export default {
  /**
    @数据插入
    @param {object} object 解析的数据
    @param {function} callback 回调函数
    @param {string} label 标签
    @param {number} index 索引
    @param {array} arr 返回数组
  */
  parseData(object, callback, label = '', index = 0, arr = []) {
    if (object.Push == undefined) {
      index++
      if (label) {
        arr = arr.slice(0, index)
        arr.push(label)
      }
      if (Array.isArray(object)) {
        object.forEach((e, i) => {
          this.parseData(e, callback, i, index, arr)
        })
      } else {
        Object.entries(object).forEach((e) => {
          this.parseData(e[1], callback, e[0], index, arr)
        })
      }
    } else {
      callback(object, label, arr)
    }
  },
  /**
    @数据插入
    @param {object} backendData 需要插入的数据（后端格式）
    @param {object} paramItem 被插入节点（前端格式）
    @param {boolean} pushState 是否推送
    @param {function} callback 回调函数
    @param {string} type 插入类型（话机、账户、默认）
  */
  setValue(backendData, paramItem, pushState, callback, type) {
    Object.entries(backendData).forEach(backendItem => {
      let [backendKey, backendValue] = backendItem
      switch (type) {
        case 'phoneKeys':  // 话机编程键
          this.parseData(paramItem, (ee) => {
            Object.values(ee).forEach(value => {
              if (backendKey == value.Key) {
                value.Value = backendValue
                pushState && (ee.Push = pushState)
              }
            })
            callback && callback(backendItem, ee)
          })
          break
        default:  // 默认
          this.parseData(paramItem, (item, key) => {
            if (backendKey === key) {
              item.Value = this.valFilter(backendValue, item.ValueTemplate, true)
              pushState && (item.Push = pushState)
              callback && callback(backendItem, item)
            }
          })
          break
      }
    })
  },
  /**
    @value格式化
    @param {string} val 参数
    @param {string} valTemplate 参数模板
    @param {boolean} type T-取值/F-赋值
    @param {string} replaceVal 模板占位符
    @return {string} 格式化后value
  */
  valFilter(val, valTemplate, type = false, replaceVal = '$') {
    if (valTemplate) {
      if (type) {
        // 取值
        return val
      } else {
        // 赋值
        return valTemplate.replace(replaceVal, val)
      }
    } else {
      return val
    }
  },
}
