<template>
  <div class="textedit">
    <div class="instructions">
      <p>{{ $t(getInstruction1()) }}</p>
      <p>{{ $t(getInstruction2()) }}</p>
    </div>
    <el-input
      class="textarea"
      rows="10"
      type="textarea"
      v-model="extentText"
    ></el-input>
    <div class="footer-btns">
      <el-button
        class="footer-btn"
        size="small"
        type="primary"
        plain
        @click="cancel"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="footer-btn"
        size="small"
        type="primary"
        @click="$emit('onlysave')"
      >{{ $t('save') }}</el-button>
      <el-button
        class="footer-btn"
        size="small"
        type="primary"
        :disabled="enablePushToDevice()"
        @click="$emit('submit')"
      >{{ $t('Save and update') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TEXTEDIT',
  props: {
    extent: String,  // xml数据
  },
  model: {
    prop: 'extent',
    event: 'changeExtent'
  },
  computed: {
    extentText: {
      get() {
        return this.extent
      },
      set(value) {
        this.$emit('changeExtent', value)
      }
    }
  },
  methods: {
     enablePushToDevice(){
      return this.$route.query.pushStatus == '0';
    },
    getInstruction1() {
      console.log('modelname:',this.$route.query.modelName, 'route', this.$route)
      if (this.$route.query.deviceModel == "H2" || this.$route.query.deviceModel == "H2P") {
        return ('sitParam2.instructions3')
      } else {
        return ('sitParam2.instructions1')
      }
    },
    getInstruction2() {
      console.log('modelname:',this.$route.query.modelName, 'route', this.$route)
      if (this.$route.query.deviceModel == "H2" || this.$route.query.deviceModel == "H2P") {
        return ('sitParam2.instructions4')
      } else {
        return ('sitParam2.instructions2')
      }
    },
    // 取消
    cancel() {
      this.$emit('cancel')
      if (
        this.$route.query.templateName ||
        this.$route.query.site ||
        this.$route.query.modelName ||
        this.$route.query.modelDesc
      ) {
        this.$router.push({
          path: '/model/addModel',
          query: {
            templateName: this.$route.query.templateName,
            site: this.$route.query.site,
            modelName: this.$route.query.modelName,
            modelDesc: this.$route.query.modelDesc,
          },
        })
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.textedit {
  background-color: #FFF;
  padding-top: 30px;
  .instructions {
    margin-bottom: 30px;
    padding: 21px 85px 19px 44px;
    background-color: #F6F6F6;
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
    }
  }
  .textarea {
    padding: 0 44px;
    width: calc(100% - 88px);
  }
  .footer-btns {
    margin-top: 60px;
    padding: 0 44px 100px;
  }
}
</style>
