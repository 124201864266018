import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  // state: {},
  getters: {
    // 语言
    languageOptions: () => {
      return {
        'zh-CN': '简体中文',
        en: 'English'
      }
    },
    userType: () => {
      return {
        enterprise: i18n.t('dist.userType.enterprise'),
        administrator: i18n.t('dist.userType.administrator'),
        channel: i18n.t('dist.userType.channel'),
      }
    },
    // 任务类型
    taskType: () => {
      return {
        1: i18n.t('dist.taskType.rebootDevice'),
        2: i18n.t('dist.taskType.factoryReset'),
        3: i18n.t('dist.taskType.upgradeFirmware'),
        7: i18n.t('dist.taskType.updateGlobalConfig'),
        // EDM 第一阶段需隐藏模块
        // 8: i18n.t('dist.taskType.updateResourceFile'),
        4: i18n.t('dist.taskType.updateConfigModel'),
        5: i18n.t('dist.taskType.updateConfigGroup'),
        6: i18n.t('dist.taskType.updateConfigSite'),
        8: i18n.t('edm009'),
        9: i18n.t('edm010'),
        10: i18n.t('edm011'),
      }
    },
    // 任务状态
    taskState: () => {
      return {
        0: i18n.t('dist.taskState.scheduled'),
        1: i18n.t('dist.taskState.ongoing'),
        // 2: i18n.t('dist.taskState.success'),
        // 3: i18n.t('dist.taskState.failed'),
        4: i18n.t('dist.taskState.cancelled'),
        5: i18n.t('dist.taskState.pause'),
        // 6: i18n.t('dist.taskState.timeout'),
        7: i18n.t('dist.taskState.Finished'),
      }
    },
    // 任务状态
    taskResult: () => {
      return {
        0: i18n.t('dist.taskState.scheduled'),
        1: i18n.t('dist.taskState.ongoing'),
        2: i18n.t('dist.taskState.success'),
        3: i18n.t('dist.taskState.failed'),
        4: i18n.t('dist.taskState.cancelled'),
        5: i18n.t('dist.taskState.pause'),
        6: i18n.t('dist.taskState.timeout'),
        7: i18n.t('dist.taskState.Finished'),
      }
    },
    // 任务时间类型
    taskTimeType: () => {
      return {
        1: i18n.t('dist.taskTimeType.immediate'),
        2: i18n.t('dist.taskTimeType.scheduled'),
        3: i18n.t('dist.taskTimeType.repeating'),
      }
    },
    // 用户状态
    accountStatus: () => {
      return {
        0: i18n.t('dist.accountStatus.unassigned'),
        1: i18n.t('dist.accountStatus.registered'),
        2: i18n.t('dist.accountStatus.unregistered'),
      }
    },
    // 设备状态
    deviceStatus: () => {
      return {
        0: i18n.t('dist.deviceStatus.offline'),
        1: i18n.t('dist.deviceStatus.online'),
        2: i18n.t('dist.deviceStatus.abnormal'),
      }
    },
    // 设备用户状态
    deviceAccountStatus: () => {
      return {
        0: i18n.t('dist.deviceAccountStatus.noAccount'),
        1: i18n.t('dist.deviceAccountStatus.normal'),
        2: i18n.t('dist.deviceAccountStatus.abnormal'),
      }
    },
    // 设备用户状态2（注：这里0和2是一样的）
    deviceAccountStatus2: () => {
      return {
        null: i18n.t('dist.deviceAccountStatus2.unregistered'),
        0: i18n.t('dist.deviceAccountStatus2.abnormal'),
        1: i18n.t('dist.deviceAccountStatus2.normal'),
        2: i18n.t('dist.deviceAccountStatus2.abnormal'),
      }
    },
    // 设备用户状态2（注：这里0和2是一样的）
    siteAccountStatus: () => {
      return {
        0: i18n.t('dist.siteAccountStatus.unassigned'),
        1: i18n.t('dist.siteAccountStatus.normal'),
        2: i18n.t('dist.siteAccountStatus.abnormal'),
      }
    },
    // 通话质量
    callQuality: () => {
      return {
        0: i18n.t('dist.callQuality.good'),
        1: i18n.t('dist.callQuality.fair'),
        2: i18n.t('dist.callQuality.poor'),
      }
    },
    // 呼叫类型
    callType: () => {
      return {
        0: i18n.t('dist.callType.caller'),
        1: i18n.t('dist.callType.callee'),
      }
    },
    // 告警状态
    alarmStatus: () => {
      return {
        resolved: i18n.t('alarmList.resolved'), // 已解决
        read: i18n.t('alarmList.read'), // 已读
        ignore: i18n.t('alarmList.ignore'), // 忽略
        unread: i18n.t('alarmList.unread'), // 未读
      }
    },
    // 告警状态颜色
    alarmStatusColor: () => {
      return {
        resolved: 'bg-success', // 已解决
        read: 'bg-primary', // 已读
        ignore: 'bg-info', // 忽略
        // unread: 'bg-danger', // 未读
      }
    },
    // 告警类型
    alarmLevel: () => {
      return {
        1: i18n.t('dist.alarmLevel.critical'), //"严重"
        2: i18n.t('dist.alarmLevel.major'), //"主要"
        3: i18n.t('dist.alarmLevel.minor'), // "一般"
      }
    },
    // 告警类型
    alarmType: () => {
      return {
        1: i18n.t('dist.alarmType.accountRegistrationFailed'), // 账号注册失败
        2: i18n.t('dist.alarmType.factoryReset'), // 话机的恢复出厂行为
        3: i18n.t('dist.alarmType.deviceReboot'), //  话机的重启行为
        4: i18n.t('dist.alarmType.deviceOffline'), // 设备离线
        // 5: i18n.t('dist.alarmType.badCallQuality'), // 通话质量差（第一阶段隐藏）
        6: i18n.t('dist.alarmType.deviceOnline'), // 话机的重启行为
        // 7: i18n.t('dist.alarmType.callfailed'), // 通话建立失败（第一阶段隐藏）
        8: i18n.t('dist.alarmType.updateConfigurationFailed'), // 更新配置失败
        // 9: i18n.t('dist.alarmType.newFirmwareNotify'), // 新固件通知（第一阶段隐藏）
        10: i18n.t('dist.alarmType.systemBackupFailed'), // 备份失败
      }
    },
    // 选择设备类型
    selectDeviceType: () => {
      return {
        1: i18n.t('dist.selectDeviceType.all'),
        2: i18n.t('dist.selectDeviceType.site'),
        3: i18n.t('dist.selectDeviceType.group'),
        4: i18n.t('dist.selectDeviceType.custom'),
      }
    },
    // 告警策略
    strategyMode: () => {
      return {
        1: i18n.t('dist.strategyMode.email'),
        2: i18n.t('dist.strategyMode.inStation'),
        3: i18n.t('dist.strategyMode.emailInstatio'),
      }
    },
    // 告警频率
    strategyFrequency: () => {
      return {
        1: i18n.t('dist.strategyFrequency.realTime'),
        2: i18n.t('dist.strategyFrequency.daily'),
        3: i18n.t('dist.strategyFrequency.weekly'),
      }
    },
    // 告警状态
    strategyStatus: () => {
      return {
        0: i18n.t('dist.strategyStatus.off'),
        1: i18n.t('dist.strategyStatus.on'),
      }
    },
    // 告警设备状态
    strategyDeviceType: () => {
      return {
        1: i18n.t('dist.strategyDeviceType.all'),
        2: i18n.t('dist.strategyDeviceType.site'),
        3: i18n.t('dist.strategyDeviceType.group'),
        4: i18n.t('dist.strategyDeviceType.custom'),
      }
    },
    // 资源类型
    OtherResourcesTypes: () => {
      return {
        1: i18n.t('edm003'),
        2: i18n.t('edm004'),
        3: i18n.t('edm005'),
      }
    },
    // 资源类型对应的文件类型
    FileTypeToResourceTypes:()=>{
      return {
        1: [".xml",".csv"],
        2: [".wav"],
        3: [".png"],
      }
    },
    //资源类型对应的大小限制
    SizeLimitToResourceTypes1:()=>{
      return {
        1: "5MB",
        2: "200KB",
        3: "1MB"
      }
    },
    SizeLimitToResourceTypes2:()=>{
      return {
        1: 5*1024*1024,
        2: 200*1024,
        3: 1*1024*1024
      }
    },
    //DNS型号
    DNSModelDics: () => {
      return [{
        id: 'DNS NAPTR',
        name: 'DNS NAPTR',
      }]
    },
    //NAT 穿透
    NatTraversalDics: () => {
      return [
        {
          id: 'Disable',
          name: 'Disable',
        },
        {
          id: 'NAT',
          name: 'NAT',
        },
        {
          id: 'Stun',
          name: 'STUN',
        },
        {
          id: 'ICE',
          name: 'ICE',
        },
      ]
    },
    // 运输方式
    transportModeDics: () => {
      return [
        {
          value: '0',
          label: 'UDP',
        },
        {
          value: '1',
          label: 'TCP',
        },
        {
          value: '2',
          label: 'TLS',
        },
        {
          value: '3',
          label: 'DNS NAPTR',
        },
      ]
    },
    // 自定义字段
    sipServerAdditionalSettings: () => {
      return [
        {
          id: 'Secondary SIP Server',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondarySIPServer'),
          defaultValue: '',
          disabled: false,
        },
        {
          id: 'Secondary SIP Server Port',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondarySIPServerPort'),
          defaultValue: 5060,
          disabled: false,
        },
        {
          id: 'Secondary Register Expire Time',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondaryRegisterExpireTime'),
          defaultValue: 3600,
          disabled: false,
        },
        {
          id: 'Secondary Transport Mode',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondaryTransportMode'),
          defaultValue: '0',
          disabled: false,
        },
        {
          id: 'Secondary Outbound Proxy Address',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondaryOutboundProxyAddress'),
          defaultValue: '',
          disabled: false,
        },
        {
          id: 'Secondary Outbound Proxy Port',
          name: i18n.t('dist.sipServerAdditionalSettings.SecondaryOutboundProxyPort'),
          defaultValue: 5060,
          disabled: false,
        },
      ]
    },
    //等级
    syslogLevel: () => {
      return [
        {
          id: '',
          name: i18n.t('dist.syslogLevel.allLevels'),
        },
        {
          id: 'high',
          name: i18n.t('dist.syslogLevel.high'),
        },
        {
          id: 'medium',
          name: i18n.t('dist.syslogLevel.medium'),
        },
        {
          id: 'low',
          name: i18n.t('dist.syslogLevel.low'),
        },
      ]
    },
    //国家字典
    countryDics: () => {
      return [
        { title: 'Afghanistan ', id: 'Afghanistan ' },
        { title: 'Albania ', id: 'Albania ' },
        { title: 'Algeria ', id: 'Algeria ' },
        { title: 'American Samoa (USA)', id: 'American Samoa (USA)' },
        { title: 'Andorra ', id: 'Andorra ' },
        { title: 'Angola ', id: 'Angola ' },
        { title: 'Anguilla (UK)', id: 'Anguilla (UK)' },
        { title: 'Antigua and Barbuda ', id: 'Antigua and Barbuda ' },
        { title: 'Argentina ', id: 'Argentina ' },
        { title: 'Armenia ', id: 'Armenia ' },
        { title: 'Aruba (Netherlands)', id: 'Aruba (Netherlands)' },
        { title: 'Australia ', id: 'Australia ' },
        { title: 'Austria ', id: 'Austria ' },
        { title: 'Azerbaijan ', id: 'Azerbaijan ' },
        { title: 'Bahamas ', id: 'Bahamas ' },
        { title: 'Bahrain ', id: 'Bahrain ' },
        { title: 'Bangladesh ', id: 'Bangladesh ' },
        { title: 'Barbados ', id: 'Barbados ' },
        { title: 'Belarus ', id: 'Belarus ' },
        { title: 'Belgium ', id: 'Belgium ' },
        { title: 'Belize ', id: 'Belize ' },
        { title: 'Benin ', id: 'Benin ' },
        { title: 'Bermuda (UK)', id: 'Bermuda (UK)' },
        { title: 'Bhutan ', id: 'Bhutan ' },
        { title: 'Bolivia ', id: 'Bolivia ' },
        { title: 'Bosnia and Herzegovina ', id: 'Bosnia and Herzegovina ' },
        { title: 'Botswana ', id: 'Botswana ' },
        { title: 'Brazil ', id: 'Brazil ' },
        {
          title: 'British Virgin Islands (UK)',
          id: 'British Virgin Islands (UK)',
        },
        { title: 'Brunei ', id: 'Brunei ' },
        { title: 'Bulgaria ', id: 'Bulgaria ' },
        { title: 'Burkina Faso ', id: 'Burkina Faso ' },
        { title: 'Burundi ', id: 'Burundi ' },
        { title: 'Cambodia ', id: 'Cambodia ' },
        { title: 'Cameroon ', id: 'Cameroon ' },
        { title: 'Canada ', id: 'Canada ' },
        { title: 'Cape Verde ', id: 'Cape Verde ' },
        { title: 'Cayman Islands (UK)', id: 'Cayman Islands (UK)' },
        { title: 'Central African Republic ', id: 'Central African Republic ' },
        { title: 'Chad ', id: 'Chad ' },
        { title: 'Chile ', id: 'Chile ' },
        { title: 'China', id: 'China' },
        {
          title: 'Christmas Island (Australia)',
          id: 'Christmas Island (Australia)',
        },
        {
          title: 'Cocos (Keeling) Islands (Australia)',
          id: 'Cocos (Keeling) Islands (Australia)',
        },
        { title: 'Colombia ', id: 'Colombia ' },
        { title: 'Comoros ', id: 'Comoros ' },
        { title: 'Cook Islands (New Zealand)', id: 'Cook Islands (New Zealand)' },
        { title: 'Costa Rica ', id: 'Costa Rica ' },
        { title: 'Croatia ', id: 'Croatia ' },
        { title: 'Cuba ', id: 'Cuba ' },
        { title: 'Curacao (Netherlands)', id: 'Curacao (Netherlands)' },
        { title: 'Cyprus ', id: 'Cyprus ' },
        { title: 'Czech Republic ', id: 'Czech Republic ' },
        { title: 'D.R Congo ', id: 'D.R Congo ' },
        { title: 'Denmark ', id: 'Denmark ' },
        { title: 'Djibouti ', id: 'Djibouti ' },
        { title: 'Dominica ', id: 'Dominica ' },
        { title: 'Dominican Republic ', id: 'Dominican Republic ' },
        { title: 'East Timor (Timor-Leste) ', id: 'East Timor (Timor-Leste) ' },
        { title: 'Ecuador ', id: 'Ecuador ' },
        { title: 'Egypt ', id: 'Egypt ' },
        { title: 'El Salvador ', id: 'El Salvador ' },
        { title: 'Equatorial Guinea ', id: 'Equatorial Guinea ' },
        { title: 'Eritrea ', id: 'Eritrea ' },
        { title: 'Estonia ', id: 'Estonia ' },
        { title: 'Ethiopia ', id: 'Ethiopia ' },
        { title: 'Falkland Islands (UK)', id: 'Falkland Islands (UK)' },
        { title: 'Faroe Islands (Denmark)', id: 'Faroe Islands (Denmark)' },
        { title: 'Fiji ', id: 'Fiji ' },
        { title: 'Finland ', id: 'Finland ' },
        { title: 'France ', id: 'France ' },
        { title: 'French Guiana (France)', id: 'French Guiana (France)' },
        { title: 'French Polynesia (France)', id: 'French Polynesia (France)' },
        { title: 'Gabon ', id: 'Gabon ' },
        { title: 'Gambia ', id: 'Gambia ' },
        { title: 'Georgia ', id: 'Georgia ' },
        { title: 'Germany ', id: 'Germany ' },
        { title: 'Ghana ', id: 'Ghana ' },
        { title: 'Gibraltar (UK)', id: 'Gibraltar (UK)' },
        { title: 'Greece ', id: 'Greece ' },
        { title: 'Greenland (Denmark)', id: 'Greenland (Denmark)' },
        { title: 'Grenada ', id: 'Grenada ' },
        { title: 'Guam (USA)', id: 'Guam (USA)' },
        { title: 'Guatemala ', id: 'Guatemala ' },
        { title: 'Guernsey (UK)', id: 'Guernsey (UK)' },
        { title: 'Guinea ', id: 'Guinea ' },
        { title: 'Guinea-Bissau ', id: 'Guinea-Bissau ' },
        { title: 'Guyana ', id: 'Guyana ' },
        { title: 'Haiti ', id: 'Haiti ' },
        { title: 'Honduras ', id: 'Honduras ' },
        { title: 'Hong Kong (China)', id: 'Hong Kong (China)' },
        { title: 'Hungary ', id: 'Hungary ' },
        { title: 'Iceland ', id: 'Iceland ' },
        { title: 'India ', id: 'India ' },
        { title: 'Indonesia ', id: 'Indonesia ' },
        { title: 'Iran ', id: 'Iran ' },
        { title: 'Iraq ', id: 'Iraq ' },
        { title: 'Ireland ', id: 'Ireland ' },
        { title: 'Isle of Man (UK)', id: 'Isle of Man (UK)' },
        { title: 'Israel ', id: 'Israel ' },
        { title: 'Italy ', id: 'Italy ' },
        { title: 'Ivory Coast ', id: 'Ivory Coast ' },
        { title: 'Jamaica ', id: 'Jamaica ' },
        { title: 'Japan ', id: 'Japan ' },
        { title: 'Jersey (UK)', id: 'Jersey (UK)' },
        { title: 'Jordan ', id: 'Jordan ' },
        { title: 'Kazakhstan ', id: 'Kazakhstan ' },
        { title: 'Kenya ', id: 'Kenya ' },
        { title: 'Kiribati ', id: 'Kiribati ' },
        { title: 'Kosovo', id: 'Kosovo' },
        { title: 'Kuwait ', id: 'Kuwait ' },
        { title: 'Kyrgyzstan ', id: 'Kyrgyzstan ' },
        { title: 'Laos ', id: 'Laos ' },
        { title: 'Latvia ', id: 'Latvia ' },
        { title: 'Lebanon ', id: 'Lebanon ' },
        { title: 'Lesotho ', id: 'Lesotho ' },
        { title: 'Liberia ', id: 'Liberia ' },
        { title: 'Libya ', id: 'Libya ' },
        { title: 'Liechtenstein ', id: 'Liechtenstein ' },
        { title: 'Lithuania ', id: 'Lithuania ' },
        { title: 'Luxembourg ', id: 'Luxembourg ' },
        { title: 'Macedonia ', id: 'Macedonia ' },
        { title: 'Madagascar ', id: 'Madagascar ' },
        { title: 'Malawi ', id: 'Malawi ' },
        { title: 'Malaysia', id: 'Malaysia' },
        { title: 'Maldives ', id: 'Maldives ' },
        { title: 'Mali ', id: 'Mali ' },
        { title: 'Malta ', id: 'Malta ' },
        { title: 'Marshall Islands ', id: 'Marshall Islands ' },
        { title: 'Mauritania ', id: 'Mauritania ' },
        { title: 'Mauritius ', id: 'Mauritius ' },
        { title: 'Mexico ', id: 'Mexico ' },
        { title: 'Micronesia ', id: 'Micronesia ' },
        { title: 'Moldova ', id: 'Moldova ' },
        { title: 'Monaco ', id: 'Monaco ' },
        { title: 'Mongolia ', id: 'Mongolia ' },
        { title: 'Montenegro ', id: 'Montenegro ' },
        { title: 'Montserrat (UK)', id: 'Montserrat (UK)' },
        { title: 'Morocco ', id: 'Morocco ' },
        { title: 'Mozambique ', id: 'Mozambique ' },
        { title: 'Myanmar ', id: 'Myanmar ' },
        { title: 'Namibia ', id: 'Namibia ' },
        { title: 'Nauru ', id: 'Nauru ' },
        { title: 'Nepal ', id: 'Nepal ' },
        { title: 'Netherlands ', id: 'Netherlands ' },
        { title: 'New Caledonia (France)', id: 'New Caledonia (France)' },
        { title: 'New Zealand ', id: 'New Zealand ' },
        { title: 'Nicaragua ', id: 'Nicaragua ' },
        { title: 'Niger ', id: 'Niger ' },
        { title: 'Nigeria ', id: 'Nigeria ' },
        { title: 'Niue (New Zealand)', id: 'Niue (New Zealand)' },
        { title: 'Norfolk Island (Australia)', id: 'Norfolk Island (Australia)' },
        { title: 'North Korea ', id: 'North Korea ' },
        {
          title: 'Northern Mariana Islands (USA)',
          id: 'Northern Mariana Islands (USA)',
        },
        { title: 'Norway ', id: 'Norway ' },
        { title: 'Oman ', id: 'Oman ' },
        { title: 'Pakistan ', id: 'Pakistan ' },
        { title: 'Palau ', id: 'Palau ' },
        { title: 'Palestine ', id: 'Palestine ' },
        { title: 'Panama ', id: 'Panama ' },
        { title: 'Papua New Guinea ', id: 'Papua New Guinea ' },
        { title: 'Paraguay ', id: 'Paraguay ' },
        { title: 'Peru ', id: 'Peru ' },
        { title: 'Philippines ', id: 'Philippines ' },
        { title: 'Pitcairn Islands (UK)', id: 'Pitcairn Islands (UK)' },
        { title: 'Poland ', id: 'Poland ' },
        { title: 'Portugal ', id: 'Portugal ' },
        { title: 'Puerto Rico (USA)', id: 'Puerto Rico (USA)' },
        { title: 'Qatar ', id: 'Qatar ' },
        { title: 'Republic of the Congo ', id: 'Republic of the Congo ' },
        { title: 'Romania ', id: 'Romania ' },
        { title: 'Russia ', id: 'Russia ' },
        { title: 'Rwanda ', id: 'Rwanda ' },
        { title: 'Saint Barthelemy', id: 'Saint Barthelemy' },
        {
          title: 'Saint Helena, Ascension, and Tristan da Cunha (UK)',
          id: 'Saint Helena, Ascension, and Tristan da Cunha (UK)',
        },
        { title: 'Saint Kitts and Nevis ', id: 'Saint Kitts and Nevis ' },
        { title: 'Saint Lucia ', id: 'Saint Lucia ' },
        { title: 'Saint Martin', id: 'Saint Martin' },
        {
          title: 'Saint Pierre and Miquelon (France)',
          id: 'Saint Pierre and Miquelon (France)',
        },
        {
          title: 'Saint Vincent and the Grenadines ',
          id: 'Saint Vincent and the Grenadines ',
        },
        { title: 'Samoa ', id: 'Samoa ' },
        { title: 'San Marino ', id: 'San Marino ' },
        { title: 'São Tomé and Príncipe ', id: 'São Tomé and Príncipe ' },
        { title: 'Saudi Arabia ', id: 'Saudi Arabia ' },
        { title: 'Senegal ', id: 'Senegal ' },
        { title: 'Serbia ', id: 'Serbia ' },
        { title: 'Seychelles ', id: 'Seychelles ' },
        { title: 'Sierra Leone ', id: 'Sierra Leone ' },
        { title: 'Singapore ', id: 'Singapore ' },
        { title: 'Sint Maarten (Netherlands)', id: 'Sint Maarten (Netherlands)' },
        { title: 'Slovakia ', id: 'Slovakia ' },
        { title: 'Slovenia ', id: 'Slovenia ' },
        { title: 'Solomon Islands ', id: 'Solomon Islands ' },
        { title: 'Somalia ', id: 'Somalia ' },
        { title: 'South Africa ', id: 'South Africa ' },
        { title: 'South Korea ', id: 'South Korea ' },
        { title: 'South Sudan ', id: 'South Sudan ' },
        { title: 'Spain', id: 'Spain' },
        { title: 'Sri Lanka ', id: 'Sri Lanka ' },
        { title: 'Sudan ', id: 'Sudan ' },
        { title: 'Suriname ', id: 'Suriname ' },
        { title: 'Swaziland ', id: 'Swaziland ' },
        { title: 'Sweden ', id: 'Sweden ' },
        { title: 'Switzerland ', id: 'Switzerland ' },
        { title: 'Syria ', id: 'Syria ' },
        { title: 'Taiwan (China)', id: 'Taiwan (China)' },
        { title: 'Tajikistan ', id: 'Tajikistan ' },
        { title: 'Tanzania ', id: 'Tanzania ' },
        { title: 'Thailand ', id: 'Thailand ' },
        { title: 'Togo ', id: 'Togo ' },
        { title: 'Tokelau (New Zealand)', id: 'Tokelau (New Zealand)' },
        { title: 'Tonga ', id: 'Tonga ' },
        { title: 'Transnistria ', id: 'Transnistria ' },
        { title: 'Trinidad and Tobago ', id: 'Trinidad and Tobago ' },
        { title: 'Tunisia ', id: 'Tunisia ' },
        { title: 'Turkey ', id: 'Turkey ' },
        { title: 'Turkmenistan ', id: 'Turkmenistan ' },
        {
          title: 'Turks and Caicos Islands (UK)',
          id: 'Turks and Caicos Islands (UK)',
        },
        { title: 'Tuvalu ', id: 'Tuvalu ' },
        { title: 'Uganda ', id: 'Uganda ' },
        { title: 'Ukraine ', id: 'Ukraine ' },
        { title: 'United Arab Emirates ', id: 'United Arab Emirates ' },
        { title: 'United Kingdom ', id: 'United Kingdom ' },
        { title: 'United States ', id: 'United States ' },
        {
          title: 'United States Virgin Islands (USA)',
          id: 'United States Virgin Islands (USA)',
        },
        { title: 'Uruguay ', id: 'Uruguay ' },
        { title: 'Uzbekistan ', id: 'Uzbekistan ' },
        { title: 'Vanuatu ', id: 'Vanuatu ' },
        { title: 'Vatican City ', id: 'Vatican City ' },
        { title: 'Venezuela ', id: 'Venezuela ' },
        { title: 'Vietnam ', id: 'Vietnam ' },
        { title: 'Wallis and Futuna (France)', id: 'Wallis and Futuna (France)' },
        { title: 'Western Sahara', id: 'Western Sahara' },
        { title: 'Yemen ', id: 'Yemen ' },
        { title: 'Zambia ', id: 'Zambia ' },
        { title: 'Zimbabwe ', id: 'Zimbabwe ' },
      ]
    },
  },
}
