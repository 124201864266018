<template>
  <div class="table-pagination page-table">
    <div class="table">
      <el-table
        ref="tablePaginationTable"
        :height="height"
        :data="tableData"
        border
        @header-dragend="headerDragend"
        @sort-change="sortChange"
        @selection-change="selectionChange"
        @select-all="selectAll"
        @select="select"
      >
        <!-- 多选框 -->
        <el-table-column
          v-if="selectionShow"
          type="selection"
          min-width="40px"
        ></el-table-column>
        <!-- 数据 -->
        <template v-for="(item, i) in columnFullData">
          <!-- 表头插槽列 -->
          <el-table-column
            v-if="item.headerSlot"
            :key="i"
            :prop="item.prop"
            :label="item.label"
            :fixed="item.fixed"
            :min-width="item.minWidth"
            :width="columnWidthData[item.prop]"
            :sortable="item.sortable"
            show-overflow-tooltip
            :filters="item.filterEnable ? getFilters(item.prop) : undefined"
            :filter-method="item.filterEnable ? filterMethod : undefined"
            resizable
          >
            <!-- 表头 -->
            <template slot="header" slot-scope="headerScope">
              <slot
                :name="item.prop + 'Header'"
                :data="headerScope"
                :filter="getFilters(item.prop)"
              ></slot>
            </template>
            <!-- 自定义内容 -->
            <template slot-scope="scope">
              <template
                v-if="!item.placeholderDisable && plaWhiteList.includes(scope.row[item.prop])"
              >{{ placeholder }}</template>
              <template v-else-if="item.slot">
                <slot :name="item.prop" :row="scope.row" :$index="scope.$index"></slot>
              </template>
              <template v-else>{{ formatData(scope.row, item) }}</template>
            </template>
          </el-table-column>
          <!-- 正常列 -->
          <el-table-column
            v-else
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :fixed="item.fixed"
            :min-width="item.minWidth"
            :width="columnWidthData[item.prop]"
            :sortable="item.sortable"
            show-overflow-tooltip
            :filters="item.filterEnable ? getFilters(item.prop) : undefined"
            :filter-method="item.filterEnable ? filterMethod : undefined"
          >
            <!-- 自定义内容 -->
            <template slot-scope="scope">
              <template
                v-if="!item.placeholderDisable && plaWhiteList.includes(scope.row[item.prop])"
              >{{ placeholder }}</template>
              <template v-else-if="item.slot">
                <slot :name="item.prop" :row="scope.row" :$index="scope.$index"></slot>
              </template>
              <template v-else>{{ formatData(scope.row, item) }}</template>
            </template>
          </el-table-column>
        </template>
        <!-- 操作 -->
        <el-table-column
          v-if="optionShow"
          :label="$t('operation')"
          :min-width="optionMinWidth"
        >
          <template slot-scope="scope">
            <slot name="option" :row="scope.row" :$index="scope.$index"></slot>
          </template>
        </el-table-column>
        <!-- 列显示设置 -->
        <el-table-column v-if="columnOptionShow" width="50">
          <template slot="header" slot-scope="scope">
            <el-dropdown trigger="click" :hide-on-click="false">
              <!-- <i class="iconfont icon-configuration">{{ scope.row ? '' : '' }}</i> -->
              <el-button type="text" class="btn-p0">
                <i class="el-icon-setting iconfont icon-configuration">{{ scope.row ? '' : '' }}</i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, i) in columnData" :key="i">
                  <el-checkbox
                    v-model="columnCheckData[item.prop]"
                    @change="checkChange(item.prop)"
                    >{{ item.label }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="pagination"
      v-if="!paginationHide"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'table-pagination',
  props: {
    memory: String,
    height: [String, Number],
    tableData: Array,
    columnData: Array,
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50, 100, 150, 200, 250]
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    selectionShow: {
      type: Boolean,
      default: false,
    },
    optionShow: {
      type: Boolean,
      default: false,
    },
    optionMinWidth: {
      type: Number,
      default: 120,
    },
    columnOptionShow: {
      type: Boolean,
      default: false,
    },
    paginationHide: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '--',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortRules: {
      type: Object,
      default() {
        return {
          descending: 'desc',
          ascending: 'asc',
        }
      },
    },
  },
  data() {
    let oldColumnCheckData = this.$store.state.memory.tableColumnCheckData[this.memory] // 获取列选项
    if (oldColumnCheckData == undefined) oldColumnCheckData = {}
    let columnCheckData = {}
    let oldColumnWidthData = this.$store.state.memory.tableColumnWidthData[this.memory] // 获取列宽度
    if (oldColumnWidthData == undefined) oldColumnWidthData = {}
    let columnWidthData = {}
    this.columnData.forEach((e) => {
      columnCheckData[e.prop] =
        this.memory && oldColumnCheckData[e.prop] != undefined ? oldColumnCheckData[e.prop] : true
      columnWidthData[e.prop] =
        this.memory && oldColumnCheckData[e.prop] != undefined ? oldColumnWidthData[e.prop] : true
    })
    return {
      columnCheckData,
      columnWidthData,
      plaWhiteList: [undefined, null, ''],
      multipleSelection: [],
    }
  },
  computed: {
    columnFullData() {
      // 存储列选项
      if (this.memory) {
        this.$store.commit('memory/SET_TABLE_COLUMN_CHECK_DATA', {
          name: this.memory,
          data: this.columnCheckData,
        })
      }
      return this.columnData
        .map(item => {
          if (this.columnCheckData[item.prop]) {
            if ((item.minWidth == undefined || item.undefined == '')) {
              if (this.columnData.length > 6) {
                if (this.$i18n.locale == 'en') {
                  item.label.length >= 9 ? item.minWidth = '220' : item.minWidth = '160'
                  if (item.label.length >= 20) item.minWidth = item.label.length * 13
                } else {
                  item.label.length >= 3 ? item.minWidth = '200' : item.minWidth = '160'
                }
              } else {
                item.minWidth = '200'
              }
            }
            return item
          }
        })
        .filter(item => item)
    },
  },
  watch: {
    columnFullData() {
      this.doLayout()
    },
    tableData(val) {
      if(Array.isArray(val) && !val.length && this.currentPage !== 1) this.handleCurrentChange(1)
    }
  },
  methods: {
    doLayout() {
      this.$nextTick(() => {
        this.$refs.tablePaginationTable.doLayout()
      })
    },
    // 列宽度记忆
    headerDragend(newWidth, oldWidth, column) {
      if (newWidth < column.minWidth) column.width = newWidth
      this.columnWidthData[column.property] = column.width
      // 存储列宽度
      if (this.memory) {
        this.$store.commit('memory/SET_TABLE_COLUMN_WIDTH_DATA', {
          name: this.memory,
          data: this.columnWidthData,
        })
      }
    },
    // 改变一页条目数
    handleSizeChange(val) {
      this.$emit('update:pageSize', val)
      this.$emit('update:currentPage', 1)
      this.$emit('changeCurrentPage', 'sizeChange')
    },
    // 改变页码数
    handleCurrentChange(val) {
      this.$emit('update:currentPage', val)
      this.$emit('changeCurrentPage', 'currentChange')
    },
    // 最少显示一列
    checkChange(val) {
      let state = false
      Object.keys(this.columnCheckData).forEach(columnKey => {
        if (this.columnCheckData[columnKey]) state = true
      })
      if (!state) {
        this.$message.warning(this.$t('showAtLeastOne'))
        this.columnCheckData[val] = true
      }
    },
    // 排序
    sortChange(val) {
      val.order = this.sortRules[val.order]
      this.$emit('sort-change', val)
    },
    // 格式化数据
    formatData(row, item) {
      let value = row[item.prop]
      // 过滤
      if (item.filter) {
        // 自定义
        if (typeof item.filter == 'function') {
          value = item.filter(value, row)
        }
        // 预设
        else if (typeof item.filter == 'string') {
          switch (item.filter) {
            case 'dateFormat':
              value = this.$dateFormat(+value)
              break
            case 'toUpperCase':
              value = value.toUpperCase()
              break
            // ...
            default:
              break
          }
        }
      }
      return value
    },
    // 获取过滤
    getFilters(prop) {
      let arr = []
      if (Array.isArray(this.tableData)) {
        this.tableData.forEach((e) => {
          if (!arr.includes(e[prop])) {
            arr.push(e[prop])
          }
        })
      }
      const newArr = arr.map((e) => {
        return { text: e, value: e }
      })
      return newArr
    },
    // 过滤方法
    filterMethod(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    // 清空过滤
    clearFilter() {
      this.$refs.tablePaginationTable.clearFilter()
    },
    // 多选
    selectionChange(val) {
      this.$emit('selection-change', val)
    },
    // 切换选中状态
    toggleRowSelection(row) {
      this.$refs.tablePaginationTable.toggleRowSelection(row)
    },
    // 清除选择
    clearSelection() {
      this.$refs.tablePaginationTable.clearSelection()
    },
    // 全选选择
    selectAll(selection) {
      this.$emit('select-all', selection)
    },
    // 选择
    select(selection, row) {
      this.$emit('select', selection, row)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-pagination {
  .table {
    .el-table::before {
      height: 0;
    }
    ::v-deep .el-table__fixed::before,
    ::v-deep .el-table__fixed-right::before {
      height: 0;
    }
    ::v-deep .el-table--group::after,
    ::v-deep .el-table--border::after {
      width: 0;
    }
    ::v-deep .caret-wrapper {
      margin-top: -7px;
      margin-bottom: -7px;
    }
    ::v-deep .el-table th.is-leaf,
    ::v-deep .el-table td {
      border-right: 1px solid transparent;
    }
    ::v-deep .el-table--border {
      border: 0;
    }
    ::v-deep.el-table {
      .cell {
        padding: 0 10px;
        line-height: 27px;
        font-size: 16px;
      }
      tr th:first-child .cell,
      tr td:first-child .cell {
        min-width: 54px;
        padding-left: 30px;
      }
      tr:first-child th {
        border-bottom: 1px solid transparent;
      }
      .el-icon-arrow-down {
        font-size: 18px;
        &::before {
          vertical-align: middle;
        }
      }
    }
    .icon-configuration {
      color: $theme-blue;
    }
  }
  .pagination {
    margin-top: 20px;
    padding: 0 30px;
    text-align: right;
    white-space: break-spaces;
    line-height: 35px;
    ::v-deep .el-pagination__editor.el-input .el-input__inner {
      padding: 0;
    }
  }
}
</style>
