<template>
  <el-select
    v-model="newValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    style="width: 100%;"
  >
    <el-option
      v-for="item in optionData"
      :key="item[maps.value]"
      :label="getLabel(item)"
      :value="item[maps.value]"
    ></el-option>
  </el-select>
</template>

<script>
import selectMixin from './mixin/select'
import { deviceCityList } from '@/api/device'
import { mapState } from 'vuex'

export default {
  name: 'select-city',
  mixins: [selectMixin],
  props: {
    siteId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('components.selectCity.selectCityPla')
      },
    },
    selectAllText: {
      type: String,
      default() {
        return this.$t('components.selectCity.allCity')
      },
    },
    maps: {
      type: Object,
      default: () => {
        return {
          label: 'city',
          value: 'id',
        }
      },
    },
  },
  computed: {
    ...mapState('memory', ['sidebarSite']),
  },
  watch: {
    'sidebarSite'(val) {
      this.getData(deviceCityList, val)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      deviceCityList(this.sidebarSite)
        .then(({ data }) => {
          this.optionData = data.result.rows.map(e => { return { ...e, id: e.city }})
          this.isArray && this.$emit('update:data', [...this.optionData])
          this.selectAll && this.optionData.unshift(this.getNode(this.selectAllText, null))
        })
        .catch(() => {})
    },
  }
}
</script>
