<template>
  <div class="header ly-header">
    <div class="ly-header_main">
      <div class="ly-header_title">
        <!-- EDM管理平台 -->
        <div class="logo-img">
          <img src="~@/assets/images/logo.svg" @click="$router.push({ path: '/' })" />
        </div>
        <h2 class="logo-text">
          {{ headerName }}
          <select-tree
            v-if="channelId && channelId != loginInfo.id"
            v-model="userId"
            size="small"
            :data="selectTreeData"
            :props="selectTreeProps"
            default-expand-all
            highlight-current
            @change="selectTreeChange"
          ></select-tree>
        </h2>
      </div>
      <div class="lyh-header_content">
        <div class="header-tools_main">
          <!-- 意见反馈/文档 ---- Start -->
          <div class="fd_wrap">
            <el-button type="text" @click="showFeedback = true">
              {{ $t('login.feedback') }}
            </el-button>
            <el-button type="text" @click="documents">
              {{ $t('login.documents') }}
            </el-button>
          </div>
          <!-- 意见反馈/文档 ---- End -->
          <!-- 用户信息/操作 ---- Start -->
          <div>
            <el-dropdown trigger="click" @command="userCommand">
              <span class="el-dropdown-link">
                {{ loginInfo.userName }}
                <i class="el-icon-arrow-down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="personnalInfo">
                  {{ $t('sidebar.personnalInfo') }}
                </el-dropdown-item>
                <el-dropdown-item command="loginout">
                  {{ $t('header.Logout') }}
                </el-dropdown-item>
                <el-dropdown-item command="settings" v-if="isLAN && loginInfo.role == 'admin'">
                  {{ $t('header.settings') }}
                </el-dropdown-item>
                <el-dropdown-item command="emailServerSetting" v-if="isLAN && loginInfo.role == 'admin'">
                  {{ $t('header.EmailSeverSetting') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 用户信息/操作 ---- End -->
          <!-- 国际化切换 ---- Start -->
          <div>
            <el-dropdown @command="languageCommand">
              <span class="el-dropdown-link">
                {{ languageOptions[language] }}
                <i class="el-icon-arrow-down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(value, key) in languageOptions"
                  :key="key"
                  :command="key"
                  :disabled="language == key"
                  >{{ value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 国际化切换 ---- End -->
          <!-- 消息通知 ---- Start -->
          <el-dropdown
            trigger="hover"
            v-if="messageWhite.includes(loginInfo.enterpriseType)"
            @command="messageCommand"
          >
            <span class="el-dropdown-link">
              <el-badge :value="messagetotal" :hidden="!messageData.length" class="item">
                <i class="el-icon-message-solid" style="font-size: 1.5em;"></i>
              </el-badge>
            </span>
            <el-dropdown-menu slot="dropdown" class="message_wrap">
              <div class="message-title">
                {{ $t('header.unreadMsg') }}
                <el-button v-show="messageData.length" class="fl-r" type="text" @click="clearAll">{{
                  $t('header.clearAll')
                }}</el-button>
              </div>
              <el-dropdown-item divided></el-dropdown-item>
              <el-dropdown-item v-for="item in messageData" :key="item.id" :command="item">
                <i class="el-icon-warning" :class="{ 'text-danger': item.status == 1 }"></i>
                <template v-if="item.type == 9">{{ item.content }}</template>
                <template v-else>{{ item.num }} {{ alarmType[item.content] }}</template>
                <span class="fl-r">
                  {{ item.alarmTime | dateFormat }}
                </span>
              </el-dropdown-item>
              <div class="message-no-data" v-show="!this.messageData.length">{{ $t('header.noData') }}</div>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 消息通知 ---- End -->
        </div>
      </div>
    </div>
    <el-dialog
      class="feedback"
      :visible.sync="showFeedback"
      width="600px"
      :close-on-click-modal="false"
    >
      <p class="feedback" v-html="this.$t('login.feedbackInfo')"></p>
      <el-button
        class="footer-btn"
        slot="footer"
        @click="showFeedback = false"
      >{{ this.$t('iKnow') }}</el-button>
    </el-dialog>
    <el-dialog :title="$t('emailSettingTitle')" :visible.sync="showEmailSetting">
      <el-form
        :model="emailForm"
        :rules="emailRules"
        label-width="150px"
      >
        <el-form-item :label="$t('emailSettings.open')">
          <el-switch
            v-model="emailForm.isFlag"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('emailSettings.server')" prop="mailServer">
          <el-input v-model="emailForm.mailServer" :placeholder="$t('emailSettings.server')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('emailSettings.port')" prop="mailPort">
          <el-input v-model="emailForm.mailPort" :placeholder="$t('emailSettings.port')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('emailSettings.userName')" prop="mailUser">
          <el-input v-model="emailForm.mailUser" :placeholder="$t('emailSettings.userName')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('emailSettings.password')" prop="mailPass">
          <el-input v-model="emailForm.mailPass" :placeholder="$t('emailSettings.password')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('emailSettings.tls')" prop="isTls">
          <el-switch
            v-model="emailForm.isTls"
            :active-value="1"
            :inactive-value="0"
            @change="changeIsTls"
          ></el-switch>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button class="footer-btn" type="primary" plain @click="showEmailSetting = false">{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn" type="primary" @click="saveEmailSetting">{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <Settings v-if="settingsOption.visible" :option="settingsOption" />
    <DialogBackupStatus v-if="dialogBackupStatusVisible" :visible.sync="dialogBackupStatusVisible"/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  getNoticeApi,
  clearNoticeApi,
  emailConfiQuery,
  emailConfiSave
} from '@/api/home'
import { getUserTrees } from '@/api/app'
import { SelectTree, DialogBackupStatus } from '@/components'
import Settings from './components/settings.vue'

export default {
  name: 'layoutHeader',
  components: {
    SelectTree,
    Settings,
    DialogBackupStatus
  },
  data() {
    return {
      showFeedback: false,
      messageWhite: ['enterprise', 'channel'],
      messageData: [],
      // channel角色切换
      userId: '',
      selectTreeData: [],
      selectTreeProps: {
        value: 'id',
        children: 'children',
        label: 'userName',
      },
      // 邮箱设置
      showEmailSetting: false,
      emailForm: {},
      emailRules: {
        mailServer: [{ required: true, message: this.$t('emailSettings.PleaseInputServer'), trigger: 'blur' }],
        mailUser: [{ required: true, message: this.$t('emailSettings.PleaseInputUser'), trigger: 'blur' }],
      },
      // 设置
      settingsOption: {
        visible: false
      },
      // 告警总数
      messagetotal: 10
    }
  },
  computed: {
    ...mapState('memory', ['language']),
    ...mapState('session', ['isLAN']),
    ...mapState('account', ['loginInfo', 'channelId']),
    ...mapGetters('session', ['dialogBackupStatusVisible']),
    ...mapGetters('dist', ['languageOptions', 'taskType', 'userType', 'alarmType']),
    headerName() {
      const systemName = this.isLAN ? this.$t('title-lan') : this.$t('title'),
        userType = this.isLAN ? `` : ` | ${this.userType[this.loginInfo.enterpriseType]}`
      return systemName + userType
    }
  },
  watch: {
    'loginInfo.id'(val) { this.userId = val },
  },
  created() {
    if (this.messageWhite.includes(this.loginInfo.enterpriseType)) {
      this.getNoticeNum()
      const noticeInterval = setInterval(() => {
        this.getNoticeNum()
      }, 60 * 1000)
      // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
      this.$once('hook:beforeDestroy', () => {
        clearInterval(noticeInterval)
      })
    }
    this.channelId && this.getUserTrees(this.channelId)
    this.userId = this.loginInfo.id
  },
  methods: {
    // 获取消息数据
    getNoticeNum() {
      getNoticeApi()
        .then(({ data }) => {
          let count = 0
          this.messageData = data.result.rows
          this.messageData.forEach(function(val){
            count = count + val.num
          })
          this.messagetotal = count
        })
        .catch(() => {})
    },
    // 获取用户
    getUserTrees(id) {
      getUserTrees({ id })
        .then(({ data }) => {
          // console.log(data.result)
          const { childUser, parentUser } = data.result.rows[0]
          let arr = []
          arr[0] = {
            id: parentUser.id,
            userName: parentUser.userName,
            children: [],
          }
          childUser.forEach((e) => {
            arr[0].children.push({
              id: e.id,
              userName: e.userName,
            })
          })
          this.selectTreeData = arr
        })
        .catch(() => {})
    },
    // 切换语言
    languageCommand(command) {
      this.$store.commit('memory/SET_LANGUAGE', command)
    },
    // 用户下拉菜单
    userCommand(command) {
      switch (command) {
        case 'personnalInfo':
          this.$router.push('/personnalInfo')
          break
        case 'loginout':
          this.$store.dispatch('account/LoginOut')
          break
        case 'settings':
          this.settingsOption.visible = true
          break
        case 'emailServerSetting':
          this.getEmailSetting()
          break
      }
    },
    // 消息操作
    messageCommand({ type, taskId }) {
      console.log(type)
      if (type === 9) {
        this.$router.push({ path: '/firmware', query: { taskId } })
      } else {
        this.$router.push({ path: '/alarmList', query: { taskId } })
      }
    },
    // 清除所有消息
    clearAll() {
      clearNoticeApi()
        .then(() => {
          this.getNoticeNum()
        })
        .catch(() => {})
    },
    // feedback() {
    //   this.showFeedback = true
    //   this.$alert(this.$t('login.feedbackInfo'), {
    //     confirmButtonText: this.$t('iKnow'),
    //     center: true,
    //     dangerouslyUseHTMLString: true,
    //   })
    // },
    documents() {
      window.open('https://www.aledevice.com/site/download')
    },
    // 进入企业
    selectTreeChange(id) {
      if (id != this.loginInfo.id) {
        this.$store
          .dispatch('account/EnterTheEnterprise', { id })
          .then(() => {
            this.$store.commit('session/SET_SIDEBAR_UPDATE', true) // 更新站点
            this.$store.commit('memory/SET_SIDEBAR_SITE', []) // 清除站点
            this.$router.push({ path: '/' }) // 返回首页
            this.getNoticeNum()
          })
          .catch(() => {})
      }
    },
    // 获取邮箱设置
    getEmailSetting() {
      emailConfiQuery()
        .then(({ data }) => {
          this.emailForm = data.result.rows[0]
          this.showEmailSetting = true
        })
    },
    // 保存邮箱设置
    saveEmailSetting() {
      let {
        isFlag,
        isTls,
        mailPass,
        mailPort,
        mailServer,
        mailUser
      } = this.emailForm
      emailConfiSave({
        isFlag,
        isTls,
        mailPass,
        mailPort,
        mailServer,
        mailUser
      })
        .then(() => this.showEmailSetting = false)
    },
    // 修改TLS，调整默认端口
    changeIsTls(event) {
      if (event) this.emailForm.mailPort = '587'
      else this.emailForm.mailPort = '25'
    }
  },
}
</script>

<style lang="scss" scoped>
.ly-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 82px;
  font-size: 22px;
  background: #f4f3ef;
  .ly-header_main {
    height: 100%;
    display: flex;
    justify-content: space-between;
    .ly-header_title {
      display: flex;
      align-items: center;
      .logo-text {
        font-size: 18px;
        font-weight: 400;
      }
      .logo-img {
        width: 240px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          cursor: pointer;
          width: 193px;
          height: 53px;
        }
      }
    }
    .lyh-header_content {
      min-width: 510px;
      .el-button--text {
        color: #666;
      }
      .header-tools_main {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        & > div {
          display: inline-flex;
          height: 82px;
          line-height: 82px;
          vertical-align: middle;
          margin-left: 22px;
          .el-dropdown-link {
            font-size: 14px;
            ::v-deep .el-badge .el-badge__content {
              background-color: $theme-yellow;
            }
          }
        }
        // 意见反馈/文档
        .fd_wrap {
          position: relative;
          padding-right: 70px;
          padding-left: 25px;
          .el-button {
            padding-right: 5px;
            padding-left: 5px;
          }
          &::before {
            content: ' ';
            width: 1px;
            height: 30px;
            background: #6b489d;
            display: block;
            position: absolute;
            left: 0;
            top: 26px;
          }
        }
      }
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #666;
    .el-badge {
      color: #6b489d;
      ::v-deep .el-badge__content {
        top: 26px;
      }
    }
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
}
.el-icon-arrow-down:before {
  font-size: 18px;
}
// 消息通知
.message_wrap {
  .message-title {
    width: 400px;
    padding: 0 20px;
    line-height: 44px;
    font-size: 16px;
  }
  .message-no-data {
    text-align: center;
  }
}
.feedback ::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 55px 88px 10px 80px;
}
::v-deep .el-dialog__footer {
  padding: 0 30px 50px;
  display: flex;
  justify-content: center;
}
.feedback {
  line-height: 1.8em;
}
</style>
