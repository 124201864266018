/* 
text
password
ipv4
boolean
number: 规则示例ValueRule: "[60,*]"，*代表无限大小
select: 规则示例ValueRule: "0,1,2,3"，下拉参数值使用,隔开
check
transfer
time
*/
export default {
  account: {
    account1: {
      basic: {
        SIPGroup1Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer1Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer1Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer1RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer1FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer1FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer1FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup1OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder1: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth1: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode1: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime1: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes1: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad1: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber1: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri1: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri1: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10,100,101,106,114,117,122,123,124,126,128,129,130,131,132',
          Push: false,
        },
        SIPGroup1DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5,100,101,102,103,104',
          Push: false,
        },
        SIPGroup1SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer1KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer1KeepAliveMode: {
          label: 'SIPServerKeepAliveMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer1GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup1BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector1: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity1: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode1: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode1: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection1: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode1: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode1: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer1DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer1: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode1: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer1RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus1: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode1: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable1: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup1SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer1SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer1FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer1SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup1TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account2: {
      basic: {
        SIPGroup2Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer2Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer2Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer2RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer2FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer2FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer2FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup2OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder2: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth2: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode2: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime2: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes2: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad2: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber2: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri2: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri2: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10,100,101,106,114,117,122,123,124,126,128,129,130,131,132',
          Push: false,
        },
        SIPGroup2DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5,100,101,102,103',
          Push: false,
        },
        SIPGroup2SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer2KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer2KeepAliveMode: {
          label: 'SIPServerKeepAliveMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer2GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup2BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector2: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity2: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode2: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode2: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection2: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode2: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode2: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer2DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer2: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode2: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer2RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus2: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode2: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable2: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup2SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer2SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer2FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer2SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup2TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account3: {
      basic: {
        SIPGroup3Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer3Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer3Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer3RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer3FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer3FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer3FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup3OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder3: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth3: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode3: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime3: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes3: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad3: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber3: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri3: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri3: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup3DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup3SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer3KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer3GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup3BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector3: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity3: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode3: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode3: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection3: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode3: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode3: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer3DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer3: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode3: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer3RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus3: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode3: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable3: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup3SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer3SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer3FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer3SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup3TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account4: {
      basic: {
        SIPGroup4Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer4Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer4Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer4RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer4FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer4FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer4FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup4OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder4: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth4: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode4: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime4: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes4: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad4: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber4: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri4: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri4: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup4DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup4SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer4KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer4GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup4BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector4: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity4: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode4: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode4: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection4: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode4: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode4: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer4DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer4: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode4: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer4RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus4: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode4: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable4: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup4SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer4SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer4FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer4SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup4TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account5: {
      basic: {
        SIPGroup5Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer5Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer5Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer5RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer5FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer5FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer5FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup5OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder5: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth5: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode5: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime5: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes5: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad5: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber5: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri5: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri5: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup5DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup5SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer5KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer5GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup5BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector5: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus5: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection5: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode5: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode5: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer5DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer5: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode5: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer5RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity5: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode5: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode5: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode5: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable5: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup5SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer5SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer5FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer5SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup5TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account6: {
      basic: {
        SIPGroup6Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer6Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer6Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer6RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer6FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer6FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer6FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup6OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder6: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth6: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode6: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime6: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes6: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad6: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber6: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri6: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri6: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup6DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup6SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer6KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer6GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup6BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector6: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity6: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode6: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode6: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection6: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode6: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode6: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer6DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer6: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode6: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer6RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus6: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode6: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable6: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup6SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer6SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer6FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer6SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup6TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account7: {
      basic: {
        SIPGroup7Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer7Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer7Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer7RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer7FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer7FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer7FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup7OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder7: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth7: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode7: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime7: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes7: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad7: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber7: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri7: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri7: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup7DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup7SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer7KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer7GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup7BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector7: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity7: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode7: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode7: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection7: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode7: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode7: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer7DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer7: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode7: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer7RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus7: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode7: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable7: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup7SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer7SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer7FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer7SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup7TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account8: {
      basic: {
        SIPGroup8Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer8Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer8Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer8RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer8FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer8FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer8FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup8OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder8: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth8: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode8: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime8: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes8: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad8: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber8: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri8: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri8: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup8DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup8SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer8KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer8GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup8BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector8: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity8: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode8: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode8: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection8: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode8: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode8: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer8DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer8: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode8: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer8RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus8: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode8: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable8: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup8SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer8SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer8FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer8SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup8TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account9: {
      basic: {
        SIPGroup9Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer9Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer9Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer9RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer9FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer9FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer9FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup9OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder9: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth9: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode9: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime9: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes9: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad9: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber9: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri9: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri8: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup9DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup9SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer9KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer9GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup9BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector9: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity9: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode9: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode9: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection9: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode9: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode9: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer9DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer9: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode9: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer9RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus9: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode9: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable9: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup9SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer9SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer9FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer9SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup9TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account10: {
      basic: {
        SIPGroup10Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer10Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer10Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer10RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer10FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer10FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer10FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup10OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder10: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth10: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode10: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime10: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes10: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad10: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber10: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri10: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri10: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup10DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup10SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer10KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer10GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup10BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector10: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity10: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode10: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode10: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection10: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode10: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode10: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer10DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer10: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode10: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer10RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus10: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode10: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable10: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup10SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer10SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer10FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer10SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup10TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account11: {
      basic: {
        SIPGroup11Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer11Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer11Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer11RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer11FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer11FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer11FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup11OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder11: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth11: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode11: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime11: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes11: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad11: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber11: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri11: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri11: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup11DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup11SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer11KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer11GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup11BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector11: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity11: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode11: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode11: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection11: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode11: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode11: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer11DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer11: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode11: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer11RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus11: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode11: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable11: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup11SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer11SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer11FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer11SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup11TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account12: {
      basic: {
        SIPGroup12Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer12Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer12Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer12RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer12FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer12FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer12FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup12OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder12: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth12: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode12: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime12: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes12: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad12: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber12: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri12: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri12: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup12DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup12SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer12KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer12GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup12BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector12: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity12: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode12: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode12: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection12: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode12: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode12: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer12DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer12: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode12: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer12RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus12: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode12: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable12: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup12SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer12SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer12FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer12SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup12TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account13: {
      basic: {
        SIPGroup13Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer13Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer13Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer13RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer13FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer13FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer13FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup13OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder13: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth13: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode13: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime13: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes13: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad13: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber13: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri13: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri13: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup13DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup13SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer13KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer13GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup13BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector13: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity13: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode13: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode13: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection13: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode13: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode13: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer13DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer13: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode13: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer13RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus13: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode13: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable13: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup13SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer13SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer13FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer13SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup13TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account14: {
      basic: {
        SIPGroup14Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer14Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer14Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer14RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer14FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer14FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer14FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup14OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder14: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth14: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode14: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime14: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes14: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad14: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber14: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri14: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri14: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup14DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup14SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer14KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer14GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup14BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector14: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity14: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode14: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode14: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection14: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode14: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode14: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer14DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer14: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode14: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer14RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus14: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode14: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable14: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup14SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer14SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer14FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer14SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup14TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account15: {
      basic: {
        SIPGroup15Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer15Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer15Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer15RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer15FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer15FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer15FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup15OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder15: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth15: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode15: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime15: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes15: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad15: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber15: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri15: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri15: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup15DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup15SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer15KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer15GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup15BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector15: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity15: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode15: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode15: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection15: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode15: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode15: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer15DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer15: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode15: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer15RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus15: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode15: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable15: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup15SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer15SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer15FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer15SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup15TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account16: {
      basic: {
        SIPGroup16Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer16Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer16Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer16RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer16FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer16FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer16FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup16OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder16: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth16: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode16: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime16: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes16: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad16: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber16: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri16: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri16: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup16DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup16SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer16KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer16GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup16BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector16: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity16: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode16: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode16: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection16: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode16: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode16: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer16DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer16: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode16: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer16RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus16: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode16: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable16: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup16SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer16SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer16FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer16SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup16TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account17: {
      basic: {
        SIPGroup17Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer17Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer17Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer17RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer17FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer17FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer17FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup17OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder17: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth17: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode17: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime17: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes17: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad17: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber17: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri17: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri17: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup17DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup17SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer17KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer17GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup17BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector17: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity17: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode17: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode17: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection17: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode17: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode17: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer17DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer17: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode17: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer17RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus17: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode17: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable17: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup17SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer17SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer17FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer17SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup17TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account18: {
      basic: {
        SIPGroup18Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer18Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer18Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer18RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer18FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer18FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer18FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup18OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder18: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth18: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode18: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime18: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes18: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad18: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber18: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri18: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri18: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup18DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup18SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer18KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer18GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup18BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector18: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity18: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode18: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode18: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection18: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode18: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode18: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer18DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer18: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode18: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer18RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus18: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode18: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable18: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup18SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer18SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer18FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer18SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup18TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account19: {
      basic: {
        SIPGroup19Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer19Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer19Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer19RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer19FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer19FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer19FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup19OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder19: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth19: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode19: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime19: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes19: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad19: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber19: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri19: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri19: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup19DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup19SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer19KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer19GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup19BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector19: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity19: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode19: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode19: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection19: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode19: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode19: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer19DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer19: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode19: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer19RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus19: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode19: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable19: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup19SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer19SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer19FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer19SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup19TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    },
    account20: {
      basic: {
        SIPGroup20Enable: {
          label: 'SIPGroupEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20LabelName: {
          label: 'SIPGroupLabelName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20DisplayName: {
          label: 'SIPGroupDisplayName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20DeviceUri: {
          label: 'SIPGroupDeviceUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20AuthenticationName: {
          label: 'SIPGroupAuthenticationName',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20AuthenticationPassword: {
          label: 'SIPGroupAuthenticationPassword',
          Type: 'password',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer20Address: {
          label: 'SIPServerAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer20Port: {
          label: 'SIPServerPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer20RegisterExpire: {
          label: 'SIPServerRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20TransportMode: {
          label: 'SIPGroupTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer20FailoverAddress: {
          label: 'SIPServerFailoverAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer20FailoverPort: {
          label: 'SIPServerFailoverPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPServer20FailoverRegisterExpire: {
          label: 'SIPServerFailoverRegisterExpire',
          Type: 'text',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20FailoverTransportMode: {
          label: 'SIPGroupFailoverTransportMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPGroup20OutBoundProxyAddress: {
          label: 'SIPGroupOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20OutBoundProxyPort: {
          label: 'SIPGroupOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20FailoverOutBoundProxyAddress: {
          label: 'SIPGroupFailoverOutBoundProxyAddress',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20FailoverOutBoundProxyPort: {
          label: 'SIPGroupFailoverOutBoundProxyPort',
          Type: 'text',
          Value: '5060',
          DefaultValue: '5060',
          ValueRule: '',
          Push: false,
        },
      },
      codec: {
        SIPPreferredVocoder20: {
          label: 'SIPPreferredVocoder',
          Type: 'transfer',
          Value: '0;8;18;9;98;125',
          DefaultValue: '0;8;18;9;98;125',
          ValueRule: '',
          Push: false,
        },
        OpusBandwidth20: {
          label: 'OpusBandwidth',
          Type: 'select',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '0,1,2',
          Push: false,
        },
        iLBCFrameMode20: {
          label: 'iLBCFrameMode',
          Type: 'select',
          Value: '30',
          DefaultValue: '30',
          ValueRule: '20,30',
          Push: false,
        },
        AudioPacketTime20: {
          label: 'AudioPacketTime',
          Type: 'text',
          Value: '20;20;20;20;20;20',
          DefaultValue: '20;20;20;20;20;20',
          ValueRule: '',
          Push: false,
        },
        AudioPayloadTypes20: {
          label: 'AudioPayloadTypes',
          Type: 'text',
          Value: '101;96',
          DefaultValue: '101;96',
          ValueRule: '',
          Push: false,
        },
        AudioVad20: {
          label: 'AudioVad',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
      advanced: {
        TelephonyVmNumber20: {
          label: 'TelephonyVmNumber',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPMessageWaitingIndicationUri20: {
          label: 'SIPMessageWaitingIndicationUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPNConfUri20: {
          label: 'SIPNConfUri',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20ServerType: {
          label: 'SIPGroupServerType',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,4,5,6,10',
          Push: false,
        },
        SIPGroup20DtmfMode: {
          label: 'SIPGroupDtmfMode',
          Type: 'select',
          Value: '2',
          DefaultValue: '2',
          ValueRule: '0,1,2,3,4,5',
          Push: false,
        },
        SIPGroup20SrtpWorkingMode: {
          label: 'SIPGroupSrtpWorkingMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2',
          Push: false,
        },
        SIPServer20KeepAliveEnable: {
          label: 'SIPServerKeepAliveEnable',
          Type: 'boolean',
          Value: 'true',
          DefaultValue: 'true',
          ValueRule: '',
          Push: false,
        },
        SIPServer20GenericPollingTimer: {
          label: 'SIPServerGenericPollingTimer',
          Type: 'text',
          Value: '40',
          DefaultValue: '40',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20SessionTimer: {
          label: 'SIPGroupSessionTimer',
          Type: 'text',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20SessionTimerRefresher: {
          label: 'SIPGroupSessionTimerRefresher',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1',
          Push: false,
        },
        SIPGroup20BLFResourceList: {
          label: 'SIPGroupBLFResourceList',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20BLFListPickUpCode: {
          label: 'SIPGroupBLFListPickUpCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPVoIPTicketsCollector20: {
          label: 'SIPVoIPTicketsCollector',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentity20: {
          label: 'TelephonyHideIdentity',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOnCode20: {
          label: 'TelephonyHideIdentityOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        TelephonyHideIdentityOffCode20: {
          label: 'TelephonyHideIdentityOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejection20: {
          label: 'AnonymousCallRejection',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOnCode20: {
          label: 'AnonymousCallRejectionOnCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        AnonymousCallRejectionOffCode20: {
          label: 'AnonymousCallRejectionOffCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPServer20DigitMap: {
          label: 'SIPServerDigitMap',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        DigitMapTimer20: {
          label: 'DigitMapTimer',
          Type: 'text',
          Value: '1',
          DefaultValue: '1',
          ValueRule: '',
          Push: false,
        },
        SIPPrivacyMode20: {
          label: 'SIPPrivacyMode',
          Type: 'select',
          Value: '0',
          DefaultValue: '0',
          ValueRule: '0,1,2,3',
          Push: false,
        },
        SIPServer20RportEnabled: {
          label: 'SIPServerRportEnabled',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        TelephonyInterphonyStatus20: {
          label: 'TelephonyInterphonyStatus',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPUsePickUpPrefixCode20: {
          label: 'SIPUsePickUpPrefixCode',
          Type: 'text',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SipUserPhoneEnable20: {
          label: 'SipUserPhoneEnable',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20SLAEnable: {
          label: 'SIPGroupSLAEnable',
          Type: 'boolean',
          Value: '',
          DefaultValue: '',
          ValueRule: '',
          Push: false,
        },
        SIPGroup20SIPsURIUsage: {
          label: 'SIPServerSIPsURIUsage',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
        SIPServer20SubscribeExpire: {
          label: 'SIPServerSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer20FailoverSubscribeExpire: {
          label: 'SIPServerFailoverSubscribeExpire',
          Type: 'number',
          Value: '3600',
          DefaultValue: '3600',
          ValueRule: '[60,*]',
          Push: false,
        },
        SIPServer20SwitchoverTimer: {
          label: 'SIPServerSwitchoverTimer',
          Type: 'number',
          Value: '60',
          DefaultValue: '60',
          ValueRule: '[0,*]',
          Push: false,
        },
        SIPGroup20TLSAnticipation: {
          label: 'SIPServerTLSAnticipation',
          Type: 'boolean',
          Value: 'false',
          DefaultValue: 'false',
          ValueRule: '',
          Push: false,
        },
      },
    }
  },
  sipFunction: {
    general: {
      SIPRegisterRetry: {
        Type: 'text',
        Value: '300',
        DefaultValue: '300',
        ValueRule: '',
        Push: false,
      },
      SIPLocalSipPort: {
        Type: 'number',
        Value: '5060',
        DefaultValue: '5060',
        ValueRule: '[0,65535]',
        Push: false,
      },
      SIPLocalSipsPort: {
        Type: 'number',
        Value: '5061',
        DefaultValue: '5061',
        ValueRule: '[0,65535]',
        Push: false,
      },
      SIPLocalSrtpPort: {
        Type: 'number',
        Value: '30000',
        DefaultValue: '30000',
        ValueRule: '[0,65535]',
        Push: false,
      },
      SIPLocalSrtcpPort: {
        Type: 'number',
        Value: '30001',
        DefaultValue: '30001',
        ValueRule: '[0,65535]',
        Push: false,
      },
      SIPLocalRtpPort: {
        Type: 'number',
        Value: '6000',
        DefaultValue: '6000',
        ValueRule: '[0,65535]',
        Push: false,
      },
      SIPLocalRtcpPort: {
        Type: 'number',
        Value: '6001',
        DefaultValue: '6001',
        ValueRule: '[0,65535]',
        Push: false,
      },
      Audiodiffserv: {
        Type: 'number',
        Value: '46',
        DefaultValue: '46',
        ValueRule: '[0,63]',
        Push: false,
      },
      SIPDSCP: {
        Type: 'number',
        Value: '40',
        DefaultValue: '40',
        ValueRule: '[0,63]',
        Push: false,
      },
      SIPMaxCall: {
        Type: 'number',
        Value: '2',
        DefaultValue: '2',
        ValueRule: '[1,5]',
        Push: false,
      },
      SIPLocalConfEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      LocalConfPartyMax: {
        Type: 'number',
        Value: '3',
        DefaultValue: '3',
        ValueRule: '[3,6]',
        Push: false,
      },
      SIPSrtpAuthentication: {
        Type: 'number',
        Value: '1',
        DefaultValue: '1',
        ValueRule: '[0,1]',
        Push: false,
      },
      SIPSslPeerVerify: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SIPSslVersion: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,100,101,102',
        Push: false,
      },
      SIPPoundKeyEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      SIPPeerFilterEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SipPeerToPeerEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      SipPaniEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SIPDefaultAccount: {
        Type: 'select',
        Value: '1',
        DefaultValue: '1',
        ValueRule: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20',
        Push: false,
      },
      SIPUseAutoBLFListEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      ReleaseTimeout: {
        Type: 'number',
        Value: '3',
        DefaultValue: '3',
        ValueRule: '[0,30]',
        Push: false,
      },
      RFC2543HoldEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      RFC5373Allowed: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SIPFailOverEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
    },
  },
  network: {
    ipParameter: {
      LocalEnetcfgDhcpMode: {
        Type: 'select',
        Value: 'Dynamic',
        DefaultValue: 'Dynamic',
        ValueRule: 'Static,Dynamic',
        Push: false,
      },
      LocalEnetcfgIpaddr: {
        Type: 'ipv4',
        Value: '0.0.0.0',
        DefaultValue: '0.0.0.0',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgSubnet: {
        Type: 'ipv4',
        Value: '255.255.255.255',
        DefaultValue: '255.255.255.255',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgRouter: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgDhcpStaticDnsEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgDns1: {
        Type: 'ipv4',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgDns2: {
        Type: 'ipv4',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgDhcpUserClassEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgDhcpUserClass: {
        Type: 'text',
        Value: 'ictouch.class0',
        DefaultValue: 'ictouch.class0',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgDhcpUserClassRfcCompliant: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgVlanEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgVlan: {
        Type: 'number',
        Value: '4095',
        DefaultValue: '4095',
        ValueRule: '[1,4095]',
        Push: false,
      },
      DmEnetcfgDataVlanEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgDataVlan: {
        Type: 'number',
        Value: '',
        DefaultValue: '',
        ValueRule: '[1,4095]',
        Push: false,
      },
      DmEnetcfgInterfaceMtu: {
        Type: 'number',
        Value: '1500',
        DefaultValue: '1500',
        ValueRule: '[128,1500]',
        Push: false,
      },
    },
    port: {
      LocalEthlcfgLanAuto: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      LocalEthlcfgLanSpeed: {
        Type: 'select',
        Value: '100',
        DefaultValue: '100',
        ValueRule: '10,100',
        Push: false,
      },
      LocalEthlcfgLanDuplex: {
        Type: 'select',
        Value: 'Full',
        DefaultValue: 'Full',
        ValueRule: 'Half,Full',
        Push: false,
      },
      LocalEthlcfgPcAuto: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      LocalEthlcfgPcSpeed: {
        Type: 'select',
        Value: '100',
        DefaultValue: '100',
        ValueRule: '10,100',
        Push: false,
      },
      LocalEthlcfgPcDuplex: {
        Type: 'select',
        Value: 'Full',
        DefaultValue: 'Full',
        ValueRule: 'Half,Full',
        Push: false,
      },
      DmEthlcfgPcEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
    },
    lldp: {
      LocalLldpcfgVlanEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      LocalLldpcfgPowerEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      LocalLldpcfgInventoryEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
    },
  },
  provision: {
    autoProvision: {
      LocalEnetcfgDmUrl: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LocalEnetcfgDmBackupUrl: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      HttpAuthUsername: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      HttpAuthPassword: {
        Type: 'password',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmAdmcfgCfgfilePollingEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmAdmcfgCfgfilePollingTimeout: {
        Type: 'text',
        Value: '86400',
        DefaultValue: '86400',
        ValueRule: '',
        Push: false,
      },
      CfgfilePollingByWeekDaysEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      CfgfilePollingBeginTime: {
        Type: 'time',
        Value: '2:00',
        DefaultValue: '2:00',
        ValueRule: '',
        Push: false,
      },
      CfgfilePollingEndTime: {
        Type: 'time',
        Value: '6:00',
        DefaultValue: '6:00',
        ValueRule: '',
        Push: false,
      },
      CfgfilePollingDayofWeek: {
        Type: 'check',
        Value: '0123456',
        DefaultValue: '0123456',
        ValueRule: '',
        Push: false,
      },
    },
    tr069: {
      DmAdmcfgTr069Enabled: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgThirdPartyAcsUrl: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgAcsUsername: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgAcsPassword: {
        Type: 'password',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmAdmcfgAcsPeriodicEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgAcsPeriodicInterval: {
        Type: 'text',
        Value: '1000',
        DefaultValue: '1000',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgAcsConnectionRequestUsername: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgAcsConnectionRequestPassword: {
        Type: 'password',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmAdmcfgStunEnabled: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgStunServerAddress: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgStunServerPort: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
    },
  },
  phoneKeys: {
    programKey: {
      data: [],
      otherData: {
        keys: 36,
        template: {
          Type: {
            Key: 'PhoneProgKey$Type',
            label: 'PhoneProgKey$Type',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,59,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,21,22,23,24,58,60',
          },
          Account: {
            Key: 'PhoneProgKey$Account',
            label: 'PhoneProgKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'PhoneProgKey$Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'PhoneProgKey$Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'PhoneProgKey$Extension',
            Value: '',
            DefaultValue: '',
          },
        },
      },
    },
    dynamicKey: {
      data: [
        {
          Type: {
            Key: 'DesktopDynamicKey1Type',
            label: 'DesktopDynamicKey$Type',
            Value: '101',
            DefaultValue: '101',
            ValueRule: '0,1,3,4,7,10,11,12,13,18,19,21,23,101,102,103',
          },
          Account: {
            Key: 'DesktopDynamicKey1Account',
            label: 'DesktopDynamicKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'DesktopDynamicKey1Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'DesktopDynamicKey1Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'DesktopDynamicKey1Extension',
            Value: '',
            DefaultValue: '',
          },
          Push: false,
          Name: 1,
        },
        {
          Type: {
            Key: 'DesktopDynamicKey2Type',
            label: 'DesktopDynamicKey$Type',
            Value: '3',
            DefaultValue: '3',
            ValueRule: '0,1,3,4,7,10,11,12,13,18,19,21,23,101,102,103',
          },
          Account: {
            Key: 'DesktopDynamicKey2Account',
            label: 'DesktopDynamicKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'DesktopDynamicKey2Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'DesktopDynamicKey2Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'DesktopDynamicKey2Extension',
            Value: '',
            DefaultValue: '',
          },
          Push: false,
          Name: 2,
        },
        {
          Type: {
            Key: 'DesktopDynamicKey3Type',
            label: 'DesktopDynamicKey$Type',
            Value: '102',
            DefaultValue: '102',
            ValueRule: '0,1,3,4,7,10,11,12,13,18,19,21,23,101,102,103',
          },
          Account: {
            Key: 'DesktopDynamicKey3Account',
            label: 'DesktopDynamicKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'DesktopDynamicKey3Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'DesktopDynamicKey3Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'DesktopDynamicKey3Extension',
            Value: '',
            DefaultValue: '',
          },
          Push: false,
          Name: 3,
        },
        {
          Type: {
            Key: 'DesktopDynamicKey4Type',
            label: 'DesktopDynamicKey$Type',
            Value: '4',
            DefaultValue: '4',
            ValueRule: '0,1,3,4,7,10,11,12,13,18,19,21,23,101,102,103',
          },
          Account: {
            Key: 'DesktopDynamicKey4Account',
            label: 'DesktopDynamicKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'DesktopDynamicKey4Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'DesktopDynamicKey4Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'DesktopDynamicKey4Extension',
            Value: '',
            DefaultValue: '',
          },
          Push: false,
          Name: 4,
        },
      ],
      otherData: {
        keys: [
          'Redial',
          'Release',
          'Hold',
          'Mute',
          'Transfer',
          'Message',
          'Conference',
          'Handsfree',
          'Up',
          'Down',
          'Left',
          'Right',
          'OK',
          'Cancel',
          'VolUp',
          'VolDown',
        ],
        template: {
          Type: {
            Key: 'ProgramKey$Type',
            label: 'ProgramKey$Type',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,3,4,7,10,11,12,13,18,19,21,23,101,102,103',
          },
          Account: {
            Key: 'ProgramKey$Account',
            label: 'ProgramKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2,3,4,5,6,7,8',
          },
          Value: {
            Key: 'ProgramKey$Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'ProgramKey$Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'ProgramKey$Extension',
            Value: '',
            DefaultValue: '',
          },
        },
      },
    },
    extKey: {
      Aom1ProgKey: {
        data: [],
        otherData: {
          keys: 60,
          template: {
            Type: {
              Key: 'Aom1ProgKey$Type',
              label: 'Aom1ProgKey$Type',
              Value: '0',
              DefaultValue: '0',
              ValueRule: '0,1,59,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,21,22,23,24,58,60',
            },
            Account: {
              Key: 'Aom1ProgKey$Account',
              label: 'Aom1ProgKey$Account',
              Value: '1',
              DefaultValue: '1',
              ValueRule: '1,2,3,4,5,6,7,8',
            },
            Value: {
              Key: 'Aom1ProgKey$Number',
              Value: '',
              DefaultValue: '',
            },
            Label: {
              Key: 'Aom1ProgKey$Label',
              Value: '',
              DefaultValue: '',
            },
            Extension: {
              Key: 'Aom1ProgKey$Extension',
              Value: '',
              DefaultValue: '',
            },
          },
        },
      },
      Aom2ProgKey: {
        data: [],
        otherData: {
          keys: 60,
          template: {
            Type: {
              Key: 'Aom2ProgKey$Type',
              label: 'Aom2ProgKey$Type',
              Value: '0',
              DefaultValue: '0',
              ValueRule: '0,1,59,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,21,22,23,24,58,60',
            },
            Account: {
              Key: 'Aom2ProgKey$Account',
              label: 'Aom2ProgKey$Account',
              Value: '1',
              DefaultValue: '1',
              ValueRule: '1,2,3,4,5,6,7,8',
            },
            Value: {
              Key: 'Aom2ProgKey$Number',
              Value: '',
              DefaultValue: '',
            },
            Label: {
              Key: 'Aom2ProgKey$Label',
              Value: '',
              DefaultValue: '',
            },
            Extension: {
              Key: 'Aom2ProgKey$Extension',
              Value: '',
              DefaultValue: '',
            },
          },
        },
      },
      Aom3ProgKey: {
        data: [],
        otherData: {
          keys: 60,
          template: {
            Type: {
              Key: 'Aom3ProgKey$Type',
              label: 'Aom3ProgKey$Type',
              Value: '0',
              DefaultValue: '0',
              ValueRule: '0,1,59,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,21,22,23,24,58,60',
            },
            Account: {
              Key: 'Aom3ProgKey$Account',
              label: 'Aom3ProgKey$Account',
              Value: '1',
              DefaultValue: '1',
              ValueRule: '1,2,3,4,5,6,7,8',
            },
            Value: {
              Key: 'Aom3ProgKey$Number',
              Value: '',
              DefaultValue: '',
            },
            Label: {
              Key: 'Aom3ProgKey$Label',
              Value: '',
              DefaultValue: '',
            },
            Extension: {
              Key: 'Aom3ProgKey$Extension',
              Value: '',
              DefaultValue: '',
            },
          },
        },
      },
    },
    dssKey: {
      data: [],
      otherData: {
        keys: 2,
        template: {
          Type: {
            Key: 'PhoneProgKey$Type',
            label: 'PhoneProgKey$Type',
            Value: '1002',
            DefaultValue: '1002',
            ValueRule: '1001,1002,1003,1004,1007,1013,1014,1020,1021,1022',
          },
          SubType: {
            Key: 'PhoneProgKey$SubType',
            label: 'PhoneProgKey$SubType',
            Value: '0000',
            DefaultValue: '0000',
            ValueRule: '0000,0100,0101,0102,0103,0104,0105,0106,0107,0108,0109,0110,0111,0300,0301,0302,0303,0304,0305,0306,0307,0308,0307,0308,0309,0310,0311,0312,0313,0314,0315,0316,0317,0318,0319,0320,0321,0322,0323,0324,0325,0326,0327,0328,0329,0330,0331,0332,0333,0334,1400,1401,1402,1403,1404,1405,1406,1407,1407,1408,1409,2000,2001',
          },
          Account: {
            Key: 'PhoneProgKey$Account',
            label: 'PhoneProgKey$Account',
            Value: '1',
            DefaultValue: '1',
            ValueRule: '1,2',
          },
          Value: {
            Key: 'PhoneProgKey$Number',
            Value: '',
            DefaultValue: '',
          },
          Label: {
            Key: 'PhoneProgKey$Label',
            Value: '',
            DefaultValue: '',
          },
          Extension: {
            Key: 'PhoneProgKey$Extension',
            Value: '',
            DefaultValue: '',
          },
        },
      },
    },
  },
  settings: {
    general: {
      KeyAsSend: {
        Type: 'select',
        Value: '1',
        DefaultValue: '1',
        ValueRule: '0,1,2',
        Push: false,
      },
      CallIdleTimeout: {
        Type: 'text',
        Value: '5',
        DefaultValue: '5',
        ValueRule: '',
        Push: false,
      },
      StutterToneEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      DialingToneEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      CallNumFilter: {
        Type: 'text',
        Value: ',-()',
        DefaultValue: ',-()',
        ValueRule: '',
        Push: false,
      },
      CallWaitingToneEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      WUISessionTimeout: {
        Type: 'number',
        Value: '600',
        DefaultValue: '600',
        ValueRule: '[30,86400]',
        Push: false,
      },
      WUISessionTimeoutH2: {
        Type: 'number',
        Value: '15',
        DefaultValue: '15',
        ValueRule: '[10,30]',
        Push: false,
      },
      HearRingBackToneTimeout: {
        Type: 'number',
        Value: '60',
        DefaultValue: '60',
        ValueRule: '[0,120]',
        Push: false,
      },
      CallCompletionEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      AutoRedialEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      AutoRedialInterval: {
        Type: 'number',
        Value: '10',
        DefaultValue: '10',
        ValueRule: '[1,60]',
        Push: false,
      },
      AutoRedialTimes: {
        Type: 'number',
        Value: '5',
        DefaultValue: '5',
        ValueRule: '[1,10]',
        Push: false,
      },
      ConfidentialDialEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      ConfidentialDialPrefix: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ConfidentialDialLength: {
        Type: 'text',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '',
        Push: false,
      },
    },
    timeDate: {
      DmEnetcfgSntp: {
        Type: 'text',
        Value: '0.pool.ntp.org',
        DefaultValue: '0.pool.ntp.org',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgSntp2: {
        Type: 'text',
        Value: 'time.nist.gov',
        DefaultValue: 'time.nist.gov',
        ValueRule: '',
        Push: false,
      },
      DmEnetcfgSntpRefreshPeriod: {
        Type: 'text',
        Value: '3600',
        DefaultValue: '3600',
        ValueRule: '',
        Push: false,
      },
      DmAdmcfgTimeZoneUtoffset: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '-11:00,-10:00,-9:30,-9:00,-8:00,-7:00,-6:00,-5:00,-4:30,-4:00,-3:30,-3:00,-2:30,-2:00,-1:00,0,+1:00,+2:00,+3:00,+3:30,+4:00,+4:30,+5:00,+5:30,+5:45,+6:00,+6:30,+7:00,+8:00,+8:45,+9:00,+9:30,+10:00,+10:30,+11:00,+11:30,+12:00,+12:45,+13:00,+13:30,+14:00',
        Push: false,
        Dependency: 'DSTLocation'
      },
      DmAdmcfgDstEnable: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2',
        Push: false,
      },
      DmAdmcfgTimeZoneLocation: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      DSTLocation: {
        Type: 'select',
        Value: '',
        DefaultValue: '0#0',
        ValueRule: '-11:00#0,-11:00#1,-10:00#0,-10:00#1,-10:00#2,-9:30#0,-9:30#1,-9:00#0,-9:00#1,-8:00#0,-8:00#1,-8:00#2,-8:00#3,-7:00#0,-7:00#1,-7:00#2,-7:00#3,-7:00#4,-6:00#0,-6:00#1,-6:00#2,-6:00#3,-6:00#4,-5:00#0,-5:00#1,-5:00#2,-5:00#3,-5:00#4,-4:30#0,-4:30#1,-4:00#0,-4:00#1,-4:00#2,-4:00#3,-4:00#4,-4:00#5,-4:00#6,-3:30#0,-3:30#1,-3:00#0,-3:00#1,-3:00#2,-3:00#3,-3:00#4,-2:30#0,-2:30#1,-2:00#0,-2:00#1,-1:00#0,-1:00#1,0#0,0#1,0#2,0#3,0#4,0#5,0#6,0#7,0#8,+1:00#0,+1:00#1,+1:00#2,+1:00#3,+1:00#4,+1:00#5,+1:00#6,+1:00#7,+1:00#8,+1:00#9,+1:00#10,+1:00#11,+1:00#12,+1:00#13,+1:00#14,+1:00#15,+1:00#16,+1:00#17,+2:00#0,+2:00#1,+2:00#2,+2:00#3,+2:00#4,+2:00#5,+2:00#6,+2:00#7,+2:00#8,+2:00#9,+2:00#10,+2:00#11,+2:00#12,+2:00#13,+2:00#14,+3:00#0,+3:00#1,+3:00#2,+3:00#3,+3:30#0,+3:30#1,+4:00#0,+4:00#1,+4:00#2,+4:00#3,+4:00#4,+4:00#5,+4:30#0,+4:30#1,+5:00#0,+5:00#1,+5:00#2,+5:00#3,+5:00#4,+5:30#0,+5:30#1,+5:45#0,+5:45#1,+6:00#0,+6:00#1,+6:00#2,+6:30#0,+6:30#1,+7:00#0,+7:00#1,+7:00#2,+8:00#0,+8:00#1,+8:00#2,+8:00#3,+8:00#4,+8:45#0,+8:45#1,+9:00#0,+9:00#1,+9:00#2,+9:00#3,+9:30#0,+9:30#1,+9:30#2,+10:00#0,+10:00#1,+10:00#2,+10:00#3,+10:00#4,+10:30#0,+10:30#1,+11:00#0,+11:00#1,+11:00#2,+11:30#0,+11:30#1,+12:00#0,+12:00#1,+12:00#2,+12:45#0,+12:45#1,+13:00#0,+13:00#1,+13:00#2,+13:30#0,+13:30#1,+14:00#0,+14:00#1',
        FilteredValueRule: '',
        Push: false,
      },
      DmAdmcfgDstType: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: 'week,date',
        Push: false,
      },
      DmAdmcfgDstStartMonth: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
        Push: false,
      },
      DMAdmcfgDstStartDate: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule:
          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31',
        Push: false,
      },
      DMAdmcfgDstStartWeek: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: '1,2,3,4,5',
        Push: false,
      },
      DMAdmcfgDstStartWeekday: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: 'Sun,Mon,Tue,Wed,Thu,Fri,Sat',
        Push: false,
      },
      DmAdmcfgDstStartHour: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23',
        Push: false,
      },
      DmAdmcfgDstEndMonth: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
        Push: false,
      },
      DMAdmcfgDstEndDate: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule:
          '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31',
        Push: false,
      },
      DMAdmcfgDstEndWeek: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: '1,2,3,4,5',
        Push: false,
      },
      DMAdmcfgDstEndWeekday: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: 'Sun,Mon,Tue,Wed,Thu,Fri,Sat',
        Push: false,
      },
      DMAdmcfgDstEndHour: {
        Type: 'select',
        Value: '',
        DefaultValue: '',
        ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23',
        Push: false,
      },
      DmAdmcfgDstOffset: {
        Type: 'CHOICE',
        Value: '60',
        DefaultValue: '60',
        ValueRule: '-300~300',
        Push: false,
      },
      DateFormat: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,100,101,102,103,104,105,106,107,108,109',
        Push: false,
      },
      DateSeperator: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3',
        Push: false,
      },
      TimeFormat: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1',
        Push: false,
      },
    },
    callDisplay: {
      CallDisplayPart: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3,4,100,101,104,105',
        Push: false,
      },
      FullNameDisplay: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3',
        Push: false,
      },
    },
    audio: {
      AudioToneCountry: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,400',
        Push: false,
      },
      AudioDtmfLevel: {
        Type: 'text',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '',
        Push: false,
      },
      AudioDtmfDuration: {
        Type: 'select',
        Value: '2',
        DefaultValue: '2',
        ValueRule: '1,2,3,4',
        Push: false,
      },
      AudioDtmfFeedbackEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      AudioHearingAidEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      Audio8021p: {
        Type: 'select',
        Value: '5',
        DefaultValue: '5',
        ValueRule: '0,1,2,3,4,5,6,7',
        Push: false,
      },
    },
    display: {
      ScreenSaverEnabled: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      SipScreenSaverTimeout: {
        Type: 'select',
        Value: '300',
        DefaultValue: '300',
        ValueRule: '60,120,300,600,1800,3600,7200,10800,21600',
        Push: false,
      },
      BackLightTimeout: {
        Type: 'number',
        Value: '300',
        DefaultValue: '300',
        ValueRule: '[0,1800]',
        Push: false,
      },
      InactiveBackLightLevel: {
        Type: 'number',
        Value: '1',
        DefaultValue: '1',
        ValueRule: '[1,9]',
        Push: false,
      },
      BackLightLevel: {
        Type: 'number',
        Value: '5',
        DefaultValue: '5',
        ValueRule: '[1,9]',
        Push: false,
      },
    },
    ringing: {
      AudioRingDevice: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2',
        Push: false,
      },
      AudioRingProgressive: {
        Type: 'boolean',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '',
        Push: false,
      },
      AudioRingChoice: {
        Type: 'select',
        Value: '101',
        DefaultValue: '101',
        ValueRule: '101,102,103,104,105,106,107,108,109,110',
        Push: false,
      },
      AudioRingInternalChoice: {
        Type: 'select',
        Value: '00',
        ValueTemplate: '/usr/share/ringdata/ring$.wav',
        DefaultValue: '/usr/share/ringdata/ring00.wav',
        ValueRule: '00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15',
        Push: false,
      },
      AudioRingExternalChoice: {
        Type: 'select',
        Value: '00',
        ValueTemplate: '/usr/share/ringdata/ring$.wav',
        DefaultValue: '/usr/share/ringdata/ring00.wav',
        ValueRule: '00,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15',
        Push: false,
      },
      AudioRingBeep: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,2,3',
        Push: false,
      },
      AudioRingSilent: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SIPRingerText: [
        {
          SIPRingerText1: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile1: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText2: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile2: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText3: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile3: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText4: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile4: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText5: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile5: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText6: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile6: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText7: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile7: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText8: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile8: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText9: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile9: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
        {
          SIPRingerText10: {
            label: 'SIPRingerText',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          SIPRingerFile10: {
            label: 'SIPRingerFile',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,101,102,103,104,105,106,107,108,109,110',
            Push: false,
          },
        },
      ],
      RingingTimeout: {
        Type: 'text',
        Value: '60',
        DefaultValue: '60',
        ValueRule: '',
        Push: false,
      },
    },
    dialingRule: {
      accountArr: [
        {
          DialingRuleEnableHistory1: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact1: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward1: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual1: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server1DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server1DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server1DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server1DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server1DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory2: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact2: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward2: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual2: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server2DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server2DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server2DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server2DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server2DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory3: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact3: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward3: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual3: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server3DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server3DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server3DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server3DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server3DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory4: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact4: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward4: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual4: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server4DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server4DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server4DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server4DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server4DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory5: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact5: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward5: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual5: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server5DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server5DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server5DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server5DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server5DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory6: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact6: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward6: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual6: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server6DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server6DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server6DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server6DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server6DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory7: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact7: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward7: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual7: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server7DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server7DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server7DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server7DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server7DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          DialingRuleEnableHistory8: {
            label: 'DialingRuleEnableHistory',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableContact8: {
            label: 'DialingRuleEnableContact',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableForward8: {
            label: 'DialingRuleEnableForward',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          DialingRuleEnableManual8: {
            label: 'DialingRuleEnableManual',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          Server8DialingRuleCountryCode: {
            label: 'ServerDialingRuleCountryCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server8DialingRuleAreaCode: {
            label: 'ServerDialingRuleAreaCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server8DialingRuleExternalPrefix: {
            label: 'ServerDialingRuleExternalPrefix',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server8DialingRuleExternalPrefixExceptions: {
            label: 'ServerDialingRuleExternalPrefixExceptions',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          Server8DialingRuleMinNumberLength: {
            label: 'ServerDialingRuleMinNumberLength',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
      ],
    },
    phoneLock: {
      ScreenLockEnable: {
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      ScreenAutomaticLockEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      ScreenLockTimeout: {
        Type: 'text',
        Value: '300',
        DefaultValue: '300',
        ValueRule: '',
        Push: false,
      },
      ScreenLockPassword: {
        Type: 'password',
        Value: '0000',
        DefaultValue: '0000',
        ValueRule: '',
        Push: false,
      },
      EmergencyNumber: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
    },
  },
  function: {
    forward: {
      TelephonyFwdMethod: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1',
        Push: false,
      },
      ForwardModeAccount: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1',
        Push: false,
      },
      ForwardImmState: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      ForwardImmDest: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardImmOnCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardImmOffCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardBusyState: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      ForwardBusyDest: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardBusyOnCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardBusyOffCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardNoReplyState: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      ForwardNoReplyDest: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardNoReplyOnCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      ForwardNoReplyOffCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      accountArr: [
        {
          TelephonyFwdMethod1: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState1: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest1: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode1: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode1: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState1: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest1: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode1: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode1: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState1: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest1: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode1: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode1: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod2: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState2: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest2: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode2: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode2: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState2: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest2: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode2: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode2: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState2: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest2: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode2: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode2: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod3: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState3: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest3: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode3: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode3: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState3: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest3: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode3: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode3: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState3: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest3: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode3: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode3: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod4: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState4: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest4: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode4: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode4: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState4: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest4: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode4: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode4: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState4: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest4: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode4: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode4: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod5: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState5: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest5: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode5: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode5: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState5: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest5: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode5: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode5: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState5: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest5: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode5: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode5: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod6: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState6: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest6: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode6: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode6: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState6: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest6: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode6: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode6: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState6: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest6: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode6: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode6: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod7: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState7: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest7: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode7: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode7: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState7: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest7: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode7: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode7: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState7: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest7: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode7: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode7: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyFwdMethod8: {
            label: 'TelephonyFwdMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          ForwardImmState8: {
            label: 'ForwardImmState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardImmDest8: {
            label: 'ForwardImmDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOnCode8: {
            label: 'ForwardImmOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardImmOffCode8: {
            label: 'ForwardImmOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyState8: {
            label: 'ForwardBusyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyDest8: {
            label: 'ForwardBusyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOnCode8: {
            label: 'ForwardBusyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardBusyOffCode8: {
            label: 'ForwardBusyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyState8: {
            label: 'ForwardNoReplyState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyDest8: {
            label: 'ForwardNoReplyDest',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOnCode8: {
            label: 'ForwardNoReplyOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          ForwardNoReplyOffCode8: {
            label: 'ForwardNoReplyOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
      ],
    },
    dnd: {
      TelephonyDndMethod: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1',
        Push: false,
      },
      DndModeAccount: {
        Type: 'select',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '0,1,100,101,102',
        Push: false,
      },
      TelephonyDndState: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      TelephonyDndOnCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      TelephonyDndOffCode: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      accountArr: [
        {
          TelephonyDndMethod1: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState1: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode1: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode1: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod2: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState2: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode2: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode2: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod3: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState3: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode3: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode3: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod4: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState4: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode4: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode4: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod5: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState5: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode5: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode5: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod6: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState6: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode6: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode6: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod7: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState7: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode7: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode7: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          TelephonyDndMethod8: {
            label: 'TelephonyDndMethod',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          TelephonyDndState8: {
            label: 'TelephonyDndState',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOnCode8: {
            label: 'TelephonyDndOnCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          TelephonyDndOffCode8: {
            label: 'TelephonyDndOffCode',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
      ],
    },
    intercom: {
      SIPAutoAnsweredAllowed: {
        label: 'SIPAutoAnsweredAllowed',
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      SIPAutoAnsweredMute: {
        label: 'SIPAutoAnsweredMute',
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      SIPAutoAnsweredTone: {
        label: 'SIPAutoAnsweredTone',
        Type: 'boolean',
        Value: 'true',
        DefaultValue: 'true',
        ValueRule: '',
        Push: false,
      },
      SIPAutoAnsweredBarge: {
        label: 'SIPAutoAnsweredBarge',
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      accountArr: [
        {
          SIPAutoAnsweredAllowed1: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute1: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone1: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge1: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup1IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed2: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute2: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone2: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge2: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup2IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed3: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute3: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone3: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge3: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup3IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed4: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute4: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone4: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge4: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup4IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed5: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute5: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone5: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge5: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup5IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed6: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute6: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone6: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge6: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup6IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed7: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute7: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone7: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge7: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup7IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
        {
          SIPAutoAnsweredAllowed8: {
            label: 'SIPAutoAnsweredAllowed',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredMute8: {
            label: 'SIPAutoAnsweredMute',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredTone8: {
            label: 'SIPAutoAnsweredTone',
            Type: 'boolean',
            Value: 'true',
            DefaultValue: 'true',
            ValueRule: '',
            Push: false,
          },
          SIPAutoAnsweredBarge8: {
            label: 'SIPAutoAnsweredBarge',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          SIPGroup8IntercomType: {
            label: 'SIPGroupIntercomType',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
        },
      ],
    },
    hotline: {
      HotlineEnabled: {
        label: 'HotlineEnabled',
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      HotlineDelayTimeout: {
        label: 'HotlineDelayTimeout',
        Type: 'text',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '',
        Push: false,
      },
      HotlineNumber: {
        label: 'HotlineNumber',
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      accountArr: [
        {
          HotlineEnabled1: {
            label: 'HotlineEnabled',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          HotlineDelayTimeout1: {
            label: 'HotlineDelayTimeout',
            Type: 'text',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '',
            Push: false,
          },
          HotlineNumber1: {
            label: 'HotlineNumber',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
        {
          HotlineEnabled2: {
            label: 'HotlineEnabled',
            Type: 'boolean',
            Value: 'false',
            DefaultValue: 'false',
            ValueRule: '',
            Push: false,
          },
          HotlineDelayTimeout2: {
            label: 'HotlineDelayTimeout',
            Type: 'text',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '',
            Push: false,
          },
          HotlineNumber2: {
            label: 'HotlineNumber',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        }
      ]
    },
  },
  directoryHistory: {
    ldap: {
      LDAPEnabled: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LDAPServerUrl: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LDAPSearchBase: {
        Type: 'text',
        Value: 'o=Alcatel,o=directoryRoot',
        DefaultValue: 'o=Alcatel,o=directoryRoot',
        ValueRule: '',
        Push: false,
      },
      LDAPFieldsMapping: {
        Type: 'text',
        Value:
          '{&quot;firstname&quot;:&quot;givenname&quot;, &quot;name&quot;:&quot;sn&quot;, &quot;officephone&quot;:&quot;telephonenumber&quot;}',
        DefaultValue:
          '{&quot;firstname&quot;:&quot;givenname&quot;, &quot;name&quot;:&quot;sn&quot;, &quot;officephone&quot;:&quot;telephonenumber&quot;}',
        ValueRule: '',
        Push: false,
      },
      LDAPFilter: {
        Type: 'text',
        Value: '(|(givenName=*%1*)(sn=*%1*))',
        DefaultValue: '(|(givenName=*%1*)(sn=*%1*))',
        ValueRule: '',
        Push: false,
      },
      LDAPLogin: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LDAPPassword: {
        Type: 'password',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LDAPConnectTimeout: {
        Type: 'text',
        Value: '3',
        DefaultValue: '3',
        ValueRule: '',
        Push: false,
      },
      LDAPSearchTimeout: {
        Type: 'text',
        Value: '5',
        DefaultValue: '5',
        ValueRule: '',
        Push: false,
      },
      LDAPCallLookup: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LDAPMaxHits: {
        Type: 'number',
        Value: '50',
        DefaultValue: '50',
        ValueRule: '[1,1000]',
        Push: false,
      },
      LDAPArr: [
        {
          LDAPTitle1: {
            label: 'LDAPTitle',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          LDAPServerUrl1: {
            label: 'LDAPServerUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          LDAPServerPort1: {
            label: 'LDAPServerPort',
            Type: 'text',
            Value: '',
            DefaultValue: '389',
            ValueRule: '',
            Push: false,
          },
          LDAPServerUseSSL1: {
            label: 'LDAPServerUseSSL',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1,2',
            Push: false,
          },
          LDAPServerCallingAcc1: {
            label: 'LDAPServerCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
          LDAPServerVersion1: {
            label: 'LDAPServerVersion',
            Type: 'select',
            Value: '3',
            DefaultValue: '3',
            ValueRule: '2,3',
            Push: false,
          },
          LDAPServerAuthenticate1: {
            label: 'LDAPServerAuthenticate',
            Type: 'select',
            Value: '0',
            DefaultValue: '3',
            ValueRule: '0,1,2,3',
            Push: false,
          },
          LDAPLogin1: {
            label: 'LDAPLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          LDAPPassword1: {
            label: 'LDAPPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          LDAPSearchBase1: {
            label: 'LDAPSearchBase',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          LDAPDisplayName1: {
            label: 'LDAPDisplayName',
            Type: 'text',
            Value: '',
            DefaultValue: 'cn',
            ValueRule: '',
            Push: false,
          },
          LDAPNumberFilter1: {
            label: 'LDAPNumberFilter',
            Type: 'text',
            Value: '',
            DefaultValue: '(|(telephoneNumber=%)(mobile=%)(other=%))',
            ValueRule: '',
            Push: false,
          },
          LDAPNameFilter1: {
            label: 'LDAPNameFilter',
            Type: 'text',
            Value: '',
            DefaultValue: '(|(cn=%)(sn=%))',
            ValueRule: '',
            Push: false,
          },
          LDAPTelAttr1: {
            label: 'LDAPTelAttr',
            Type: 'text',
            Value: '',
            DefaultValue: 'telephoneNumber',
            ValueRule: '',
            Push: false,
          },
          LDAPMobileAttr1: {
            label: 'LDAPMobileAttr',
            Type: 'text',
            Value: '',
            DefaultValue: 'mobile',
            ValueRule: '',
            Push: false,
          },
          LDAPOtherAttr1: {
            label: 'LDAPOtherAttr',
            Type: 'text',
            Value: '',
            DefaultValue: 'other',
            ValueRule: '',
            Push: false,
          },
          LDAPNameAttr1: {
            label: 'LDAPNameAttr',
            Type: 'text',
            Value: '',
            DefaultValue: 'cn sn ou',
            ValueRule: '',
            Push: false,
          },
          LDAPSortAttr1: {
            label: 'LDAPSortAttr',
            Type: 'text',
            Value: '',
            DefaultValue: 'cn',
            ValueRule: '',
            Push: false,
          },
          LDAPInCallSearchEnabled1: {
            label: 'LDAPInCallSearchEnabled',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          LDAPOutCallSearchEnabled1: {
            label: 'LDAPOutCallSearchEnabled',
            Type: 'select',
            Value: '0',
            DefaultValue: '0',
            ValueRule: '0,1',
            Push: false,
          },
          LDAPMaxHits1: {
            label: 'LDAPMaxHits',
            Type: 'number',
            Value: '50',
            DefaultValue: '50',
            ValueRule: '[1,1000]',
            Push: false,
          },
        },
      ]
    },
    remotePhoneBook: {
      RemotePhoneBookEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      remotePhoneBookArr: [
        {
          RemotePhoneBook1Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook1GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook1Login: {
            label: 'RemotePhoneBookLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook1Password: {
            label: 'RemotePhoneBookPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook1CallingAcc: {
            label: 'RemotePhoneBookCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
        },
        {
          RemotePhoneBook2Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook2GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook2Login: {
            label: 'RemotePhoneBookLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook2Password: {
            label: 'RemotePhoneBookPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook2CallingAcc: {
            label: 'RemotePhoneBookCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
        },
        {
          RemotePhoneBook3Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook3GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook3Login: {
            label: 'RemotePhoneBookLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook3Password: {
            label: 'RemotePhoneBookPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook3CallingAcc: {
            label: 'RemotePhoneBookCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
        },
        {
          RemotePhoneBook4Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook4GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook4Login: {
            label: 'RemotePhoneBookLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook4Password: {
            label: 'RemotePhoneBookPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook4CallingAcc: {
            label: 'RemotePhoneBookCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
        },
        {
          RemotePhoneBook5Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook5GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook5Login: {
            label: 'RemotePhoneBookLogin',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook5Password: {
            label: 'RemotePhoneBookPassword',
            Type: 'password',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook5CallingAcc: {
            label: 'RemotePhoneBookCallingAcc',
            Type: 'select',
            Value: '-1',
            DefaultValue: '-1',
            ValueRule: '-1,1,2',
            Push: false,
          },
        },
        {
          RemotePhoneBook6Url: {
            label: 'RemotePhoneBookUrl',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
          RemotePhoneBook6GroupName: {
            label: 'RemotePhoneBookGroupName',
            Type: 'text',
            Value: '',
            DefaultValue: '',
            ValueRule: '',
            Push: false,
          },
        },
      ],
      RemotePhoneBookForceUpdateMode: {
        Type: 'boolean',
        Value: '0',
        DefaultValue: '0',
        ValueRule: '',
        Push: false,
      },
      RemotePhoneBookPeriodUpdateEnable: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      RemotePhoneBookInterval: {
        Type: 'text',
        Value: '3600',
        DefaultValue: '3600',
        ValueRule: '',
        Push: false,
      },
    },
  },
  maintenance: {
    logCollection: {
      LogRemoteServerExist: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      LogRemoteServerAddress: {
        Type: 'text',
        Value: '',
        DefaultValue: '',
        ValueRule: '',
        Push: false,
      },
      LogRemoteServerPort: {
        Type: 'text',
        Value: '514',
        DefaultValue: '514',
        ValueRule: '',
        Push: false,
      },
      LogRemoteServerProtocol: {
        Type: 'select',
        Value: 'udp',
        DefaultValue: 'udp',
        ValueRule: 'udp,tcp',
        Push: false,
      },
    },
    changePassword: {
      DmAdminPasswd: {
        Type: 'password',
        Value: '123456',
        DefaultValue: '123456',
        ValueRule: '',
        Push: false,
      },
    },
    security: {
      DmSecucfgSsh: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
      TelnetEnabled: {
        Type: 'boolean',
        Value: 'false',
        DefaultValue: 'false',
        ValueRule: '',
        Push: false,
      },
    },
  },
}
