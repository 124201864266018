<template>
  <el-select
    v-model="newValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    style="width: 100%;"
  >
    <el-option
      v-for="item in optionData"
      :key="item[maps.value]"
      :label="getLabel(item)"
      :value="item[maps.value]"
    ></el-option>
  </el-select>
</template>

<script>
import selectMixin from './mixin/select'
import { groupTempSelect } from '@/api/group'

export default {
  name: 'select-group',
  mixins: [selectMixin],
  props: {
    placeholder: {
      type: String,
      default() {
        return this.$t('components.selectGroup.allGroup')
      },
    },
    selectAllText: {
      type: String,
      default() {
        return this.$t('components.selectGroup.allGroup')
      },
    },
    maps: {
      type: Object,
      default: () => {
        return {
          label: 'name',
          subLabel: 'name',
          value: 'id',
        }
      },
    },
  },
  created() {
    if (this.isArray && this.data.length) {
      this.setData(this.data)
    } else {
      this.getData(groupTempSelect)
    }
  },
}
</script>
