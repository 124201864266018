import store from '@/store'
import { isIdInner } from 'echarts/lib/util/model'
import jszip from 'jszip'

// 递归
export const recursive = (data, callback, children = 'children') => {
  if (Array.isArray(data)) {
    data.forEach((item) => {
      if (item[children] && item[children].length) {
        recursive(item[children], callback)
      } else {
        callback && callback(item)
      }
    })
  }
}

// 下载文件流
export const downloadFile = (res, customFileName) => {
  const filename = customFileName ? customFileName : (res.headers['content-disposition']).split('filename=')[1]
  const blob = new Blob([res.data])
  var downloadElement = document.createElement('a')
  var href = window.URL.createObjectURL(blob)
  downloadElement.href = href
  downloadElement.download = decodeURI(filename)
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(href)
}

// 下载文件URL
export const downloadFileUrl = (uploadRealFileUrl) => {
  const urlArr = uploadRealFileUrl.split('\\')
  var downloadElement = document.createElement('a')
  // 局端不要加协议（https/http）
  downloadElement.href = uploadRealFileUrl
  console.log('href', downloadElement.href)
  downloadElement.setAttribute('download', urlArr[urlArr.length - 1])
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
}

// fileSize: 0 不限制大小
export const fileCheck = ({ file, fileTypes, fileSize = 0, callback }) => {
  // 文件格式
  let typeErr = !fileTypes.some(e => file.name.endsWith(e))
  // 文件大小
  let sizeErr = fileSize ? file.size < fileSize ? false : true : false;
  // 回调函数
  callback(typeErr, sizeErr)
  return typeErr || sizeErr ? false : true
}

export const isGoodZipContent = (content) => {
  var zip = new jszip();
  return zip.loadAsync(content)
    .then(function (zip) {
      let all = zip.files;
      if (anyIsDir(all)) {
        return false;
      } else {
        return true;
      }
    });
}

let anyIsDir = (files) => {
  for (const key in files) {
    if (Object.hasOwnProperty.call(files, key)) {
      const file = files[key];
      if(file.dir){
        return true;
      }
    }
  }
  return false;
}

// IP格式化
export function ipFormat(val) {
  const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(val);
}

// MAC格式化
export function macFormat(val, split = ':') {
  let str = val.replace(/[-:]/g, split).trim()
  const reg = /^([a-fA-F0-9]{2}:){5}[a-fA-F0-9]{2}$/
  const reg2 = /^([a-fA-F0-9]{2}){6}$/
  if (reg.test(str)) {
    return str
  } else if (reg2.test(str)) {
    let newStr = ''
    for (let i = 0; i < 6; i++) {
      newStr += (i ? split : '') + str.substr(i * 2, 2)
    }
    return newStr
  } else {
    return false
  }
}

// 驼峰命名转下划线命名
// export function Hump2Underline(s, pla = '_') {
//   return s.replace(/([A-Z])/g, pla + '$1').toLowerCase()
// }

// 推送成功提示
export function pushSuccessMassage(vue) {
  const h = vue.$createElement
  const message = vue.$message.success({
    duration: 6000,
    message: h('P', { class: 'el-message__content' }, [
      `${vue.$t('task.successTip')} `,
      h(
        'el-link',
        {
          attrs: { type: 'primary' },
          style: { verticalAlign: 'top' },
          on: {
            click: () => {
              vue.$router.push('/task')
              message.close()
            },
          },
        },
        vue.$t('device.clickToViewTheTask')
      ),
    ]),
  })
}

// 获取对象数组中某项key重复的特定值集合
export function getNonUniqueIds(arr, key) {
  let ids = arr.map((item) => {
    return item[key]
  })

  let repeatIds = ids.filter((item) => {
    return ids.indexOf(item) !== ids.lastIndexOf(item)
  })
  return repeatIds || []
}

// 获取对象数组中的特定值
export function getCurrentValue(val, currentKey, targetKey, arr) {
  const targetItem = getCurrentItem(val, currentKey, arr)
  return targetItem ? targetItem[targetKey] : ''
}

// 获取对象数组中特定值对应的某一项
export function getCurrentItem(val, currentKey, arr) {
  const targetItem = arr.find((item) => {
    return item[currentKey] == val
  })
  return targetItem
}