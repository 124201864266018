<template>
  <div>
    <el-checkbox
      :indeterminate="isIndeterminate"
      v-model="checkAll"
      @change="handleCheckAllChange"
    >
      <span style="font-weight: bold;">{{ label }}</span>
    </el-checkbox>
    <el-checkbox-group
      class="sub-checkbox"
      v-model="newValue"
      @change="handleCheckedCitiesChange"
    >
      <el-checkbox
        class="checkbox-item"
        v-for="item in options"
        :key="item.id"
        :label="item.id"
        :disabled="checkboxDisable(item, newValue)"
      >{{ labelText('rsName', item) }}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [Array, Boolean],
      default() {
        return []
      },
    },
    checkboxOptions: Array,
    label: String,
  },
  data() {
    let checkAll = Array.isArray(this.value)
      ? this.checkboxOptions.length && this.checkboxOptions.length == this.value.length
        ? true
        : false
      : this.value
    return {
      checkAll,
      isIndeterminate: checkAll ? false : !!this.value.length,
    }
  },
  watch: {
    value(value) {
      this.checkAll = Array.isArray(value)
        ? this.checkboxOptions.length && this.checkboxOptions.length == value.length
          ? true
          : false
        : value
      this.isIndeterminate = false
    },
  },
  computed: {
    ...mapState('memory', ['language']),
    // 选项文字（根据语言显示）
    labelText() {
      return (type, item) => {
        return this.language === 'en'
          ? item[type]
          : item[`${type}Cn`]
      }
    },
    newValue: {
      get() {
        return Array.isArray(this.value) ? this.value : []
      },
      set(val) {
        if (this.checkboxOptions.length) {
          this.$emit('change', val)
        } else {
          this.$emit('change', this.checkAll)
        }
      },
    },
    options() {
      return this.checkboxOptions
    },
    checkboxDisable() {
      return (item, newValue) => {
        // 查看权限禁用
        return item.rsName.endsWith('list') && newValue.includes(item.id) && newValue.length > 1
      }
    }
  },
  methods: {
    handleCheckAllChange(val) {
      this.newValue = val ? this.checkboxOptions.map((e) => e.id) : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.options.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-checkbox {
  padding-left: 20px;
  .checkbox-item {
    width: calc((100% - 110px) / 3);
    min-width: 325px;
  }
}
</style>