export default [
  {
    path: '/channelManagement',
    name: 'channelManagement',
    meta: {
      power: 'channelManagement',
    },
    component: () => import(/* webpackChunkName: "Channel" */ '@/views/Channel/Channel.vue'),
  },
  {
    path: '/channelManagement/addChannel',
    name: 'addChannel',
    meta: {
      power: 'channelManagement',
    },
    component: () =>
      import(/* webpackChunkName: "ChannelDetails" */ '@/views/Channel/ChannelDetails.vue'),
  },
  {
    path: '/channelManagement/editChannel',
    name: 'editChannel',
    meta: {
      power: 'channelManagement',
    },
    component: () =>
      import(/* webpackChunkName: "ChannelDetails" */ '@/views/Channel/ChannelDetails.vue'),
  },
]
