<template>
  <el-checkbox-group v-model="newValue" size="mini">
    <el-checkbox v-for="(item, i) in defaultValueArr" :key="i" :label="item" :disabled="disabled">{{
      $t(`sitParam.${label}Values.${item}`)
    }}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'sp-check',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    item: Object,
    label: String,
  },
  data() {
    return {
      defaultValueArr: this.item.DefaultValue.split(''),
    }
  },
  computed: {
    newValue: {
      get() {
        return this.value.split('')
      },
      set(val) {
        let data = []
        this.defaultValueArr.forEach((e) => {
          val.includes(e) && data.push(e)
        })
        this.$emit('change', data.join(''))
      },
    },
  },
}
</script>
