export default [
  {
    path: '/deviceFilter',
    name: 'deviceFilter',
    meta: {
      power: 'deviceFilter',
    },
    component: () => import(/* webpackChunkName: "DeviceFilter" */ '@/views/DeviceFilter/DeviceFilter.vue'),
  }
]
