<template>
  <el-select
    v-model="newValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    :multiple="multiple"
    collapse-tags
    style="width: 100%;"
  >
    <el-option
      v-for="item in optionData"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import selectMixin from './mixin/select'
import { commonModel } from '@/api/app'
import { modelSelect } from '@/api/model'

export default {
  name: 'select-model',
  mixins: [selectMixin],
  props: {
    multiple: Boolean,
    defaultSelectAll: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default() {
        return window.vm.$t('components.selectModel.selectModelPla')
      },
    },
    modelOptions: Array,
    modelType: {
      type: String,
      default: 'General',
    },
    selectAllText: {
      type: String,
      default() {
        return window.vm.$t('components.selectModel.allModel')
      },
    },
    maps: {
      type: Object,
      default: () => {
        return {
          label: 'label',
          value: 'value',
        }
      },
    },
    siteIds: Array,
  },
  created() {
    if (!this.siteIds) {
      if (this.isArray && this.data.length) {
        this.setData(this.data)
      } else {
        this.getData()
      }
    }
  },
  watch: {
    siteIds(val) {
      if (val.length) this.getModelSelect()
    },
  },
  methods: {
    getData() {
      commonModel({ type: this.modelType })
        .then(({ data }) => {
          let selectedModel = []
          this.optionData = this.modelOptions || data.result.rows.map(item => {
            if (this.multiple && this.defaultSelectAll && !this.disable) selectedModel.push(item)
            return this.getNode(item, item)
          })
          selectedModel.length && this.$emit('change', selectedModel)
          this.isArray && this.$emit('update:data', [...this.optionData])
          this.selectAll && !this.multiple && this.optionData.unshift(this.getNode(this.selectAllText, null))
        })
        .catch(() => {})
    },
    getModelSelect() {
      modelSelect(this.siteIds)
        .then(({ data }) => {
          this.optionData = this.modelOptions || data.result.rows.map((e) => {
            return this.getNode(e, e)
          })
          this.selectAll && !this.multiple && this.optionData.unshift(this.getNode(this.selectAllText, null))
        })
        .catch(() => {})
    },
  },
}
</script>
