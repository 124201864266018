import axios from '@/plugins/axios.js'

// 获取消息
export const getNoticeApi = (params) =>
  axios.get('/homepage/notice', {
    params,
    data: {
      noLoading: true,
    },
  })

// 清除消息
export const clearNoticeApi = () => axios.post('/homepage/clearNotice', { noLoading: true })

// 统计
export const homeTotal = (params) => axios.post('/homepage/total', params)

// 地图显示设备分布图
export const deviceDistribution = (params) => {
  // let newParams = { ...params }
  // newParams.site = newParams.site == '' ? [] : [newParams.site]
  return axios.post('/homepage/deviceDistribution', params)
}

// 邮箱设置查询
export const emailConfiQuery = () => axios.get('/emailConfig/query')

// 邮箱设置保存
export const emailConfiSave = (params) => axios.post('/emailConfig/save', params)

// 设置Http
export const setHttpCA = (params) => axios.post('/common/setHttpCA', params)