export default [
  {
    path: '/enterpriseManagement',
    name: 'enterpriseManagement',
    meta: {
      power: 'enterpriseManagement',
    },
    component: () =>
      import(/* webpackChunkName: "Enterprise" */ '@/views/Enterprise/Enterprise.vue'),
  },
  {
    path: '/enterpriseManagement/addEnterprise',
    name: 'addEnterprise',
    meta: {
      power: 'enterpriseManagement',
    },
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseDetails" */ '@/views/Enterprise/EnterpriseDetails.vue'
      ),
  },
  {
    path: '/enterpriseManagement/editEnterprise',
    name: 'editEnterprise',
    meta: {
      power: 'enterpriseManagement',
    },
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseDetails" */ '@/views/Enterprise/EnterpriseDetails.vue'
      ),
  },
  {
    path: '/enterpriseManagement/sendEmail',
    name: 'editEnterprise',
    meta: {
      power: 'enterpriseManagement',
    },
    component: () =>
      import(
        /* webpackChunkName: "SendEmail" */ '@/views/Enterprise/SendEmail.vue'
      ),
  }
]
