<style lang="scss" scoped>
.mac-tag {
  padding: 5px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 300px;
  .el-tag {
    margin-right: 10px;
  }
}
</style>
<template>
  <div class="mac-tag">
    <el-tag
      :key="tag"
      v-for="tag in newValue"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>
    <el-input
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="small"
      @keyup.enter.native="handleInputConfirm()"
      @blur="handleInputConfirm"
      style="width: 200px"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput"
      >+ New MAC Address</el-button
    >
  </div>
</template>

<script>
import { macFormat } from '@/plugins/methods'

export default {
  name: 'input-textarea-mac',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Array,
  },
  data() {
    return {
      oldValue: this.oldValue,
      inputVisible: false,
      inputValue: '',
    }
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        if (val != this.oldValue) this.$emit('change', val)
        this.oldValue = val
      },
    },
  },
  methods: {
    handleClose(tag) {
      this.newValue.splice(this.newValue.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let a = this.inputValue.split(/[;,'\s'，；]/)
      a = a.filter((item) => item !== '')
      for (let i = 0; i < a.length; i++) {
        let inputValue = macFormat(a[i])
        // console.log('inputValue', inputValue)
        if (inputValue) {
          if (inputValue && !this.newValue.includes(inputValue)) {
            this.newValue.push(inputValue)
          }
          // else {
          //   console.log('重复')
          // }
          this.inputVisible = false
          this.inputValue = ''
        } else {
          // console.log('格式错误')
          this.$message.error(this.$t('device.macAddressRule'))
        }
      }
    },
  },
}
</script>
