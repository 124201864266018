import axios from '@/plugins/axios.js'

// 用户:查询（分页）
export const userSearch = (params) => axios.post('/user/queryAllUser', params)

// 用户:新增
export const addUser = (params) => axios.post('/user/addUser', params)

// 用户:修改密码
export const updatePassword = (params) => axios.post('/user/updatePassword', params)

// 用户:删除
export const removeUser = (params) =>
  axios.post('/user/forbid', params, {
    params: {
      id: params.id,
    },
  })

// 用户:发邮件
export const userSend = (params) =>
  axios.post(`/user/sendEmail/${params.type}`, params, {
    params: {
      id: params.id,
    },
  })

// 角色：查询
export const selectRole = (params) => axios.get('/role/list', { params })

// 角色：查询（分页）
export const roleSearch = (params) => axios.post('/role/queryPage', params)

// 角色：查询
export const roleQuery = (params) => axios.get('/role/query', { params })

// 角色：新增
export const addRole = (params) => axios.post('/role/save', params)

// 角色：删除
export const removeRole = (data) => axios.delete('/role/remove', { data })

// 角色：查询权限
export const allResourcesQuery = (params) => axios.get('/role/allResources', { params })

// 查询所有企业
export const getAllEnterprise = (params) => axios.post('/allEnterprise', { params })

// 查询所有企业
export const getAllChannel = (params) => axios.post('/allChannel', { params })
