<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <!-- <el-input
        v-model="tabelSearch.keywords"
        size="small"
        prefix-icon="el-icon-search"
        clearable
        @change="getTabelData"
        style="margin-bottom: 10px;"
      ></el-input> -->
      <table-pagination
        ref="refTable"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        paginationHide
        @selection-change="selectChange"
      >
      </table-pagination>
    </el-col>
    <el-col :span="12">
      <!-- <div style="margin-bottom: 10px;">{{ $t('selected') }}{{ selectDeviceNum }}</div> -->
      <table-pagination
        :tableData="selectData"
        :columnData="columnData"
        paginationHide
        optionShow
      >
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
            <el-button type="text" class="btn-p0" @click="deleteItem(scope.row)">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </el-col>
  </el-row>
</template>

<script>
import { TablePagination } from '@/components'
import { getAllChannel } from '@/api/user'

export default {
  name: 'select-user',
  components: {
    TablePagination,
  },
  model: {
    prop: 'selectData',
    event: 'change',
  },
  props: {
    selectData: Array,
  },
  data() {
    return {
      // tabelSearch: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   sortField: 'createTime',
      //   sortOrder: 'asc',
      //   keywords: '',
      // },
      // tabelTotal: 0,
      columnData: [
        {
          label: this.$t('channelManagement.name'),
          prop: 'name',
          minWidth: 200,
        },
        {
          label: this.$t('channelManagement.email'),
          prop: 'email',
          minWidth: 200,
        },
      ],
      tableData: [],
    }
  },
  computed: {
    selectDeviceNum() {
      return this.selectData.length
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      getAllChannel(this.tabelSearch)
        .then(({data}) => {
          this.tableData = data.result.rows[0]
          this.$nextTick(() => {
            this.tableData.forEach((e) => {
              if (this.selectData.map((ee) => ee.id).includes(e.id)) {
                this.$refs.refTable.toggleRowSelection(e)
              }
            })
          })
        })
        .catch(() => {})
    },
    // 清除选择
    clearSelection() {
      this.$refs.refTable.clearSelection()
    },
    // 全选
    // selectAll(event) {
    //   console.log('全选', event)
    //   this.$emit('change', event)
    // },
    // 选择变化
    selectChange(event) {
      this.$emit('change', event)
    },
    // 选择
    // select(val, row) {
    //   if (this.selectData.map((e) => e.id).includes(row.id)) {
    //     console.log('单选T', this.selectData.filter((e) => e.id != row.id))
    //     this.$emit(
    //       'change',
    //       this.selectData.filter((e) => e.id != row.id)
    //     )
    //   } else {
    //     let arr = [...this.selectData]
    //     arr.push(row)
    //     console.log('单选F', arr)
    //     this.$emit('change', arr)
    //   }
    // },
    // 删除
    deleteItem(row) {
      this.$refs.refTable.toggleRowSelection(this.tableData.filter((e) => e.id == row.id)[0])
      this.$emit(
        'change',
        this.selectData.filter((e) => e.id != row.id)
      )
    },
  },
}
</script>
