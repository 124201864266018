import Vue from 'vue'
import store from '@/store'
import router from '@/router'

// 按钮权限
Vue.directive('has', {
  inserted: function(el, binding) {
    let option = { has: [], isOr: false }
    if (typeof binding.value == 'string') {
      option.has = [binding.value]
    } else if (Array.isArray(binding.value)) {
      option.has = binding.value
    } else if (binding.value.has) {
      option.has = binding.value.has
      option.isOr = binding.value.isOr
    } else {
      console.error('格式错误')
    }

    const permissionButtons = store.state.account.permissionButtons[router.currentRoute.meta.power]
    let removeEl = true
    if (Array.isArray(permissionButtons)) {
      option.has.forEach((item) => {
        if (option.isOr) {
          if (permissionButtons.includes(item)) removeEl = false
        } else {
          if (!permissionButtons.includes(item)) removeEl = false
        }
      })
    } else {
      removeEl = false
    }
    if (option.isOr ? removeEl : !removeEl) {
      el.parentNode.removeChild(el)
    }
  },
})
