import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
// Element
import zhCN from 'element-ui/lib/locale/lang/zh-CN'
import en from 'element-ui/lib/locale/lang/en'
const elementLang = { 'zh-CN': zhCN, en }
// setParam
import setParamZhCN from '@/components/set-param/i18n/zh-CN.json'
import setParamEn from '@/components/set-param/i18n/en.json'
const setParamLang = { 'zh-CN': setParamZhCN, en: setParamEn }

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = {
        ...locales(key),
        ...elementLang[matched[1]], // Element
        ...setParamLang[matched[1]], // setParam
      }
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
})

// Element语言设置
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
