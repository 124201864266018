<template>
  <el-select ref="siteSelect" v-model="label" :placeholder="placeholder" :size="size">
    <el-option value class="options">
      <el-tree
        ref="tree"
        :node-key="props.value"
        :data="data"
        :props="props"
        :default-expand-all="defaultExpandAll"
        :highlight-current="highlightCurrent"
        @node-click="handleNodeClick"
      >
        <span slot-scope="{ data }">
        {{ data[props.label] }} {{ props.subLabel ? data[props.subLabel] ? `(${data[props.subLabel]})` : '' : ''}}
        </span>
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'select-tree',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: [String, Number, Array],
    data: Array,
    props: {
      required: true,
      type: Object,
      default: () => {
        return {
          value: 'id',
          children: 'children',
          label: 'label',
        }
      }
    },
    placeholder: String,
    defaultExpandAll: Boolean,
    highlightCurrent: Boolean,
    size: String,
  },
  data() {
    return {}
  },
  computed: {
    label() {
      return this.getLabel(this.value)
    },
  },
  methods: {
    handleNodeClick(row) {
      if(row[this.props.value]) {
        this.$emit('change', row[this.props.value])
        this.$refs.siteSelect.blur()
      }
    },
    getLabel(id) {
      let label = null
      this.recursive(this.data, id, (row) => {
        label = row[this.props.label]
      })
      return label
    },
    recursive(array, id, callback) {
      array.forEach((e) => {
        if (e.id == id) callback(e)
        e[this.props.children] &&
          e[this.props.children].length &&
          this.recursive(e[this.props.children], id, callback)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li ::v-deep .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.el-tree ::v-deep .is-current .el-tree-node__label {
  color: $--color-primary;
  font-weight: 700;
}
.el-tree ::v-deep .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
.selectInput {
  padding: 0 5px;
  box-sizing: border-box;
}
</style>
