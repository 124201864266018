<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="850px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <p v-if="message && msgType == 'string'">{{ message }}</p>
    <p v-if="message && msgType == 'html'" v-html="message"></p>
    <table-pagination :tableData="tableData" :columnData="columnData" paginationHide>
    </table-pagination>
    <span slot="footer">
      <el-button @click="closeDialog">{{ $t('close') }}</el-button>
      <el-button type="primary" @click="exportItem">{{ $t('export') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { TablePagination } from '@/components'

export default {
  name: 'dialog-msg-table',
  components: {
    TablePagination,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    message: String,
    msgType: {
      type: String,
      default: 'string'
    },
    tableData: {
      type: Array,
      default() {
        return []
      },
    },
    columnData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    // 取消
    closeDialog() {
      this.$emit('update:visible', false)
    },
    // 导出
    exportItem() {
      this.$emit('exportItem', this.tableData)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table th, ::v-deep .el-table td {
  text-align: left;
}
</style>
