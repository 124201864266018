<template>
  <el-dialog :title="$t('settings.http')+'/'+$t('settings.https')" width="960px" :visible.sync="option.visible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="180px">
      <el-form-item>
        <el-radio-group v-model="form.httpProtocal" @change="httpProtocalChange">
          <el-radio label="http">{{$t('settings.http')}}</el-radio>
          <el-radio label="https">{{$t('settings.https')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="isHTTP" style="min-height: 336px;">
        <el-form-item :label="$t('settings.httpPort')" prop="httpPort">
          <el-input v-model="form.httpPort" :placeholder="$t('settings.httpPortPla')" />
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item :label="$t('settings.httpsPort')" prop="httpsPort">
          <el-input v-model="form.httpsPort" :placeholder="$t('settings.httpsPortPla')" />
        </el-form-item>
        <el-form-item :label="$t('settings.certificateFile')" prop="pemFile">
          <InputFile
            v-model="form.pemFile"
            accept="pem,cer,der,crt"
            :acceptTip="$t('components.upload.typeTipsMsg', ['.pem/.cer/.der/.crt'])"
          />
        </el-form-item>
        <el-form-item :label="$t('settings.privateKey')" prop="keyFile">
          <InputFile
            v-model="form.keyFile"
            accept="key"
            :acceptTip="$t('components.upload.typeTipsMsg', ['.key'])"
          />
        </el-form-item>
        <el-form-item :label="$t('settings.trustedCa')" prop="caFile">
          <InputFile
            v-model="form.caFile"
            accept="pem,cer,der,crt"
            :acceptTip="$t('components.upload.typeTipsMsg', ['.pem/.cer/.der/.crt'])"
          />
        </el-form-item>
      </div>
      <el-form-item class="footer-btns">
        <el-button class="footer-btn" type="primary" plain @click="cancel">{{ $t('cancel') }}</el-button>
        <el-button class="footer-btn" type="primary" @click="save">{{ $t('save') }}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { InputFile } from '@/components'
import { setHttpCA } from '@/api/home'

export default {
  name: 'settings',
  components: {
    InputFile
  },
  props: {
    option: {
      required: true,
      type: Object
    }
  },
  data() {
    const isHTTPNow = window.location.protocol == 'http:'
    const portNow = window.location.port
    return {
      form: {
        httpProtocal: 'http',
        httpPort: isHTTPNow && portNow ? portNow : '80',
        httpsPort: !isHTTPNow && portNow ? portNow : '443',
        pemFile: null,
        keyFile: null,
        caFile: null,
      },
      rules: {
        httpPort: {
          required: true,
          validator: (rule, value, callback) => {
            if (value == '') {
              callback(new Error(this.$t('settings.httpPortPla')))
            } else {
              value = Number.parseFloat(value)
              if (!Number.isInteger(value)) {
                callback(new Error(this.$t('settings.pleaseEnterANumericValue')))
              } else {
                callback()
              }
            }
          },
          trigger: 'blur',
        },
        httpsPort: {
          required: true,
          validator: (rule, value, callback) => {
            if (value == '') {
              callback(new Error(this.$t('settings.httpsPortPla')))
            } else {
              value = Number.parseFloat(value)
              if (!Number.isInteger(value)) {
                callback(new Error(this.$t('settings.pleaseEnterANumericValue')))
              } else {
                callback()
              }
            }
          },
          trigger: 'blur',
        },
        pemFile: {
          required: true,
          message: this.$t('settings.pemFilePla'),
          trigger: 'change',
        },
        keyFile: {
          required: true,
          message: this.$t('settings.keyFilePla'),
          trigger: 'change',
        },
        caFile: {
          required: true,
          message: this.$t('settings.caFilePla'),
          trigger: 'change',
        },
      }
    }
  },
  computed: {
    isHTTP() {
      return this.form.httpProtocal == 'http'
    }
  },
  methods: {
    cancel() {
      this.option.visible = false
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let formData = new FormData()
          formData.append('httpProtocal', this.form.httpProtocal)
          formData.append('port', Number(this.isHTTP ? this.form.httpPort : this.form.httpsPort))
          !this.isHTTP && formData.append('pemFile', this.form.pemFile)
          !this.isHTTP && formData.append('keyFile', this.form.keyFile)
          !this.isHTTP && formData.append('caFile', this.form.caFile)
          // console.log(formData);
          setHttpCA(formData).then(({ data }) => {
            this.$message.success(data.message)
            this.cancel()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    httpProtocalChange() {
      this.$refs['form'].clearValidate()
    }
  }
}
</script>