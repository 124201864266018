<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-input
        v-model="tabelSearch.keywords"
        size="small"
        prefix-icon="el-icon-search"
        clearable
        @change="getTabelData"
        :placeholder="placeholder"
        style="margin-bottom: 10px;"
      ></el-input>
      <table-pagination
        ref="refTable"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @select="select"
        @select-all="selectAll"
        @changeCurrentPage="getTabelData"
      >
      </table-pagination>
    </el-col>
    <el-col :span="12">
      <div style="margin-bottom: 10px;">{{ $t('selected') }}{{ selectDeviceNum }}</div>
      <table-pagination
        :tableData="selectData"
        :columnData="columnData"
        paginationHide
        optionShow
      >
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
            <el-button type="text" class="btn-p0" @click="deleteItem(scope.row)">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </el-col>
  </el-row>
</template>

<script>
import { TablePagination } from '@/components'
import { userSearch } from '@/api/user'

export default {
  name: 'select-user',
  components: {
    TablePagination,
  },
  model: {
    prop: 'selectData',
    event: 'change',
  },
  props: {
    selectData: Array,
    placeholder: {
      type: String,
      default() {
        return this.$t('name-email')
      },
    },
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'asc',
        keywords: '',
      },
      tabelTotal: 0,
      columnData: [
        {
          label: this.$t('user.userName'),
          prop: 'userName',
          minWidth: 200,
        },
        {
          label: this.$t('user.email'),
          prop: 'email',
          minWidth: 200,
        },
      ],
      tableData: [],
    }
  },
  computed: {
    selectDeviceNum() {
      return this.selectData.length
    },
  },
  created() {
    this.getTabelData()
  },
  methods: {
    // 获取数据
    getTabelData() {
      userSearch(this.tabelSearch)
        .then((res) => {
          this.tableData = res.data.result.rows[0].list // 表格数据
          this.tabelTotal = res.data.result.rows[0].total // 表格数据条目
          this.$nextTick(() => {
            this.tableData.forEach((e) => {
              if (this.selectData.map((ee) => ee.id).includes(e.id)) {
                this.$refs.refTable.toggleRowSelection(e)
              }
            })
          })
        })
        .catch(() => {})
    },
    // 清除选择
    clearSelection() {
      this.$refs.refTable.clearSelection()
    },
    // 全选择
    selectAll(val) {
      console.log('全选', this.selectData)
      const deviceIds = this.selectData.map((e) => e.id),
        selectIds = val.map(row => row.id)
      let arr = [...this.selectData]
      let isSelect = !selectIds.every(row => deviceIds.includes(row))
      if (isSelect) {  // 选择
        val.forEach((e) => {
          !deviceIds.includes(e.id) && arr.push(e)
        })
      } else {  // 取消选择
        this.tableData
          .map(row => {
            if (!selectIds.includes(row.id)) return row
          }) 
          .filter(item => item)
          .forEach(deleteItem => {
            if (deleteItem) {
              let index = arr.findIndex(item => item.id === deleteItem.id)
              index !== -1 && arr.splice(index, 1)
            }
          })
      }
      this.$emit('change', arr)
    },
    // 选择
    select(val, row) {
      this.$emit('change', this.selectData.map(row => row.id).includes(row.id)
        ? this.selectData.filter(item => item.id != row.id)
        : [...this.selectData, row]
      )
    },
    // 删除
    deleteItem(row) {
      this.$refs.refTable.toggleRowSelection(this.tableData.filter((e) => e.id == row.id)[0])
      this.$emit(
        'change',
        this.selectData.filter((e) => e.id != row.id)
      )
    },
  },
}
</script>
