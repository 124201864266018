<template>
  <div>
    <transfer-device
      ref="transferDevice"
      v-show="deviceType == 1"
      v-model="newDevices"
      :columnData="columnData"
      :modelOptions="modelOptions"
      :toolsDisplay="toolsDisplay"
      :autoGet="autoGet"
    ></transfer-device>
  </div>
</template>

<script>
import TransferDevice from './transfer-device'
import InputTextareaMac from './input-textarea-mac'

export default {
  name: 'select-device',
  components: {
    TransferDevice,
  },
  props: {
    macs: Array,
    devices: Array,
    deviceType: Number,
    columnData: Array,
    modelOptions: Array,
    toolsDisplay: Object,
    autoGet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oldMacs: this.macs,
      oldDevices: this.devices,
    }
  },
  computed: {
    newMacs: {
      get() {
        return this.macs
      },
      set(val) {
        if (val != this.oldMacs) this.$emit('update:macs', val)
        this.oldMacs = val
      },
    },
    newDevices: {
      get() {
        return this.devices
      },
      set(val) {
        if (val != this.oldDevices) this.$emit('update:devices', val)
        this.oldDevices = val
      },
    },
  },
  methods: {
    tabelSearchChange(option) {
      this.$refs.transferDevice.tabelSearchChange(option)
    },
    getTabelData(type) {
      this.$refs.transferDevice.getTabelData(type)
    }
  },
}
</script>
