import axios from '@/plugins/axios.js'

// selectSite
export const selectSite = (params) => axios.post('/tb-site/selectSite', params)

// 查询
export const siteSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/tb-site-template/searchSiteTemplate', newParams)
}

// 查询
export const siteQuery = (params) =>
  axios.get('/tb-site-template/searchSiteTemplateById', { params })

// 按站点配置-下拉数据
export const siteTempSelect = (params) => axios.get('/tb-site-template/siteTempSelect', { params })

// 选择站点(新建站点模板)
export const siteTempSiteSelect = (params) => axios.get('/tb-site-template/siteSelect', { params })

// 新增
export const siteAdd = (params) => axios.post('/tb-site-template/addSiteTemplate', params)

// 更新
export const siteUpdate = (params) => axios.post('/tb-site-template/updateSiteTemplate', params)

// 批量删除
export const removeSiteBatch = (data) =>
  axios.delete('/tb-site-template/deleteSiteTemplate', { data })

// 删除
export const removeSite = (data) =>
  axios.delete('/tb-site-template/deleteOneSiteTemplate', { data })

// 下载
export const downloadSite = (params) =>
  axios.get(`/cfg/type/site/${params.relationId}/${params.modeltype}/${params.templateName}`, {
    responseType: 'blob',
  })
