<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getVersionType } from './api/app.js'

export default {
  name: 'app',
  beforeCreate() {
    this.$i18n.locale = this.$store.state.memory.language
    getVersionType()
      .then(({ data }) => {
        let isDevelopment = process.env.NODE_ENV === 'development',  // 运行环境
          developmentIsLan = 'localhost' === location.hostname,      // 研发服务
          developmentServer = developmentIsLan ? '局端' : '云端',     // 研发服务文字
          productIsLan = data.result.rows[0] === 'personal',         // 生产服务（personal-局端 enterprise-云端）
          productionServer = productIsLan ? '局端' : '云端',          // 生产服务文字
          isLan = isDevelopment ? developmentIsLan : productIsLan    // 是否为局端
        this.$store.commit('session/SET_IS_LAN', isLan)
        console.log(
          `%c ${isDevelopment ? '研发' : '生产'} | ${isDevelopment ? developmentServer : productionServer} %c ${process.env.VUE_APP_VERSION} `,
          'color: white; background: #383038; padding: 1px 0; border-radius: 4px 0 0 4px',
          'color: white; background: #6B489D; padding: 1px 0; border-radius: 0 4px 4px 0'
        )
      })
  },
  mounted() {
    this.$store.commit('session/SET_APP_WIDTH', document.body.clientWidth)
    this.$store.commit('session/SET_APP_HEIGHT', document.body.clientHeight)
    window.onresize = () => {
      return (() => {
        this.$store.commit('session/SET_APP_WIDTH', document.body.clientWidth)
        this.$store.commit('session/SET_APP_HEIGHT', document.body.clientHeight)
      })()
    }
  },
}
</script>
