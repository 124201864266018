import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import session from './modules/session'
import account from './modules/account'
import memory from './modules/memory'
import dist from './modules/dist'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          account: val.account,
          memory: val.memory,
        }
      },
    }),
  ],
  modules: {
    session,
    account,
    memory,
    dist,
  },
})
