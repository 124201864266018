<template>
  <el-form-item
    :prop="prop"
    :rules="{ validator: validatePass, trigger: ['blur', 'change'] }"
  >
    <span slot="label">
      <el-checkbox
        class="checkbox"
        v-model="item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></el-checkbox>
      {{ $t(`sitParam.${getParamName()}`, [index ? index : '']) }}
    </span>
    <span>
      <!-- type text -->
      <el-input
        v-if="item.Type == 'text'"
        v-model="item.Value"
        :disabled="!item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></el-input>
      <!-- type password -->
      <el-input
        v-else-if="item.Type == 'password'"
        v-model="item.Value"
        :disabled="!item.Push"
        show-password
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></el-input>
      <!-- type boolean  -->
      <span v-else-if="item.Type == 'boolean'">
        <!-- true/false -->
        <el-switch
          v-if="isNaN(+item.Value)"
          v-model="item.Value"
          :disabled="!item.Push"
          active-value="true"
          inactive-value="false"
          @change="checkboxChange({ ...item, xmlLabel: label })"
        ></el-switch>
        <!-- 0/1 -->
        <el-switch
          v-else
          v-model="item.Value"
          :disabled="!item.Push"
          active-value="1"
          inactive-value="0"
          @change="checkboxChange({ ...item, xmlLabel: label })"
        ></el-switch>
      </span>
      <!-- type number -->
      <SpNumber
        v-model="item.Value"
        :item="item"
        :disabled="!item.Push"
        v-else-if="item.Type == 'number'"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></SpNumber>
      <!-- type select -->
      <el-select
        v-else-if="item.Type == 'select'"
        v-model="item.Value"
        :placeholder="$t('pleaseSelect')"
        :disabled="!item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      >
        <el-option
          v-for="item2 in item.FilteredValueRule ? item.FilteredValueRule.split(',') : item.ValueRule.split(',')"
          :key="item2"
          :label="shorten($t(`sitParam.${getParamName()}Values.${item2}`), 80, '...')"
          :value="item.ValueTemplate
            ? item.ValueTemplate.replace('$', item2)
            : item2"
        ></el-option>
      </el-select>
      <!-- type transfer -->
      <SpTransfer
        class="el-input"
        v-else-if="item.Type == 'transfer'"
        v-model="item.Value"
        :item="item"
        :disabled="!item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></SpTransfer>
      <!-- type check -->
      <SpCheck
        class="el-input"
        v-else-if="item.Type == 'check'"
        v-model="item.Value"
        :item="item"
        :label="label"
        :disabled="!item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></SpCheck>
      <!-- type dataTime -->
      <el-time-picker
        v-else-if="item.Type == 'time'"
        v-model="item.Value"
        value-format="H:mm"
        format="H:mm"
        :clearable="false"
        :disabled="!item.Push"
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></el-time-picker>
      <!-- type default -->
      <el-input
        v-model="item.Value"
        :disabled="!item.Push"
        v-else
        @change="checkboxChange({ ...item, xmlLabel: label })"
      ></el-input>
    </span>
    <el-tooltip
      effect="dark"
      :content="$t(`sitParam.${getParamName()}Des`, [index ? index : ''])"
      placement="top"
      class="ml-s"
    >
      <i class="el-icon-question"></i>
    </el-tooltip>
  </el-form-item>
</template>

<script>
import SpNumber from './sp-number'
import SpTransfer from './sp-transfer'
import SpCheck from './sp-check'

export default {
  name: 'sp-param',
  components: {
    SpNumber,
    SpTransfer,
    SpCheck,
  },
  props: {
    item: Object,
    label: String,
    prop: String,
    tabActive: String,
    index: Number,
  },
  model: {
    prop: 'item',
    event: 'changeItem'
  },
  data() {
    return {
      checkedData: {},
    }
  },
  mounted() {
    this.checkDependency(true)
  },
  methods: {
    checkboxChange(event) {
      event.label = this.getParamName()
      console.log("checkboxChange:", this.item.label)
      this.checkDependency(false)
    },
    checkDependency(init) {
      if (this.item.Dependency) {
        this.$emit('dependencychanged', this.item.label, this.item.Value, this.item.Dependency, init)
      }
    },
    // 校验表单
    validatePass(rule, value, callback) {
      // type ipv4
      if (this.item.Type == 'ipv4' && value) {
        var ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        ipv4Regex.test(value)
          ? callback()
          : callback(new Error(this.$t('sitParam2.ipv4Validate')))
      } else if (this.item.Type == 'transfer' && !value) {
        callback(new Error(this.$t('sitParam2.transferValidate')))
      } else {
        callback()
      }
    },
    getParamName() {
      return this.item.label ? this.item.label : this.label
    },
    getParamValue() {
      return this.item.Value
    },
    shorten(s, length, suffix) {
      if(s.length<=length){
        return s;
      }else{
        return s.substring(0, length-1)+suffix;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-input-number {
  // width: calc(100% - 30px);
  width: 100%;
  max-width: 500px;
}
::v-deep .el-checkbox__inner {
  border-color: #000000;
}
</style>
