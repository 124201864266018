import axios from '@/plugins/axios.js'

// 查询
export const modelSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/modeltemplate/search', newParams)
}

// 查询
export const modelQuery = (params) => axios.get('/modeltemplate/query', { params })

// 获取supportModel
export const supportModelSelect = (params) => axios.get('/modeltemplate/supportModelSelect', { params })

// 查询话机型号
export const modelSelect = (params) => axios.post('/modeltemplate/modelSelect', params)

// 按型号配置-下拉数据
export const modelTempSelect = (params) => axios.get('/modeltemplate/modelTempSelect', { params })

// 按型号配置-关联站点
export const modelTempSite = (params) => axios.get('/modeltemplate/modelTempSite', { params })

// 新增
export const modelAdd = (params) => axios.post('/modeltemplate/add', params)

// 编辑
export const modelUpdate = (params) => axios.post('/modeltemplate/update', params)

// 批量删除
export const removeModelBatch = (data) => axios.delete('/modeltemplate/removeBatch', { data })

// 批量
export const removeModel = (data) => axios.delete('/modeltemplate/remove', { data })

// 下载
export const downloadModel = (params) =>
  axios.get(`/cfg/type/model/${params.relationId}/${params.modelType}/${params.templateName}`, {
    responseType: 'blob',
  })

// 新增
export const createModel = (params) => axios.post('/model/create', params)

// 新增
export const updateModel = (params) => axios.post('/model/update', params)
