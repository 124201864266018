<template>
  <div class="aside">
    <slot></slot>
    <div class="menu" ref="aside">
      <div v-for="(item, i) in data" :key="i" @click="clickItem(item)">
        {{ $t(`sitParam.${item}`) }}
        <span v-show="checkList[item]">{{ checkList[item] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar',
  props: {
    value: String,
    data: Array,
    checkList: Object,
  },
  data() {
    return {}
  },
  computed: {
    newActive: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val, this.value)
      },
    },
  },
  mounted() {
    this.$refs.aside.children[this.data.indexOf(this.value)].className = 'active'
  },
  methods: {
    clickItem(key, type) {
      if ((type || this.newActive != key)) {
        for (let i = 0; i < this.$refs.aside.children.length; i++) {
          const element = this.$refs.aside.children[i];
          element.removeAttribute('class')
        }
        this.$refs.aside.children[this.data.indexOf(key)].className = 'active'
        this.newActive = key
      }
    },
  },
}
</script>

<style></style>
