export default [
  /* alarmList */
  {
    path: '/alarmList',
    name: 'alarmList',
    meta: {
      power: 'alarmList',
    },
    component: () =>
      import(/* webpackChunkName: "AlarmList" */ '@/views/Alarm/AlarmList/AlarmList.vue'),
  },
  /* alarmStrategy */
  {
    path: '/alarmStrategy',
    name: 'alarmStrategy',
    meta: {
      power: 'alarmStrategy',
    },
    component: () =>
      import(
        /* webpackChunkName: "AlarmStrategy" */ '@/views/Alarm/AlarmStrategy/AlarmStrategy.vue'
      ),
  },
  {
    path: '/alarmStrategy/addStrategy',
    name: 'addStrategy',
    meta: {
      power: 'alarmStrategy',
    },
    component: () =>
      import(
        /* webpackChunkName: "AlarmStrategyDetails" */ '@/views/Alarm/AlarmStrategy/AlarmStrategyDetails/AlarmStrategyDetails.vue'
      ),
  },
  {
    path: '/alarmStrategy/editStrategy',
    name: 'editStrategy',
    meta: {
      power: 'alarmStrategy',
    },
    component: () =>
      import(
        /* webpackChunkName: "AlarmStrategyDetails" */ '@/views/Alarm/AlarmStrategy/AlarmStrategyDetails/AlarmStrategyDetails.vue'
      ),
  },
]
