import Layout from '@/views/Layout/Layout.vue'

import account from './account'
import device from './device'
import configuration from './configuration'
import alarm from './alarm'
import task from './task'
import resources from './resources'
import system from './system'
import serParam from './serParam'
import diagnostics from './diagnostics'
import channel from './channel'
import enterprise from './enterprise'
import deviceFilter from './deviceFilter'
import adminFirmware from './adminFirmware'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login/Login.vue'),
  },
  {
    path: '/modifyEmail',
    name: 'modifyEmail',
    component: () => import(/* webpackChunkName: "ModifyEmail" */ '@/views/Login/ModifyEmail.vue'),
  },
  {
    path: '/modifyPassword',
    name: 'modifyPassword',
    component: () =>
      import(/* webpackChunkName: "ModifyPassword" */ '@/views/Login/ModifyPassword.vue'),
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      },
      {
        path: '/personnalInfo',
        name: 'personnalInfo',
        component: () =>
          import(/* webpackChunkName: "PersonnalInfo" */ '@/views/Login/PersonnalInfo.vue'),
      },
      /* Home */
      {
        path: '/',
        name: 'home',
        meta: {
          power: 'home',
        },
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/Home.vue'),
      },
      /* Site Management */
      {
        path: '/ssite',
        name: 'ssite',
        meta: {
          power: 'ssite',
        },
        component: () => import(/* webpackChunkName: "Ssite" */ '@/views/Site/Site.vue'),
      },

      /* Call Statistics */
      {
        path: '/callStatistics',
        name: 'callStatistics',
        meta: {
          power: 'callStatistics',
        },
        component: () =>
          import(
            /* webpackChunkName: "CallStatistics" */ '@/views/CallStatistics/CallStatistics.vue'
          ),
      },
      ...account, // Account Management
      ...device, // Device Management
      ...configuration, // Device Configuration
      ...alarm, // Alarm Management
      ...task, // Task Management
      ...diagnostics, // Device Diagnostics
      ...resources, // Resources Management
      ...enterprise, // Enterprise Management
      ...channel, // Channel Management
      ...system, // System Management
      ...serParam, // setParam
      ...deviceFilter,
      ...adminFirmware,
    ],
  },
]

export default routes
