<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="960px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
    
      <div v-loading="loading">
        <el-upload
          class="upload"
          drag
          ref="upload"
          action
          :on-change="handleChange"
          :before-upload="beforeUpload"
          :http-request="httpRequestSend"
          :file-list="fileList"
          :auto-upload="false"
        >
          <svg class="icon-upload-cloud" aria-hidden="true">
            <use xlink:href="#icon-upload-cloud"></use>
          </svg>
          <!-- <i class="el-icon-upload iconfont icon-upload-cloud"></i> -->
          <div class="el-upload__text">
            {{ $t('components.upload.tip1') }}
            <em>{{ $t('components.upload.tip2') }}</em>
          </div>
          <div
            class="el-upload__tip"
            slot="tip"
            v-html="
              fileTipText
                ? fileTipText
                : $t('components.upload.tip3', [fileFormat.join(' or '), fileSize + fileSizeUnit])
            "
          ></div>
        </el-upload>
        <div v-if="generalTemplateBtnShow" class="ta-c">
          <slot name="generalTemplate"></slot>
          <el-button style="font-size: 14px"
            icon="el-icon-download iconfont icon-download"
            size="small"
            type="primary"
            @click="generalTemplateclick"
          >{{ generalTemplateBtnText }}</el-button>
        </div>
        <slot></slot>
      </div>
      <span slot="footer">
        <el-button type="primary" plain :disabled = "this.waiting" @click="handleClose">{{ $t('cancel') }}</el-button>
        <el-button type="primary" :disabled = "this.waiting"  @click="submit">{{ $t('import') }}</el-button>
      </span>
    </el-dialog>
    <dialog-msg-table
      v-if="msgTableShow"
      msgType="html"
      :visible.sync="msgTableVisible"
      :message="msgTableMessage"
      :tableData="msgTableTableData"
      :title="msgTable.title"
      :columnData="msgTable.columnData"
      @exportItem="msgTableExport"
    >
    </dialog-msg-table>
  </div>
</template>

<script>
import axios from '@/plugins/axios.js'
import DialogMsgTable from './dialog-msg-table'
import { fileCheck } from '@/plugins/methods'

export default {
  name: 'dialog-import-item',
  components: { DialogMsgTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Import',
    },
    url: String,
    fileFormat: {
      type: Array,
      default() {
        return ['.xlsx', '.xls']
      },
    },
    fileSize: {
      type: Number,
      default: 3,
    },
    fileSizeUnit: {
      type: String,
      default: 'M',
    },
    fileTipText: String,
    generalTemplateBtnShow: {
      type: Boolean,
      default: false,
    },
    generalTemplateBtnText: {
      type: String,
      default() {
        return window.vm.$t('components.upload.generalTemplate')
      },
    },
    // msgTable
    msgTableShow: Boolean,
    msgTable: Object,
    // 上传文件类型错误
    typeErrMessage: {
      type: String,
      default() {
        return window.vm.$t('components.upload.typeTipsMsg', [this.fileFormat.join('/')])
      },
    },
    // 上传文件大小错误
    sizeErrMessage: {
      type: String,
      default() {
        return window.vm.$t('components.upload.sizeTipsMsg', [this.fileSize + this.fileSizeUnit])
      },
    },
  },
  data() {
    return {
      loading: false,
      fileList: [],
      // msgTable
      msgTableVisible: false,
      msgTableMessage: '',
      msgTableTableData: [],
      waiting: false,
      timerId: null
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.fileList = []
      clearTimeout(this.timerId)
      this.waiting = false
      this.loading = false
      this.timerId = null
      this.$emit('close', false)
    },
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]
      }
    },
    // 确定
    submit() {
      this.$refs.upload.submit()
    },
    beforeUpload(file) {
      return fileCheck({
        file,
        fileTypes: this.fileFormat,
        fileSize: this.fileSize * 1024 * 1024,
        callback: (typeErr, sizeErr) => {
          typeErr && this.$message.error(this.typeErrMessage)
          !typeErr && sizeErr && this.$message.error(this.sizeErrMessage)
        },
      })
    },
    formatMsg(msg) {
      let msgList = msg.split('，'),
        filed_num = msgList[0].split(' Data')[0],
        filed_text = msgList[0].substring(filed_num.length),
        success_num = msgList[1].split(' Data')[0],
        success_text = msgList[1].substring(success_num.length)
      return `
        <span style="color: #F44336">${filed_num}</span>
        <span>${filed_text}，</span>
        <span style="color: #3AB477">${success_num}</span>
        <span>${success_text}</span>
      `
    },
    httpRequest(param) {
      let formData = new FormData()
      formData.append('file', param.file)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      
      axios
        .post(this.url, formData, config)
        .then(({ data }) => {
          if (this.msgTableShow) {
            const msg = data.message
            const msgList = data.result.rows
            if (msgList.length) {
              this.msgTableMessage = this.formatMsg(msg)
              this.msgTableTableData = msgList
              this.msgTableVisible = true
            } else {
              this.$message.success(msg)
            }
          } else {
            this.$message.success(data.message)
          }
          this.handleClose()
        })
        .catch(({ data }) => {
          clearTimeout(this.timerId)
          this.waiting = false
          this.loading = false
          this.timerId = null
          let idx = data.messageExt.search('Duplicate entry') 
          if (this.$route.path.search("device") && idx >= 0) {
                  this.$message.error(this.$t('device.duplicateDevice'))
              }
        })
    },
    httpRequestSend(param) { 
      if (!this.waiting) { //already clicked
        this.waiting = true
        this.loading = true
        this.httpRequest(param)
        this.timerId = setTimeout(() => {
          this.waiting = false
          this.loading = false
        }, 60000);
      }
    },
    generalTemplateclick() {
      this.$emit('generalTemplateclick')
    },
    msgTableExport(val) {
      this.$emit('msgTableExport', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-upload-cloud {
  width: 172px;
  height: 172px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  padding-top: 55px;
  text-align: center;
  .el-dialog__title {
    font-size: 30px;
  }
}
::v-deep .el-dialog__footer {
  padding: 0 88px 80px;
  .el-button {
    width: 162px;
  }
}
::v-deep .el-dialog__body {
  padding: 55px 88px 33px;
}
::v-deep .el-upload {
  width: 100%;
  height: 330px;
  .el-upload-dragger {
    width: 100%;
    height: 100%;
    padding-top: 58px;
    border-color: $--color-primary;
    .el-icon-upload {
      font-size: 172px;
    }
  }
}
.ta-c {
  margin-top: 30px;
}
.el-upload__tip {
  color: #ccc;
}
.upload >>> .el-list-enter-active,
.upload >>> .el-list-leave-active {
  transition: none;
}
.upload >>> .el-list-enter,
.upload >>> .el-list-leave-active {
  opacity: 0;
}
.upload >>> .el-upload-list {
  height: 40px;
}
</style>
