export default [
  /* sipAccount */
  {
    path: '/sipAccount',
    name: 'sipAccount',
    meta: {
      power: 'sipAccount',
    },
    component: () =>
      import(/* webpackChunkName: "SipAccount" */ '@/views/Account/SipAccount/SipAccount.vue'),
  },
  {
    path: '/sipAccount/addAccount',
    name: 'addAccount',
    meta: {
      power: 'sipAccount',
    },
    component: () =>
      import(
        /* webpackChunkName: "AccountDetails" */ '@/views/Account/SipAccount/AccountDetails.vue'
      ),
  },
  {
    path: '/sipAccount/editAccount',
    name: 'editAccount',
    meta: {
      power: 'sipAccount',
    },
    component: () =>
      import(
        /* webpackChunkName: "AccountDetails" */ '@/views/Account/SipAccount/AccountDetails.vue'
      ),
  },
  /* sipServer */
  {
    path: '/sipServer',
    power: 'sipServer',
    meta: {
      power: 'sipServer',
    },
    component: () =>
      import(/* webpackChunkName: "SipServer" */ '@/views/Account/SipServer/SipServer.vue'),
  },
  {
    path: '/sipServer/addServer',
    name: 'addServer',
    meta: {
      power: 'sipServer',
    },
    component: () =>
      import(/* webpackChunkName: "ServerDetails" */ '@/views/Account/SipServer/ServerDetails.vue'),
  },
  {
    path: '/sipServer/editServer',
    name: 'editServer',
    meta: {
      power: 'sipServer',
    },
    component: () =>
      import(/* webpackChunkName: "ServerDetails" */ '@/views/Account/SipServer/ServerDetails.vue'),
  },
]
