<template>
  <div class="param-tab">
    <!-- sidebar -->
    <SpSidebar
      ref="sidebar"
      :value="tabActive2"
      :data="sidebarData"
      :checkList="checkList"
      @change="changeSidebar"
    >
      <el-select
        v-if="tabName == 'account'"
        class="select-account"
        v-model="selectAccount"
        placeholder="Please Select"
        @change="changeAccount"
      >
        <el-option
          v-for="(item, i) in sidebarAccountList"
          :key="i"
          :label="$t('sitParam.account') + item.split('account')[1]"
          :value="item"
        ></el-option>
      </el-select>
    </SpSidebar>
    <!-- 账号设置 -->
    <SpMain
      ref="main"
      v-if="tabName == 'account'"
      :tabActive="tabName"
      v-model="tabData[selectAccount][tabActive2]"
      @cancel="cancel"
      @submit="submit"
      @onlysave="onlysave"
    ></SpMain>
    <!-- 话机按键设置 -->
    <SpMainPhoneKeys
      ref="main"
      v-else-if="tabName == 'phoneKeys'"
      v-model="tabData[tabActive2]"
      @cancel="cancel"
      @submit="submit"
      @onlysave="onlysave"
    ></SpMainPhoneKeys>
    <!-- 通用设置 -->
    <SpMain
      ref="main"
      v-else
      :tabActive="tabName"
      v-model="tabData[tabActive2]"
      @cancel="cancel"
      @submit="submit"
      @onlysave="onlysave"
    ></SpMain>
  </div>
</template>

<script>
import SpSidebar from './sp-sidebar'
import SpMain from './sp-main'
import SpMainPhoneKeys from './sp-main-phone-keys'
import methods from '../plugins/methods'

export default {
  name: 'sp-tab',
  components: {
    SpSidebar,
    SpMain,
    SpMainPhoneKeys,
  },
  props: {
    tabData: Object,  // 当前分页数据
    tabName: String,  // 当前tab名称
    relationId: String,
    relationName: String,
  },
  model: {
    prop: 'tabData',
    event: 'changeTabData',
  },
  data() {
    // 侧边栏数据
    let sidebarData = this.tabName == 'account'
      ? Object.keys(this.tabData.account1)
      : Object.keys(this.tabData)
    // 非mac的account删除basic板块
    if (this.relationName != 'mac' && this.tabName == 'account')
      sidebarData.splice(sidebarData.indexOf('basic'), 1)
    // 侧边栏Account数据
    const sidebarAccountList = this.tabName == 'account'
      ? Object.keys(this.tabData)
      : []
    return {
      sidebarData,
      sidebarAccountList,
      tabActive2: sidebarData[0],
      selectAccount: sidebarAccountList[0],
    }
  },
  computed: {
    checkList() {
      let checkList = {},
        checkNumber = 0
      Object.entries(this.selectAccount ? this.tabData[this.selectAccount] : this.tabData)
        .forEach((e) => {
          !checkList[e[0]] && (checkList[e[0]] = 0)
          methods.parseData(e[1], (ee) => {
            ee.Push && ++checkList[e[0]]
          })
        })
      if (['account', 'phoneKeys'].includes(this.tabName)) {
        for (let subTab in this.tabData) {
          methods.parseData(this.tabData[subTab], (cell) => {
            cell.Push && checkNumber++
          })
        }
      } else {
        checkNumber = eval(Object.values(checkList).join('+'))
      }
      this.$emit('changeTotal', checkNumber, this.tabName)
      return checkList
    },
  },
  methods: {
    // 切换sidebar
    changeSidebar(val, oldVal) {
      const type = this.$refs.main.getValidate()
      if (type) {
        if (val != oldVal) {
          this.$message.error(
            typeof type == 'string'
              ? type
              : this.$t('sitParam2.paramValidateFailed')
          )
          this.$refs.sidebar.clickItem(oldVal, true)
        }
      } else {
        this.tabActive2 = val
      }
    },
    // 切换用户
    changeAccount(val) {
      this.selectAccount = val
    },
    // 保存
    onlysave() {
      this.$emit('onlysave')
    },
    // 提交
    submit() {
      this.$emit('submit')
    },
    // 取消
    cancel() {
      this.$emit('cancel')
      if (
        this.$route.query.templateName &&
        this.$route.query.site &&
        this.$route.query.modelName
      ) {
        this.$router.push('/model')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
