'use strict'

import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import loading from '@/plugins/loading'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}
const _axios = axios.create(config)
const _loading = loading.create() // loading

_axios.interceptors.request.use(
  function(config) {
    // loading
    config.headers[_loading.identifier] = _loading.show(config)
    // language
    const language = store.state.memory.language
    config.headers['Accept-Language'] = language == 'en' ? 'en-US' : language
    config.headers['time-zone'] = getTimeZone()
    // return
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function(response) {
    // loading
    _loading.hide(response)
    if (response.config.responseType == 'blob') {
      // blob
      return response
    } else if (response.data.code == 0) {
      // 正常
      return response
    } else {
      // 错误
      const LogoutCode = [80000]
      const backupCode = [100000, 110000, 120000]
      if (LogoutCode.includes(response.data.code)) {
        // 登出错误code
        _loading.getState() && Message.error(response.data.message)
        store.dispatch('account/LoginOut')
      } else if(backupCode.includes(response.data.code)) {
        // 备份恢复状态code
        store.commit('session/SET_DIALOG_BACKUP_STATUS_INFO', response.data)
      } else if (response.data.code == 50000 && response.data.messageExt != null
        && response.data.messageExt.search('Duplicate entry') >= 0) {
        // 普通错误code
        Message.error(response.data.message)
      } else {
        Message.error(response.data.message)
      }
      return Promise.reject(response)
    }
  },
  function(error) {
    // loading
    _loading.hide(error)
    if (error.response && error.response.data && error.response.data.message) {
      Message.error(error.response.data.message)
    } else if (error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
      Message.error('Network timeout')
    } else {
      Message.error('Network exception')
    }
    return Promise.reject(error)
  }
)

function getTimeZone() {
  var date = new Date()
  var dateStr = date.toString();
  var startIndex = dateStr.indexOf("GMT")
  var timeZone = dateStr.substring(startIndex, startIndex+6) + ":" + dateStr.substring(startIndex+6, startIndex+8);
  return timeZone
}

export default _axios
