<template>
  <div class="main">
    <div class="main-header">
      <el-button class="dialog-btn"
        :type="selectAllType ? null : 'primary'"
        size="small"
        @click="selectAll"
      >{{ selectAllType ? $t('unselectAll') : $t('selectAll') }}</el-button>
      <el-button
        class="dialog-btn"
        size="small"
        type="primary"
        plain
        @click="resetSettings"
      >{{ $t('resetSetting') }}</el-button>
    </div>
    <div class="main-body">
      <el-form class="h100" :model="mainData" ref="dataRef" label-width="320px">
        <template v-for="(mainItem, key) in mainData">
          <!-- 分组 -->
          <template v-if="Array.isArray(mainItem)">
            <div
              v-for="(group, groupIndex) in mainItem"
              :key="groupIndex"
            >
              <div class="card-title">{{ $t('sitParam.' + key, [groupIndex + 1]) }}</div>
              <SpInput
                v-for="(groupItem, itemIndex) in group"
                :key="itemIndex"
                v-model="group[itemIndex]"
                :label="itemIndex"
                :prop="`${key}.${groupIndex}.${itemIndex}.Value`"
                :tabActive="tabActive"
                :index="groupIndex + 1"
                @dependencychanged="dependencychanged"
              ></SpInput>
            </div>
          </template>
          <!-- 单个控件 -->
          <SpInput
            :key="key"
            v-else
            v-model="mainData[key]"
            :label="key"
            :prop="`${key}.Value`"
            :tabActive="tabActive"
            @dependencychanged="dependencychanged"
          ></SpInput>
        </template>
      </el-form>
    </div>
    <div class="main-footer footer-btns">
      <el-button class="footer-btn" size="small" type="primary" plain @click="$emit('cancel')">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" size="small" type="primary" @click="onlysave">{{$t('save')}}</el-button>
      <el-button :disabled="enablePushToDevice()" class="footer-btn" size="small" type="primary" @click="submit">{{$t('Save and update')}}</el-button>
    </div>
  </div>
</template>

<script>
import SpInput from './input/sp-input'
import methods from '../plugins/methods'

export default {
  name: 'sp-main',
  components: {
    SpInput,
  },
  props: {
    mainData: {
      type: Object,
      default() {
        return {}
      },
    },
    tabActive: {
      type: String,
      default: 'default',
    },
  },
  model: {
    prop: 'mainData',
    event: 'changeMainData'
  },
  computed: {
    selectAllType: {
      get() {
        let type = true
        methods.parseData(this.mainData, (e) => {
          !e.Push && (type = false)
        })
        return type
      },
      set(val) {
        methods.parseData(this.mainData, (e) => {
          e.Push = val
        })
      },
    },
  },
  methods: {
    enablePushToDevice(){
      return this.$route.query.pushStatus == '0';
    },
    // 全选
    selectAll() {
      this.selectAllType = !this.selectAllType
      var dataArry = []
      for (let i in this.mainData) {
        if (!this.mainData[i].label) {
          this.mainData[i].label = i
        }
        this.mainData[i].xmlLabel = i
        dataArry.push(this.mainData[i])
      }
    },
    // 恢复默认值
    resetSettings() {
      methods.parseData(this.mainData, (e) => {
        e.Value = e.DefaultValue
        e.Push = false
      })
      var dataArry = []
      for (let i in this.mainData) {
        if (!this.mainData[i].label) {
          this.mainData[i].label = i
        }
        dataArry.push(this.mainData[i])
      }
    },
    //保存
    onlysave() {
      this.$refs['dataRef'].validate((valid) => {
        if (valid) {
          this.$emit('onlysave')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 保存并更新
    submit() {
      this.$refs['dataRef'].validate((valid) => {
        if (valid) {
          this.$emit('submit')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateDSTValueRules(rule, timezone) {
      var dataArry = []
      let rules = rule.split(',')
      for (let i in rules) {
        let prefixId = rules[i].indexOf('#')
        if (rules[i].substring(0,prefixId) == timezone) {
          dataArry.push(rules[i])
          console.log("rule:", rules[i])
        }
      }
      return dataArry.join(',')
    },
    dependencychanged(label, value, dependancy, init) {
      console.log('dependencychanged in sp-main', label, value, dependancy, init)
      for (let i in this.mainData) {
        if (i == dependancy) {
          this.mainData[i].FilteredValueRule = this.updateDSTValueRules(this.mainData[i].ValueRule, value)
          if (!init)
            this.mainData[i].Value = '0'
          console.log("maindata", i, this.mainData[i].Value, this.mainData[i].FilteredValueRule)
        }
      }
      this.$emit('dependencychanged',label, value, dependancy)
    },
    // 校验结果
    getValidate() {
      let flag = false
      this.$refs['dataRef'].validate((valid) => {
        flag = valid
      })
      return !flag
    },
  },
}
</script>

<style lang="scss" scoped>
.card-title {
  line-height: 2em;
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: #f2e9ff;
}
</style>
