<template>
  <el-select
    v-model="newValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    style="width: 100%;"
  >
    <el-option
      v-for="item in newOptionData"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'dist-select',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: [String, Number],
    size: String,
    clearable: Boolean,
    disabled: Boolean,
    placeholder: String,
    selectAll: {
      type: [Object, Boolean],
      default() {
        return false
      },
    },
    optionData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      oldValue: this.value,
    }
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        if (val !== this.oldValue) this.$emit('change', val)
        this.oldValue = val
      },
    },
    newOptionData() {
      const arr = Object.entries(this.optionData).map((e) => {
        return {
          label: e[1],
          value: e[0],
        }
      })
      if (this.selectAll) arr.unshift(this.selectAll)
      return arr
    },
  },
}
</script>
