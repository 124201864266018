<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <div class="search">
        <div v-if="toolsDisplay.siteShow">
          <select-site
            v-model="tabelSearch.site"
            :disabled="toolsDisplay.siteDisable"
            size="small"
            clearable
            checkboxShow
            :checkStrictly="toolsDisplay.siteDisable"
            :defaultSelectAll="toolsDisplay.siteDisable == undefined ? true : !toolsDisplay.siteDisable"
            @change="getTabelData('site')"
          ></select-site>
        </div>
        <div v-if="toolsDisplay.modelShow">
          <select-model
            ref="selectModel"
            v-model="tabelSearch.model"
            :disabled="toolsDisplay.modelDisable"
            multiple
            :modelOptions="modelOptions"
            size="small"
            :defaultSelectAll="toolsDisplay.modelDisable == undefined ? true : !toolsDisplay.modelDisable"
            @change="getTabelData('model')"
          ></select-model>
        </div>
        <div v-if="toolsDisplay.keywordsShow">
          <el-input
            v-model="tabelSearch.keywords"
            size="small"
            :placeholder="$t('firmware.searchPlaceHold')"
            prefix-icon="el-icon-search"
            clearable
            @change="getTabelData('keywords')"
          ></el-input>
        </div>
        <div v-if="toolsDisplay.groupShow">
          <select-group
            v-model="tabelSearch.groupId"
            size="small"
            selectAll
            @change="getTabelData('groupId')"
          ></select-group>
        </div>
      </div>
      <table-pagination
        ref="refTable"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @select="select"
        @select-all="selectAll"
      ></table-pagination>
    </el-col>
    <el-col :span="12">
      <div>{{ $t('selected') }}{{ selectDeviceNum }}</div>
      <table-pagination
        :tableData="devices"
        :columnData="columnData"
        paginationHide
        optionShow
      >
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('delete')"
            placement="top"
            :open-delay="1000"
            v-has="'delete'"
          >
            <el-button type="text" class="btn-p0" @click="deleteItem(scope.row)">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </el-col>
  </el-row>
</template>

<script>
import { TablePagination, SelectSite, SelectModel, SelectGroup } from '@/components'
import { deviceFilter } from '@/api/device'

export default {
  name: 'transfer-device',
  components: {
    TablePagination,
    SelectSite,
    SelectModel,
    SelectGroup,
  },
  model: {
    prop: 'devices',
    event: 'change',
  },
  props: {
    devices: Array,
    autoGet: Boolean,
    modelOptions: Array,
    //siteOptions: Array,
    columnData: {
      type: Array,
      default() {
        return [
          {
            label: this.$t('device.macAddress'),
            prop: 'macAddress',
            minWidth: 150,
            filter: 'toUpperCase'
          },
          {
            label: this.$t('device.deviceName'),
            prop: 'deviceName',
            minWidth: 150,
          },
          {
            label: this.$t('device.model'),
            prop: 'deviceModel',
            minWidth: 100,
          },
          {
            label: this.$t('sipAccount.userName'),
            prop: 'userName',
            minWidth: 140,
            filterEnable: true,
          },
        ]
      },
    },
    toolsDisplay: {
      type: Object,
      default() {
        return {
          siteShow: true,
          modelShow: true,
          keywordsShow: true,
          groupShow: false,
          siteDisable: false,
          modelDisable: false,
          keywordsDisable: false,
          groupDisable: false,
        }
      },
    },
  },
  data() {
    return {
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'macAddress',
        sortOrder: 'asc',
        site: [],
        model: [],
        groupId: null,
        keywords: '',
      },
      tabelTotal: 0,
      tableData: [],
      tableSelection: [],
    }
  },
  computed: {
    selectDeviceNum() {
      return this.devices.length
    },
  },
  mounted() {
    if (this.modelOptions) {
      this.tabelSearch.model = this.modelOptions.map(item => item.value)
    }
    this.autoGet && this.getTabelData('mounted')
  },
  methods: {
    // 获取数据
    getTabelData(type) {
      if (type) {
        this.tabelSearch.sortField = 'macAddress'
        this.tabelSearch.sortOrder = 'asc'
        type !== 'currentChange' && (this.tabelSearch.currentPage = 1)
        type !== 'sizeChange' && (this.tabelSearch.pageSize = 10)
      }
      deviceFilter(this.tabelSearch)
        .then((res) => {
          this.tableData = res.data.result.rows[0].list // 表格数据
          this.tabelTotal = res.data.result.rows[0].total // 表格数据条目
          this.$refs.refTable.clearFilter() // 表格过滤清空
          if (this.devices.length) this.init()
        })
        .catch(() => {})
    },
    // 回显
    init() {
      this.$nextTick(() => {
        this.tableData && this.tableData.forEach((e) => {
          if (this.devices.map((ee) => ee.id).includes(e.id)) {
            this.$refs.refTable.toggleRowSelection(e)
          }
        })
      })
    },
    // 清除选择
    clearSelection() {
      this.$refs.refTable.clearSelection()
    },
    // 全选择
    selectAll(val) {
      const deviceIds = this.devices.map((e) => e.id),
        selectIds = val.map(row => row.id)
      let arr = [...this.devices]
      let isSelect = !selectIds.every(row => deviceIds.includes(row))
      if (isSelect) {  // 选择
        val.forEach((e) => {
          !deviceIds.includes(e.id) && arr.push(e)
        })
      } else {  // 取消选择
        this.tableData
          .map(row => {
            if (!selectIds.includes(row.id)) {
              return row
            }
          })
          .filter(item => item)
          .forEach(deleteItem => {
            if (deleteItem) {
              let index = arr.findIndex(item => item.id === deleteItem.id)
              index !== -1 && arr.splice(index, 1)
            }
          })
      }
      this.$emit('change', arr)
    },
    // 选择
    select(val, row) {
      if (this.devices.map((e) => e.id).includes(row.id)) {
        this.$emit(
          'change',
          this.devices.filter((e) => e.id != row.id)
        )
      } else {
        this.$emit('change', [...this.devices, row])
      }
    },
    // 触发筛选
    tabelSearchChange(option) {
      let tabelSearch = JSON.parse(JSON.stringify(this.tabelSearch))
      tabelSearch.site = option.site || this.tabelSearch.site
      tabelSearch.model = option.model || this.tabelSearch.model
      tabelSearch.groupId = option.groupId || null
      tabelSearch.keywords = option.keywords || ''
      //console.log('tabelSearch.site %s model %s option %s', tabelSearch.site, tabelSearch.model, option)
      if(JSON.stringify(this.tabelSearch) !== JSON.stringify(tabelSearch)) {
        this.tabelSearch = tabelSearch
        this.getTabelData('tabelSearchChange')
      }
    },
    // 删除
    deleteItem(row) {
      this.$refs.refTable.toggleRowSelection(this.tableData.filter((e) => e.id == row.id)[0])
      this.$emit('change', this.devices.filter((e) => e.id != row.id))
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  padding: 0 30px;
  > div {
    flex: 1;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>