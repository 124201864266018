export default [
  {
    path: '/user',
    name: 'user',
    meta: {
      power: 'user',
    },
    component: () => import(/* webpackChunkName: "User" */ '@/views/System/User/User.vue'),
  },
  {
    path: '/user/addRole',
    name: 'addRole',
    meta: {
      power: 'user',
    },
    component: () =>
      import(/* webpackChunkName: "AddRole" */ '@/views/System/User/add-role/Add-role.vue'),
  },
  {
    path: '/user/editRole',
    name: 'editRole',
    meta: {
      power: 'user',
    },
    component: () =>
      import(/* webpackChunkName: "AddRole" */ '@/views/System/User/add-role/Add-role.vue'),
  },
  {
    path: '/authorizedChannels',
    name: 'authorizedChannels',
    meta: {
      power: 'authorizedChannels',
    },
    component: () =>
      import(/* webpackChunkName: "AuthorizedChannels" */ '@/views/System/AuthorizedChannels.vue'),
  },
  {
    path: '/syslog',
    name: 'syslog',
    meta: {
      power: 'syslog',
    },
    component: () => import(/* webpackChunkName: "Syslog" */ '@/views/System/Syslog.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      power: 'about',
    },
    component: () => import(/* webpackChunkName: "About" */ '@/views/System/About.vue'),
  },
  {
    path: '/backup',
    name: 'backup',
    meta: {
      power: 'backup',
    },
    component: () => import(/* webpackChunkName: "Backup" */ '@/views/System/Backup/Backup.vue'),
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    meta: {
      power: 'upgrade',
    },
    component: () => import(/* webpackChunkName: "Upgrade" */ '@/views/System/Upgrade.vue'),
  },
  {
    path: '/apiService',
    name: 'apiService',
    meta: {
      power: 'apiService',
    },
    component: () => import(/* webpackChunkName: "ApiService" */ '@/views/System/ApiService.vue'),
  },
]
