<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="960px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <span slot="footer">
      <el-button class="dialog-btn" @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button class="dialog-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
    </span>
    <el-alert
      v-if="req.subtitle"
      :title="req.subtitle"
      type="info"
      center
      :closable="false"
    ></el-alert>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item class="el-form-item" :label="$t('task.executionModel')">
        <el-radio v-model="form.type" :label="1">{{ $t('task.immediate') }}</el-radio>
        <el-radio v-model="form.type" :label="2">{{ $t('task.scheduled') }}</el-radio>
      </el-form-item>
      <el-form-item :label="$t('task.taskName')" prop="name" v-if="form.type == 2">
        <el-input
          v-model="form.name"
          :maxlength="128"
          :placeholder="$t('task.taskNamePla')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('task.taskTime')" prop="time" v-if="form.type == 2">
        <!-- <el-date-picker
          v-model="form.time"
          type="datetimerange"
          :start-placeholder="$t('startTime')"
          :end-placeholder="$t('endTime')"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
          style="width: 100%;"
          :picker-options="pickerOptions"
        ></el-date-picker> -->
        <el-date-picker
          v-model="form.time"
          type="datetime"
          value-format="timestamp"
          style="width: 45%;"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialog-task-execution-mode',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('task.pleaseSelectTheExecutionMode')
      },
    },
    req: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    const now = new Date(new Date().toLocaleDateString()).getTime()
    return {
      form: {
        type: 1,
        name: '',
        time: now,
      },
      rules: {
        name: {
          required: true,
          message: this.$t('task.taskNameRule'),
          trigger: 'blur',
        },
        time: {
          required: true,
          validator: (rule, value, callback) => {
            const nowTime = parseInt(new Date().getTime()/1000)*1000
            let legalTime = new Date().getTime() + (5 * 60 * 1000)
            if (nowTime == value) {
              this.form.time = value + (10 * 60 * 1000)
            } else if (value - legalTime < 0) {
              callback(new Error(this.$t('task.taskBeginTimeRule')))
            } else {
              callback()
            }
          },
          trigger: 'change',
        },
      },
    }
  },
  methods: {
    // 检查时间范围
    checkTimeRange() {
      let startTime = this.form.time[0],
        endTime = this.form.time[1]
      if (startTime > endTime) {
        this.$message.error('time range error!')
        this.form.time[1] = this.form.time[0]
        this.$forceUpdate()
        return false
      } else {
        return true
      }
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            type: this.form.type,
            name: this.form.name,
          }
          if (this.req.selectList) params.deviceIds = this.req.selectList
          // 立即
          if (this.form.type == 1) {
            params.beginTime = new Date().getTime()
          } // 定时
          else if (this.form.type == 2) {
            params.beginTime = this.form.time
            params.endTime = this.form.time + 86399000
          }
          this.$emit('submit', params, this.req)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 55px 0;
}
.form {
  padding: 43px 88px;
}
</style>
