import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    axiosLoading: false,
    sidebarUpdate: false,
    appWidth: 0,
    appHeight: 0,
    isLAN: false, // 应用类型 | F-云端 T-局端
    dialogBackupStatusInfo: undefined,
  },
  getters: {
    pageTabelHeight: (state) => {
      return state.appHeight > 381 ? state.appHeight - 381 : 0
    },
    dialogBackupStatusVisible: (state) => {
      const backupCode = [100000, 110000, 120000]
      return state.dialogBackupStatusInfo ? backupCode.includes(state.dialogBackupStatusInfo.code) : false
    }
  },
  mutations: {
    SET_AXIOS_LOADING(state, payload) {
      state.axiosLoading = payload
    },
    SET_SIDEBAR_UPDATE(state, payload) {
      state.sidebarUpdate = payload
    },
    SET_APP_WIDTH(state, payload) {
      state.appWidth = payload
    },
    SET_APP_HEIGHT(state, payload) {
      state.appHeight = payload
    },
    SET_DIALOG_BACKUP_STATUS_INFO(state, payload) {
      state.dialogBackupStatusInfo = payload
    },
    SET_IS_LAN(state, payload) {
      state.isLAN = payload
      document.title = payload ? i18n.t('title-lan') : i18n.t('title');
    }
  }
}
