export default [
  {
    path: '/task',
    name: 'task',
    meta: {
      power: 'task',
    },
    component: () => import(/* webpackChunkName: "Task" */ '@/views/Task/Task.vue'),
  },
  {
    path: '/task/addTask',
    name: 'addTask',
    meta: {
      power: 'task',
    },
    component: () => import(/* webpackChunkName: "TaskDetails" */ '@/views/Task/TaskDetails.vue'),
  },
  {
    path: '/task/editTask',
    name: 'editTask',
    meta: {
      power: 'task',
    },
    component: () => import(/* webpackChunkName: "TaskDetails" */ '@/views/Task/TaskDetails.vue'),
  },
  {
    path: '/task/runAgainTask',
    name: 'runAgainTask',
    meta: {
      power: 'task',
    },
    component: () => import(/* webpackChunkName: "TaskDetails" */ '@/views/Task/TaskDetails.vue'),
  },
]
