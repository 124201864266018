<template>
  <div>
    <el-table :data="newData">
      <!-- Key -->
      <el-table-column :label="$t('sitParam.Key', [''])">
        <template slot-scope="scope">
          <!-- @change="checkboxChange(scope.row)" -->
          <el-checkbox
            v-model="scope.row.Push"
            >{{
              typeof scope.row.Name == 'number'
                ? $t('sitParam.Key', [
                    scope.$index + 1 + (pageCount - 1) * pageSize,
                  ])
                : $t('sitParam.phoneKeysKeyValues.' + scope.row.Name)
            }}</el-checkbox
          >
        </template>
      </el-table-column>
      <!-- Type -->
      <el-table-column :label="$t('sitParam.Type')">
        <template slot-scope="scope">
          <el-select
            size="small"
            v-model="scope.row['Type'].Value"
            :placeholder="$t('pleaseSelect')"
            :disabled="!scope.row.Push"
            @change="valueChange(scope.row)"
          >
            <el-option
              v-for="item in scope.row['Type'].ValueRule.split(',')"
              :key="item"
              :label="$t(`sitParam.phoneKeysTypeValues.${item}`)"
              :value="item"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <!-- Account -->
      <el-table-column :label="$t('sitParam.Account')">
        <template slot-scope="scope">
          <el-select
            size="small"
            v-model="scope.row['Account'].Value"
            :placeholder="$t('pleaseSelect')"
            :disabled="
              getDisabled('Account', scope.row.Push, scope.row['Type'].Value)
            "
          >
            <el-option
              v-for="item in (subTypeShow ? getAccount(scope.row['Type'].Value, scope.row['SubType'].Value).split(',') : scope.row['Account'].ValueRule.split(','))"
              :key="item"
              :label="subTypeShow 
                  ? $t(`sitParam.phoneKeysAccountValues.${item}`) 
                  : $t(`sitParam.accountArr`, [item])"
              :value="item"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>

      <!-- subType -->
      <el-table-column v-if="subTypeShow" :label="$t('sitParam.SubType')">
        <template slot-scope="scope">
          <el-select
            size="small"
            v-model="scope.row['SubType'].Value"
            :placeholder="$t('pleaseSelect')"
            :disabled="
              getDisabled('SubType', scope.row.Push, scope.row['Type'].Value)
            "
          >
            <el-option
              v-for="item in getSubTypes(scope.row['Type'].Value, scope.row['SubType'].ValueRule).split(',')"
                :key="item"
                :label="$t(`sitParam.phoneKeysSubTypeValues.${item}`)"
                :value="item"
            ></el-option>
          </el-select>
        </template>
       </el-table-column> 
      <!-- Value -->
      <el-table-column :label="$t('sitParam.Value')">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row['Value'].Value"
            size="small"
            :disabled="
              getDisabled('Value', scope.row.Push, scope.row['Type'].Value)
            "
          ></el-input>
        </template>
      </el-table-column>
      <!-- Label -->
      <el-table-column :label="$t('sitParam.Label')">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row['Label'].Value"
            size="small"
            :disabled="
              getDisabled('Label', scope.row.Push, scope.row['Type'].Value)
            "
          ></el-input>
        </template>
      </el-table-column>
      <!-- Extension -->
      <el-table-column :label="$t('sitParam.Extension')">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row['Extension'].Value"
            size="small"
            :disabled="
              subTypeShow 
                  ? (getDisabled('Extension', scope.row.Push, scope.row['Type'].Value) || checkPickupWithSubtype(scope.row['Type'].Value, scope.row['SubType'].Value)) 
                  : getDisabled('Extension', scope.row.Push, scope.row['Type'].Value)
            "
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-if="paginationShow"
      :page-size="pageSize"
      :page-count="pageCount"
      @current-change="currentChange"
      background
      layout="prev, pager, next"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    paginationShow: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    total: {
      type: Number,
      default: 60,
    },
  },
  model: {
    prop: 'data',
    event: 'changeNewData'
  },
  data() {
    return {
      pageCount: 1,
    }
  },
  computed: {
    newData() {
      if (this.paginationShow) {
        return this.data.filter((e, i) => {
          const num = i + 1
          return (
            (this.pageCount - 1) * this.pageSize < num &&
            num <= this.pageCount * this.pageSize
          )
        })
      } else {
        return this.data
      }
    },
    subTypeShow: {
      get() {
        if (this.$route.query.deviceModel == "H2" || this.$route.query.deviceModel == "H2P") {
          return true
        } else {
          return false
        }
      },
      set(val) {
        
      }
    }
  },
  methods: {
    // checkboxChange(i) {
    //   let i_Account = i.Account
    //   i_Account.Push = i.Push
    //   i_Account.label = i.Account.Key
    //   let i_Extension = i.Extension
    //   i_Extension.Push = i.Push
    //   i_Extension.label = i.Extension.Key
    //   let i_Label = i.Label
    //   i_Label.Push = i.Push
    //   i_Label.label = i.Label.Key
    //   let i_Type = i.Type
    //   i_Type.Push = i.Push
    //   i_Type.label = i.Type.Key
    //   let i_Value = i.Value
    //   i_Value.Push = i.Push
    //   i_Value.label = i.Value.Key
    //   this.$emit('checkboxChange', i_Account)
    //   this.$emit('checkboxChange', i_Extension)
    //   this.$emit('checkboxChange', i_Label)
    //   this.$emit('checkboxChange', i_Type)
    //   this.$emit('checkboxChange', i_Value)
    // },
    currentChange(val) {
      this.pageCount = val
    },
    valueChange(scope) {
      // 修改type会变化可配置项，需要重置为默认值
      scope.Account.Value = scope.Account.DefaultValue
      scope.Value.Value = scope.Value.DefaultValue
      scope.Label.Value = scope.Label.DefaultValue
      scope.Extension.Value = scope.Extension.DefaultValue
      if (scope.Type.Value == "1001" || scope.Type.Value == "1003" || scope.Type.Value == "1014" || scope.Type.Value == "1022") {
        scope.SubType.Value = scope.Type.Value.substring(2) + "00"
        console.log(scope.SubType.Value, "scope.SubType.Value")
      } else {
        scope.SubType.Value = scope.SubType.DefaultValue
      }
    },
    subTypeChange(scope) {
      scope.Account.Value = scope.Account.DefaultValue
      scope.Value.Value = scope.Value.DefaultValue
      scope.Label.Value = scope.Label.DefaultValue
      scope.Extension.Value = scope.Extension.DefaultValue
    },
    getSubTypes(type, valueRule) {
      console.log("getSubTypes", type, valueRule)
      let subtyps = valueRule.split(",")
      if (type == "1001" || type == "1003" || type == "1014" || type == "1022") {
        let typePrefix = type.substring(2)
        var filteredSubTypes = []
        for (let i in subtyps) {
          if (subtyps[i].startsWith(typePrefix)) {
            filteredSubTypes.push(subtyps[i])
            console.log("subtype", subtyps[i])
          }
        }
        return filteredSubTypes.join(",")
      } else {
        return ""
      }
    },
  
    getAccount(type, subType) {
      if (type == "1001" && (subType == "0109" || subType == "0110" || subType == "0111")) {
        return ("-1,1,2")
      }
      else {
        return ("1,2")
      }
    },

    checkPickupWithSubtype(type, subType) {
      if (type == "1001" && !(subType == "0107" || subType == "0108" || subType == "0109" || subType == "0110" || subType == "0111") ) {
        console.log("checkPickupWithSubtype false")
        return false
      } else {
        console.log("checkPickupWithSubtype true")
        return true
      }
    },

    // 查询禁用
    getDisabled(type, Push, val) {
      const obj = {
        Account: [
          '0',
          '3',
          '4',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '14',
          '18',
          '19',
          '23',
          '24',
          '42',
          '58',
          '61',
          '69',
          '101',
          '102',
          '103',
          '1003',
          '1004',
          '1007',
          '1013',
          '1014',
          '1020',
          '1021',
          '1022'
        ],
        Value: [
          '0',
          '2',
          '3',
          '4',
          '9',
          '10',
          '11',
          '12',
          '18',
          '23',
          '24',
          '42',
          '58',
          '60',
          '61',
          '69',
          '101',
          '102',
          '103',
          '1002',
          '1003',
          '1013'
        ],
        Label: ['0', '2', '101', '102', '103'],
        Extension: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '7',
          '10',
          '11',
          '12',
          '13',
          '17',
          '18',
          '19',
          '21',
          '22',
          '23',
          '24',
          '42',
          '61',
          '69',
          '101',
          '102',
          '103',
          '1002',
          '1003',
          '1004',
          '1007',
          '1013',
          '1004',
          '1020',
          '1021',
          '1022'
        ],
        SubType: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '7',
          '10',
          '11',
          '12',
          '13',
          '17',
          '18',
          '19',
          '21',
          '22',
          '23',
          '24',
          '42',
          '61',
          '69',
          '101',
          '102',
          '103',
          '1002',
          '1004',
          '1007',
          '1013',
          '1021',
          '1022'
        ],
      }
      return Push ? obj[type].includes(val) : true
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table th {
  background-color: #F6F6F6;
}
.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
