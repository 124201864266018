<template>
  <el-input-number
    v-model="newValue"
    :min="valMin"
    :max="valMax"
    :disabled="disabled"
    :placeholder="placeholder"
  ></el-input-number>
</template>

<script>
export default {
  name: 'sp-number',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    item: Object,
  },
  data() {
    let valArr = this.item.ValueRule.split(',')
    valArr[0] = valArr[0].replace('[', '')
    valArr[1] = valArr[1].replace(']', '')
    return {
      valMin: valArr[0] == '*' ? -Infinity : +valArr[0],
      valMax: valArr[1] == '*' ? Infinity : +valArr[1],
    }
  },
  computed: {
    newValue: {
      get() {
        return +this.value
      },
      set(val) {
        this.$emit('change', val + '')
      },
    },
  },
}
</script>
