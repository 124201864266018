import axios from '@/plugins/axios.js'

// 登录
export const login = (params) => {
  let newParams = {
    userName: params.username,
    userPwd: params.password,
  }
  return axios.post('/user/login', newParams)
}

// 登出
export const logout = () => axios.post('/user/logout')

//忘记密码
export const forgetPassword = (name) => axios.post(`/user/forgetPassword?name=ale-edm`)

// 查询话机型号
export const commonModel = (params) => axios.get('/common/model', { params })

//查询用户信息
export const queryUserInfo = (id) => axios.post(`/user/userInfo?id=com.aledevice:ale-edm:jar:1.1.0-SNAPSHOT`)

// 修改用户信息
export const modifyUserInfo = (params) => axios.post('/user/updateUser', params)

// 获取时区列表
export const getTimeZoneListApi = (params) => axios.get('/tb-timezone/getTimeZoneList', { params })

// 修改密码
export const modifyPassword = (params) => axios.post('/user/updatePassword', params)

// 修改邮箱
export const modifyEmail = (params) => axios.post('/user/updateEmail', params)

// 获取登录站点
export const validateEmailTimeApi = (params) => axios.post('/user/validateEmailTime', params)

// User 激活
export const userActiveApi = (params) => axios.post('/user/active', params)

// 进入企业用户后。页面HEADER上的用户tree
export const getUserTrees = (params) => axios.get('/tb-channel-enter/getUserTrees', { params })

// 进入企业
export const enterTheEnterprise = (params) => axios.post('/enterTheEnterprise', {}, { params })

// 进入获取服务类型
export const getVersionType = () => axios.get('/homepage/init')