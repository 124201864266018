<template>
  <el-steps :active="active" align-center>
    <el-step
      :class="[step_idx < active ? 'is-finish' : 'not-finish']"
      v-for="(step, step_idx) in steps"
      :key="step"
      :title="step"
    ></el-step>
  </el-steps>
</template>

<script>
export default {
  name: 'kxjl-steps',
  props: {
    active: Number,
    steps: Array
  }
}
</script>

<style lang="scss" scoped>
  .el-steps {
    width: 100%;
    .is-finish {
      ::v-deep .el-step__icon {
        background-color: $--color-primary;
        color: #FFF;
      }
    }
    .not-finish {
      ::v-deep .el-step__icon {
        color: $--color-primary;
        border-color: $--color-primary;
      }
      ::v-deep .is-process,
      ::v-deep .is-wait {
        color: $--color-primary;
      }
    }
  }
</style>
