import axios from '@/plugins/axios.js'

// 查询
export const groupSearch = (params) => {
  let { keywords, ...newParams } = params
  newParams.groupName = keywords == null ? '' : keywords
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/tb-group-template/selectGroup', newParams)
}
// 查询
export const groupQuery = (params) => axios.get('/tb-group-template/selectGroupById', { params })

// 按分组配置-下拉数据
export const groupTempSelect = (params) =>
  axios.get('/tb-group-template/groupTempSelect', { params })

// 查询组-配置模板下的话机明细
export const groupDeviceDisplay = (params) => axios.post('/tb-group-template/groupDisplay', params)

// 新增
export const groupAdd = (params) => axios.post('/tb-group-template/addNewGroup', params)

// 更新
export const groupUpdate = (params) => axios.post('/tb-group-template/updateGroup', params)

// 批量删除
export const removeGroupBatch = (data) => axios.delete('/tb-group-template/deleteGroup', { data })

// 删除
export const removeGroup = (data) => axios.delete('/tb-group-template/delete', { data })

// 下载
export const downloadGroup = (params) =>
  axios.get(`/cfg/type/group/${params.relationId}/${params.modeltype}/${params.templateName}`, {
    responseType: 'blob',
  })

// groupName验证唯一性
export const groupNameVerify = (params) => axios.get('/tb-group-template/groupNameVerify', { params })