import {
  login,
  logout,
  enterTheEnterprise
} from '@/api/app.js'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    loginInfo: {},
    // loginParams: {},
    asyncRouterMap: {},
    permissionButtons: {},
    channelId: undefined, // channel托管时使用的id
    zoneOffset: 0, // 时区 | 单位分钟（m）
  },
  mutations: {
    SET_LOGIN_INFO(state, payload) {
      Object.keys(payload).forEach((e) => {
        state[e] = payload[e]
      })
    },
    SET_ZONE_ZONEID(state, payload) {
      state.loginInfo.zoneId = payload.id
      state.loginInfo.timezone = payload.timezone
    },
    SET_ZONE_OFFSET(state, payload) {
      state.zoneOffset = +payload
    },
  },
  actions: {
    // 登录
    Login({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            const { buttons, resources, ...loginInfo } = res.data.result.rows[0]
            commit('SET_LOGIN_INFO', {
              loginInfo, // 用户信息
              // loginParams: params, // 登录参数
              asyncRouterMap: resources, // 权限菜单数据
              permissionButtons: buttons, // 权限按钮数据
            })
            // channel角色
            if (loginInfo.enterpriseType == 'channel') {
              commit('SET_LOGIN_INFO', { channelId: loginInfo.id })
            }
            resolve(res)
          })
          .catch((error) => {
            console.log('=====登录失败=====')
            reject(error)
          })
      })
    },
    // 登出
    LoginOut({ commit }) {
      logout()
        .then(() => {
          commit('SET_LOGIN_INFO', {
            loginInfo: {}, // 用户信息
            // loginParams: {}, // 登录参数
            asyncRouterMap: {}, // 权限菜单数据
            permissionButtons: {}, // 权限按钮数据
            channelId: undefined, // 权限按钮数据
            zoneOffset: 0, // 时区偏移
          })
          router.push('/login')
        })
    },
    // 进入企业
    EnterTheEnterprise({ commit }, params) {
      return new Promise((resolve, reject) => {
        enterTheEnterprise(params)
          .then((res) => {
            const { buttons, resources, ...loginInfo } = res.data.result.rows[0]
            commit('SET_LOGIN_INFO', {
              loginInfo, // 用户信息
              // loginParams: params, // 登录参数
              asyncRouterMap: resources, // 权限菜单数据
              permissionButtons: buttons, // 权限按钮数据
            })
            resolve(res)
          })
          .catch((error) => {
            console.log('=====登录失败=====')
            reject(error)
          })
      })
    },
  },
}
