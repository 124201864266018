import { render, staticRenderFns } from "./sp-main-phone-keys.vue?vue&type=template&id=195fa11c&scoped=true"
import script from "./sp-main-phone-keys.vue?vue&type=script&lang=js"
export * from "./sp-main-phone-keys.vue?vue&type=script&lang=js"
import style0 from "./sp-main-phone-keys.vue?vue&type=style&index=0&id=195fa11c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195fa11c",
  null
  
)

export default component.exports