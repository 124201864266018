<template>
  <el-select
    v-if="show || optionData.length > 1"
    v-model="newValue"
    :size="size"
    :clearable="clearable"
    :disabled="disabled"
    :placeholder="placeholder"
    style="width: 100%;"
  >
    <el-option
      v-for="item in optionData"
      :key="item[maps.value]"
      :label="getLabel(item)"
      :value="item[maps.value]"
    ></el-option>
  </el-select>
</template>

<script>
import selectMixin from './mixin/select'
import { frimwareList } from '@/api/firmware'

export default {
  name: 'select-firmware',
  mixins: [selectMixin],
  props: {
    placeholder: {
      type: String,
      default() {
        return window.vm.$t('components.selectFirmware.selectFirmwarePla')
      },
    },
    selectAllText: {
      type: String,
      default() {
        return window.vm.$t('components.selectFirmware.allFirmware')
      },
    },
    maps: {
      type: Object,
      default: () => {
        return {
          label: 'firmwareName',
          subLabel: 'firmwareVersion',
          value: 'id',
        }
      },
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (this.isArray && this.data.length) {
      this.setData(this.data)
    } else {
      this.getData()
    }
  },
  methods: {
    getData() {
      frimwareList({})
        .then(({ data }) => {
          this.optionData = data.result.rows.map((e) => {
            return this.getNode(e, e)
          })
          this.isArray && this.$emit('update:data', [...this.optionData])
          this.selectAll && this.optionData.unshift(this.getNode(this.selectAllText, null))
        })
        .catch(() => {})
    },
  }
}
</script>
