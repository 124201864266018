import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n' // 国际化
import router from './router' // 路由
import store from './store' // 状态

import './plugins/element.js' // UI组件
import './plugins/directive.js' // 指令

import './style/style.scss' // 全局样式
import './style/iconfont/iconfont.css' // 图标
import './style/iconfont/iconfont.js' // 图标

import DateFilter from './plugins/dateFilter.js' // 时间格式化插件
Vue.use(DateFilter)

Vue.config.productionTip = false

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
})
app.$mount('#app')
window.vm = app
