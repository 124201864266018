<template>
  <div class="layout">
    <LayoutHeader></LayoutHeader>
    <div class="layout-container">
      <LayoutSidebar></LayoutSidebar>
      <div class="layout-main" v-loading="axiosLoading" :element-loading-text="$t('loading')">
        <LayoutBreadcrumb v-if="breadcrumbShow" :data="breadcrumbData"></LayoutBreadcrumb>
        <router-view />
        <LayoutFooter></LayoutFooter>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from './layoutHeader'
import LayoutSidebar from './layoutSidebar'
import LayoutBreadcrumb from './layoutBreadcrumb'
import LayoutFooter from './layoutFooter'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: {
    LayoutHeader,
    LayoutSidebar,
    LayoutBreadcrumb,
    LayoutFooter,
  },
  computed: {
    ...mapState('session', ['axiosLoading']),
    breadcrumbData() {
      return this.$route.path.substr(1).split('/')
    },
    breadcrumbShow() {
      const blacklist = ['', '404'] // 黑名单
      return !blacklist.includes(this.breadcrumbData[0])
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1240px;
  height: 100%;
  .layout-container {
    display: flex;
    width: 100%;
    height: calc(100% - 82px);
    .layout-main {
      flex: auto;
      width: calc(100vw - 320px);
      height: 100%;
      position: relative;
    }
  }
}
</style>