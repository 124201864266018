<template>
  <div class="input-mac">
    <el-input
      v-model="form[0]"
      ref="input0"
      maxlength="3"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
    <div class="division">{{ split }}</div>
    <el-input
      v-model="form[1]"
      ref="input1"
      maxlength="3"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
    <div class="division">{{ split }}</div>
    <el-input
      v-model="form[2]"
      ref="input2"
      maxlength="3"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
    <div class="division">{{ split }}</div>
    <el-input
      v-model="form[3]"
      ref="input3"
      maxlength="3"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
    <div class="division">{{ split }}</div>
    <el-input
      v-model="form[4]"
      ref="input4"
      maxlength="3"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
    <div class="division">{{ split }}</div>
    <el-input
      v-model="form[5]"
      maxlength="3"
      ref="input5"
      :disabled="disabled"
      @paste.native.capture.prevent="handlePaste"
    ></el-input>
  </div>
</template>

<script>
import { macFormat } from '@/plugins/methods'

export default {
  name: 'input-mac',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    split: {
      type: String,
      default: ':',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: ['', '', '', '', '', ''],
    }
  },
  watch: {
    value(val) {
      this.$set(this, 'form', this.getMacArr(val))
    },
    form(val) {
      let _this = this
      val.forEach((e, i) => {
        if (e.length == 3) {
          val[i] = e.substr(0, 2)
          let curPosition = _this.getCursurPosition(_this.$refs['input' + i].$refs["input"])
          _this.$nextTick(() =>{
            if(curPosition !== 3){
              _this.$refs['input' + i].$refs["input"].selectionStart = _this.$refs['input' + i].$refs["input"].selectionEnd  = curPosition // 更新光标位置，将之前算的新位置给输入框光标
            }else{
              if(i === val.length-1) return
              _this.$refs['input' + (i+1)].$refs["input"].selectionStart = _this.$refs['input' + (i+1)].$refs["input"].selectionEnd  = 1
            }
          })
          if(i === val.length-1) return
          if(_this.getCursurPosition(_this.$refs['input' + i].$refs["input"]) === 3){
            _this.$refs['input' + (i + 1)].focus()
            if(!val[i + 1]){
              val[i + 1] = e.substr(2, 1)
            }else{
              val[i + 1] = e.substr(2, 1)+ val[i + 1].substr(0, 1)
            }
          }
        }
      })
      _this.$emit('change', val.join(_this.split))
    },
  },
  created() {
    if (this.value) this.$set(this, 'form', this.getMacArr(this.value))
  },
  methods: {
    getCursurPosition(oTxt1){
      var cursurPosition=-1;
      if(!(!oTxt1.selectionStart && typeof oTxt1.selectionStart !== 'number')){//非IE浏览器
        cursurPosition= oTxt1.selectionStart;
      }else{//IE
        var range = document.selection.createRange();
        range.moveStart("character",-oTxt1.value.length);
        cursurPosition=range.text.length;
      }
      return cursurPosition
    },
    getMacArr(val) {
      let form = []
      const newVal = macFormat(val)
      if (newVal) {
        form = newVal.split(this.split)
      } else {
        form = ['', '', '', '', '', '']
        const str = val.replace(/[-:.]/g, this.split)
        if (str.includes(this.split)) {
          str.split(this.split).forEach((e, i) => {
            form[i] = e.substr(0, 2)
          })
        } else {
          form = [str.substr(0, 2), '', '', '', '', '']
        }
      }
      return form
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData
      const mac = clipboardData.getData('Text')
      this.$set(this, 'form', this.getMacArr(mac))
    },
  },
}
</script>

<style lang="scss" scoped>
.input-mac {
  display: flex;
  .el-input {
    width: calc((100% - 20px * 5) / 6);
    ::v-deep.el-input__inner {
      padding: 0 15px;
      text-align: center;
    }
  }
  .division {
    width: 20px;
    line-height: 54px;
    text-align: center;
  }
}
</style>
