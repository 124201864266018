export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: null,
    size: String,
    clearable: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: 'Please Select',
    },
    data: {
      type: [Array, Boolean],
      default: () => {
        return false
      },
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    selectAllText: {
      type: String,
      default: 'All',
    },
    maps: {
      type: Object,
      default: () => {
        return {
          label: 'label',
          value: 'value',
        }
      },
    },
    subLabel: {
      type: Boolean,
      default: false,
    },
    labelTemplate: {
      type: String,
      default: '($)',
    },
  },
  data() {
    return {
      oldValue: this.value,
      optionData: [],
    }
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        if (val != this.oldValue) this.$emit('change', val)
        this.oldValue = val
      },
    },
    isArray() {
      return Array.isArray(this.data)
    },
  },
  methods: {
    getData(getDataApi, params) {
      getDataApi(params)
        .then(({ data }) => {
          this.optionData = data.result.rows
          this.isArray && this.$emit('update:data', [...this.optionData])
          this.selectAll && this.optionData.unshift(this.getNode(this.selectAllText, null))
        })
        .catch(() => {})
    },
    setData(data) {
      this.optionData = [...data]
      this.selectAll && this.optionData.unshift(this.getNode(this.selectAllText, null))
    },
    getNode(label, value) {
      const obj = {}
      obj[this.maps.label] = label
      obj[this.maps.value] = value
      return obj
    },
    getLabel(item) {
      let str = item[this.maps.label]
      if (this.subLabel && item[this.maps.subLabel])
        str += this.labelTemplate.replace('$', item[this.maps.subLabel])
      return str
    },
  },
}
