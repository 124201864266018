export default [
  {
    path: '/device/setParam',
    meta: {
      power: 'device',
    },
    component: () => import(/* webpackChunkName: "SetParam" */ '@/views/SetParam/SetParamPage.vue'),
  },
  {
    path: '/model/setParam',
    meta: {
      power: 'model',
    },
    component: () => import(/* webpackChunkName: "SetParam" */ '@/views/SetParam/SetParamPage.vue'),
  },
  {
    path: '/group/setParam',
    meta: {
      power: 'group',
    },
    component: () => import(/* webpackChunkName: "SetParam" */ '@/views/SetParam/SetParamPage.vue'),
  },
  {
    path: '/site/setParam',
    meta: {
      power: 'site',
    },
    component: () => import(/* webpackChunkName: "SetParam" */ '@/views/SetParam/SetParamPage.vue'),
  },
  // {
  //   path: '/MAC/setParam',
  //   meta: {
  //     power: 'MAC',
  //   },
  //   component: () => import(/* webpackChunkName: "SetParam" */ '@/views/SetParam/SetParamPage.vue'),
  // },
]
