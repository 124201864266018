<template>
  <div>
    <div tabindex="-1" role="dialog" aria-modal="true" aria-label="Tip" class="el-message-box__wrapper" style="z-index: 2002;">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <span>{{ $t('tip') }}</span>
          </div>
          <button type="button" aria-label="Close" class="el-message-box__headerbtn" @click="handleClose">
            <i class="el-message-box__close el-icon-close"></i>
          </button>
        </div>
        <div class="el-message-box__content">
          <div class="el-message-box__container">
            <div class="el-message-box__status el-icon-warning"></div>
            <div class="el-message-box__message">
              <p>{{ dialogBackupStatusInfo.message }}</p>
              <p v-if="dialogBackupStatusInfo.code == 120000 || status === 3"><br>{{ $t('components.systemStatus.tipStatus3') }}</p>
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <el-button
            v-if="dialogBackupStatusInfo.cancelShow"
            size="small"
            :loading="cancelLoading"
            @click="handleCancel">
            {{ $t('cancel') }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="v-modal" tabindex="0" style="z-index: 2001;"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { backupStatus, backupCancel } from '@/api/backup'

export default {
  name: 'backup-now',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      interval: null,
      status: null,
      cancelLoading: false,
      cancelMessage: '',
    }
  },
  computed: {
    ...mapState('session', ['dialogBackupStatusInfo', 'isBackup'])
  },
  watch: {
    status(val) {
      if(val === 0) {
        if(this.cancelMessage) this.$message.success(this.cancelMessage)
        setTimeout(location.reload(), 3000)
      }
    }
  },
  mounted() {
    this.interval = setInterval(this.getBackupStatus, 1 * 1000)
  },
  methods: {
    getBackupStatus() {
      backupStatus().then(({data}) => {
        this.status = data.result.rows[0]
      })
    },
    handleCancel() {
      this.cancelLoading = true
      backupCancel().then(({data}) => {
        this.cancelMessage = data.message
      })
    },
    // 取消
    handleClose() {
      clearInterval(this.interval)
      this.$store.commit('session/SET_DIALOG_BACKUP_STATUS_INFO')
    },
  },
}
</script>