export default [
  /* model */
  {
    path: '/model',
    name: 'model',
    meta: {
      power: 'model',
    },
    component: () =>
      import(/* webpackChunkName: "Model" */ '@/views/Configuration/Model/Model.vue'),
  },
  {
    path: '/model/addModel',
    name: 'addModel',
    meta: {
      power: 'model',
    },
    component: () =>
      import(/* webpackChunkName: "ModelDetails" */ '@/views/Configuration/Model/ModelDetails.vue'),
  },
  {
    path: '/model/editModel',
    name: 'editModel',
    meta: {
      power: 'model',
    },
    component: () =>
      import(/* webpackChunkName: "ModelDetails" */ '@/views/Configuration/Model/ModelDetails.vue'),
  },
  /* group */
  {
    path: '/group',
    name: 'group',
    meta: {
      power: 'group',
    },
    component: () =>
      import(/* webpackChunkName: "Group" */ '@/views/Configuration/Group/Group.vue'),
  },
  {
    path: '/group/addGroup',
    name: 'addGroup',
    meta: {
      power: 'group',
    },
    component: () =>
      import(/* webpackChunkName: "GroupDetails" */ '@/views/Configuration/Group/GroupDetails.vue'),
  },
  {
    path: '/group/editGroup',
    name: 'editGroup',
    meta: {
      power: 'group',
    },
    component: () =>
      import(/* webpackChunkName: "GroupDetails" */ '@/views/Configuration/Group/GroupDetails.vue'),
  },
  /* site */
  {
    path: '/site',
    name: 'site',
    meta: {
      power: 'site',
    },
    component: () => import(/* webpackChunkName: "Site" */ '@/views/Configuration/Site/Site.vue'),
  },
  {
    path: '/site/addSite',
    name: 'addSite',
    meta: {
      power: 'site',
    },
    component: () =>
      import(/* webpackChunkName: "GroupDetails" */ '@/views/Configuration/Site/SiteDetails.vue'),
  },
  {
    path: '/site/editSite',
    name: 'editSite',
    meta: {
      power: 'site',
    },
    component: () =>
      import(/* webpackChunkName: "GroupDetails" */ '@/views/Configuration/Site/SiteDetails.vue'),
  },
  /* MAC */
  {
    path: '/MAC',
    name: 'MAC',
    meta: {
      power: 'MAC',
    },
    component: () => import(/* webpackChunkName: "MAC" */ '@/views/Configuration/MAC.vue'),
  },
  /* global */
  {
    path: '/global',
    name: 'global',
    meta: {
      power: 'global',
    },
    component: () => import(/* webpackChunkName: "Global" */ '@/views/Configuration/Global.vue'),
  },
]
