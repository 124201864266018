import axios from '@/plugins/axios.js'

// 查询版本数据List
export const frimwareList = (params) => axios.get('/tb-firmware/selectFiltList', { params })

// 查询版本数据
export const frimwareSearch = (params) => axios.post('/tb-firmware/selectFrimware', params)

// 查询版本数据
export const selectFrimwareList = (params) => axios.post('/tb-firmware/selectFrimwareList', params)

// 查询单条版本数据
export const frimwareQuery = (params) => axios.get('/tb-firmware/selectFrimwareById', { params })

// 添加或修改版本数据
export const addFrimware = (params) => axios.post('/tb-firmware/addOrEditFirmware', params)

// 升级固件
export const firmwareUpgrade = (params) => axios.post('/device/firmwareUpgrade', params)

// 推送配置
export const firmwareUpgrade2 = (params) => {
  const now = new Date(new Date().toLocaleDateString()).getTime()
  const newParams = {
    type: params.type,
    name: params.name,
    firmwareId: params.template,
    beginTime: params.beginTime ? params.beginTime : now,
    endTime: params.endTime ? params.endTime : now + 86399000,
    deviceIds: params.deviceIds,
    macs: params.macs,
  }
  return axios.post('/tb-firmware/firmwareUpgrade', newParams)
}

// 删除版本数据
export const firmwareRemoveBatch = (data) => axios.delete('/tb-firmware/deleteFirmware', { data })

// 下载配置文件
export const firmwareDownLoad = (params) =>
  axios.post('/tb-firmware/downLoadFile', params, { responseType: 'blob' })

// 订阅型号关注版本升级
export const setNewVersionNotify = (params) =>
  axios.post('/tb-version-notification/subscribe/newVersionNotify', params)

// 查询当前用户关注的话机型号
export const getNewVersionNotify = (params) =>
  axios.get('/tb-version-notification/searchNotifiedModels', { params })

// 判断版本号是否重复
export const firmwareVersionIsRepeat = (params) =>
  axios.post(
    `/tb-firmware/isRepeat?firmwareVersion=${params.firmwareVersion}&supportModel=${params.supportModel}&id=${params.id}`
  )

// device升级固件双层下拉查询
export const selectUpgradeVersionList = (params) => axios.post('/tb-firmware/selectUpgradeVersionList', params)
