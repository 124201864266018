<style lang="scss" scoped>
.breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  line-height: 70px;
  padding: 0 30px;
  font-size: 24px;
  z-index: 1;
  span {
    vertical-align: middle;
  }
  .el-button {
    padding: 0;
    font-size: 24px;
  }
}
</style>
<template>
  <div class="breadcrumb">
    <template v-for="(item, index) in data">
      <span :key="item" v-if="item != 'diagnosisInterface'">
        <el-button
          type="text"
          v-if="index + 1 != data.length"
          @click="$router.push('/' + item)"
        >{{ getLabel(item, index) }}</el-button>
        <span v-else>{{ getLabel(item, index) }}</span>
        <span v-if="index + 1 != data.length && item != 'diagnostics'"> / </span>
        <span v-else-if="subtitle">({{ subtitle }})</span>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'layoutBreadcrumb',
  props: {
    data: Array,
  },
  computed: {
    subtitle() {
      let subtitle = this.$route.query.subtitle
      return subtitle ? subtitle.toLocaleUpperCase() : ''
    },
  },
  methods: {
    getLabel(item, index) {
      if (index) {
        return this.$t(this.data[0] + '.' + item)
      } else {
        return this.$t('sidebar.' + item)
      }
    },
  },
}
</script>
