<style lang="scss" scoped>
.el-input__inner {
  line-height: 1.5em;
}
.el-input__suffix {
  cursor: pointer;
  right: 90px;
}
.el-input-group__append {
  background-color: $--color-primary;
  color: $--color-white;
  border-color: $--color-primary;
  ::v-deep .el-upload--text {
    &:hover, &:focus {
      color: $--color-white;
    }
  }
}
</style>
<template>
  <div class="el-input el-input-group el-input-group--append">
    <div class="el-input__inner">{{ newValue && newValue.name }}</div>
    <span class="el-input__suffix" v-if="newValue && newValue.name">
      <span class="el-input__suffix-inner">
        <i class="el-icon-circle-close el-input__icon" @click.stop="clear"></i>
      </span>
    </span>
    <el-upload
      class="el-input-group__append"
      action
      :before-upload="beforeUpload"
      :limit="1"
      :auto-upload="true"
      :show-file-list="false">
      {{ $t('select') }}
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'input-file',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: File,
    placeholder: String,
    accept: {
      type: String,
      default: 'xlsx'
    },
    acceptTip: {
      type: String,
      default() {
        return this.$t('components.upload.typeTipsMsg', ['.xlsx'])
      }
    },
    sizeLimit: {
      type: Number,
      default: 100
    },
    sizeLimitTip: {
      type: String,
      default() {
        return this.$t('components.upload.sizeTipsMsg', ['100KB'])
      }
    },
  },
  computed: {
    newValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
        this.$parent.$emit("el.form.change");
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const acceptArr = this.accept.split(',')
      const isType = !(acceptArr.indexOf(file ? file.name.split('.')[1] : '') == -1)
      const isSize = file.size / 1024 < this.sizeLimit
      if (!isType) {
        this.$message.warning(this.acceptTip)
        return false
      }
      if (!isSize) {
        this.$message.warning(this.sizeLimitTip)
        return false
      }
      this.newValue = file
      return false
    },
    clear() {
      this.newValue = null
    }
  }
}
</script>
