<template>
  <div class="siderbar">
    <div class="is-collapse" @click="isCollapse = !isCollapse">
      <i :class="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
    </div>
    <div class="sidebarMenu">
      <transition :name="isCollapse ? '' : 'el-zoom-in-top'">
        <div class="select-cover" v-show="siteShow">
          <div class="select-site">
            <i class="iconfont icon-search"></i>
            <SelectSite
              checkboxShow
              ref="selectSite"
              size="small"
              v-model="site"
              clearable
              defaultSelectAll
              defaultExpandedSecondLevel
            />
          </div>
        </div>
      </transition>
      <el-menu
        class="sidebar"
        :default-active="activeIndex"
        :collapse="isCollapse"
        :background-color="sidebarBagcgroundColor"
        :text-color="sidebarTextColor"
        :active-text-color="sidebarActiveTextColor"
        @select="handleSelect"
      >
        <template v-for="item in asyncRouterMap">
          <el-submenu v-if="item.children.length" :index="item.rsUrl" :key="item.id">
            <template slot="title">
              <i
                class="iconfont"
                :class="'icon-' + item.rsUrl"
                :style="{ color: fontColor[item.rsUrl] }"
              ></i>
              <span slot="title" class="title">{{ $t('sidebar.' + item.rsUrl) }}</span>
            </template>
            <template v-for="item2 in item.children">
              <el-menu-item-group :key="item2.id">
                <el-menu-item :index="item2.rsUrl">
                  <span class="title w100">{{ $t('sidebar.' + item2.rsUrl) }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </template>
          </el-submenu>
          <el-menu-item v-else :index="item.rsUrl" :key="item.id">
            <i
              class="iconfont"
              :class="'icon-' + item.rsUrl"
              :style="{ color: fontColor[item.rsUrl] }"
            ></i>
            <span slot="title" class="title w100">{{ $t('sidebar.' + item.rsUrl) }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SelectSite } from '@/components'
import {
  sidebarBagcgroundColor,
  sidebarTextColor,
  sidebarActiveTextColor,
} from '@/style/variables.scss'

export default {
  name: 'layoutSidebar',
  components: {
    SelectSite
  },
  data() {
    return {
      isCollapse: false,
      siteShow: true,
      sidebarBagcgroundColor,
      sidebarTextColor,
      sidebarActiveTextColor,
      fontColor: {
        home: '#E8BE34',
        account: '#906BAC',
        channelManagement: '#8096CC',
        enterpriseManagement: '#9C7DB7',
        device: '#EC7E6F',
        deviceManagement: '#EC7E6F',
        configuration: '#6B6BB0',
        ssite: '#3DAC73',
        task: '#5EB3E5',
        alarm: '#9C7DB7',
        diagnostics: '#8096CC',
        callStatistics: '#D9E36D',
        resources: '#8ECFCC',
        system: '#E8BE34',
      },
    }
  },
  computed: {
    ...mapState('account', ['asyncRouterMap']),
    ...mapState('memory', ['sidebarSite']),
    ...mapState('session', ['sidebarUpdate', 'appWidth']),
    activeIndex() {
      return this.$route.meta.power
    },
    site: {
      get() {
        return this.sidebarSite
      },
      set(val) {
        this.$store.commit('memory/SET_SIDEBAR_SITE', val)
      },
    },
  },
  watch: {
    isCollapse(val) {
      if (val) {
        this.siteShow = !val
      } else {
        setTimeout(() => {
          this.siteShow = !val
        }, 300)
      }
    },
    appWidth(val) {
      if (val <= 1366) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    },
    sidebarUpdate(val) {
      if (val) {
        this.$refs.selectSite.getData(true)
        this.$store.commit('session/SET_SIDEBAR_UPDATE', false)
      }
    },
  },
  methods: {
    handleSelect(key) {
      if (key == 'home') key = ''
      this.$router.push('/' + key)
    },
  }
}
</script>

<style lang="scss">
.select-cover {
  max-width: calc(240px - 52px);
  padding: 20px 26px;
  .select-site {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: rgba($color: #FFF, $alpha: .1);
    border-radius: 10px;
    .icon-search {
      padding-left: 13px;
      color: #FFF;
      font-size: 18px;
    }
    .el-input__inner {
      border: none;
    }
    .el-select .el-input .el-select__caret {
      font-size: 18px;
    }
    .el-input--small .el-input__inner {
      background-color: transparent;
      font-size: 14px;
    }
  }
}
.siderbar {
  position: relative;
  &:hover {
    animation-delay: 2s;
    .is-collapse {
      opacity: 1;
    }
  }
  .is-collapse {
    transition: opacity 0.5s ease-out;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 2001;
    top: 50%;
    right: -14px;
    background-color: #282d30;
    height: 80px;
    width: 14px;
    color: white;
    text-align: center;
    line-height: 80px;
    i {
      font-size: 14px;
    }
    &::after,
    &::before {
      position: absolute;
      top: -14px;
      right: 0px;
      content: '';
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #282d30;
      border-left-color: #282d30;
    }
    &::after {
      top: auto;
      bottom: -14px;
      border-bottom-color: transparent;
      border-top-color: #282d30;
      border-left-color: #282d30;
    }
  }
}
.sidebarMenu {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $--sidebar-bagcground-color;
  /*IE隐藏滚动条*/
  // -ms-overflow-style: none;
  /*火狐隐藏滚动条*/
  // overflow: -moz-scrollbars-none;
  /*Chrome隐藏滚动条*/
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .el-menu {
    border-right: 0;
  }
  .el-menu:not(.el-menu--collapse) {
    width: 240px;
  }
  .title {
    display: inline-block;
    width: calc(100% - 30px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    &.w100 {
      width: 100%;
    }
  }
  .sidebar {
    // 收起
    &.el-menu--collapse {
      width: 96px;
      .el-menu-item,
      .el-submenu__title {
        padding-left: 18px;
        text-align: center;
        .iconfont {
          margin-right: 0;
          position: relative;
          right: -5px;
        }
      }
    }
    .el-menu-item-group__title {
      padding: 0;
    }
    .el-menu-item-group .el-menu-item {
      padding-left: 50px !important;
    }
    .el-menu-item,
    .el-submenu__title {
      $menu-item-height: 48px;
      box-sizing: border-box;
      padding: 0 20px 0 32px;
      height: $menu-item-height;
      line-height: $menu-item-height;
      border-right: 12px $--sidebar-bagcground-color solid;
      opacity: 0.6;
      color: white !important;
      font-size: 14px;
      .el-submenu__icon-arrow {
        content: "\e62e";
        right: 0;
        margin-top: -10px;
        font-size: 18px;
      }
      .iconfont {
        font-size: 24px;
        margin-right: 6px;
      }
      // 高亮
      &.is-active {
        opacity: 1;
        border-right-color: #f1c42c;
        background-color: #1d2125 !important;
        .title {
          font-weight: bold;
        }
      }
      &:hover {
        border-right-color: #f1c42c;
      }
    }
    // 高亮
    .el-submenu {
      &.is-active .el-submenu__title {
        opacity: 1;
      }
    }
  }
}
</style>