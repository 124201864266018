import axios from '@/plugins/axios.js'

// 查询
export const deviceSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/device/search', newParams)
}

// 过滤
export const deviceFilter = ({
  currentPage,
  groupId,
  keywords,
  pageSize,
  site,
  model,
  sortField,
  sortOrder,
  types
}) => {
  types = typeof(model) === 'string' ? [model] : model
  return axios.post('/device/filter', {
    currentPage,
    groupId,
    keywords,
    pageSize,
    site,
    sortField,
    sortOrder,
    types
  })
}

// 查询（list）
export const deviceQuery = (params) => axios.get('/device/query', { params })

// 查询（list）
export const deviceList = (params) => axios.get('/device/queryDevice', { params })

// 查询（设备账户）
export const deviceAccountList = (params) => axios.get('/device/listDeviceAccount', { params })

// 查询城市（list）
export const deviceCityList = (params) => axios.post('/device/cityList', params)

// 新增
export const deviceAdd = (params) => {
  let newParams = { ...params }
  newParams.macAddress = newParams.macAddress.toLowerCase()
  return axios.post('/device/add', newParams)
}

// 编辑
export const deviceUpdate = (params) => {
  let newParams = { ...params }
  newParams.macAddress = newParams.macAddress.toLowerCase()
  return axios.post('/device/update', newParams)
}

// 导出
export const deviceExport = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/device/export', newParams, { responseType: 'blob' })
}

// 模板导出
export const deviceTemplateExport = (params) => {
  return axios.post('/device/deviceExcel', params, { responseType: 'blob' })
}

// 导入失败数据导出
export const deviceExportFailedData = (params) => {
  return axios.post('/device/exportFailedData', params, { responseType: 'blob' })
}

// 删除
// export const removeDevice = (params) => axios.delete('/device/remove', { params })

// 批量删除
export const removeDeviceBatch = (data) => axios.delete('/device/removeBatch', { data })

// 获取设备账号状态
export const deviceAccoutnStatus = (params) => axios.get('/device/getAccoutnStatus', { params })

// 禁用推送配置
export const deviceDisablePushConfig = (params) => axios.post('/device/disablePushConfig', params)

// 启用推送配置
export const deviceEnablePushConfig = (params) => axios.post('/device/enablePushConfig', params)

// 分配站点
export const deviceSiteAssign = (params) => axios.post('/device/siteAssign', params)

// 重启设备
export const deviceReboot = (params) => axios.post('/device/deviceReboot', params)

// 恢复出厂
export const deviceFactoryReset = (params) => axios.post('/device/factoryReset', params)

// 恢复出厂
export const deviceDetails = (params) => axios.post('/device/details', undefined, { params })

// 获取model下账号个数
export const deviceModelAccountNum = (params, noLoading = false) =>
  axios.get('/device/listAccount', {
    params,
    data: {
      noLoading,
    },
  })

// 账号分配
export const deviceAccountAssign = (params) => axios.post('/device/accountAssign', params)

// 查询设备
export const getDevice = (params) => axios.post('/device/default', params)

// 扫描
export const scanDevice = (params) => axios.post('/device/scanDevice', params)

// 停止扫描
export const stopScanDevice = (params) => axios.post("/device/stopScanDevice", null, { params })

// 更新话机信息
export const getDeviceInfo = (params) => axios.post("/device/getDeviceInfo", params)

// 校验 mac 地址
export const verifyMac = (params) => axios.get("/device/verifyMac", { params })
