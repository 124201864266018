<template>
  <div class="main">
    <!-- phone -->
    <div class="main-header">
      <el-button class="btn"
        :type="selectAllType ? null : 'primary'"
        size="small"
        @click="selectAll"
        >{{ selectAllType ? $t('unselectAll') : $t('selectAll') }}</el-button
      >
      <el-button class="btn" size="small" @click="resetSettings">{{
        $t('resetSetting')
      }}</el-button>
    </div>
    <div class="main-body">
      <SpTable
        v-model="phoneKeysData"
        v-if="pageType"
      ></SpTable>
      <template v-else>
        <el-tabs v-model="tabActive" @tab-click="tabClick">
          <el-tab-pane
            v-for="(item, key) in phoneKeysData"
            :name="key"
            :label="$t(`sitParam.${key}`)"
            :key="key"
          ></el-tab-pane>
          <SpTable
            ref="spTableRef"
            v-model="phoneKeysData[tabActive]"
            paginationShow
          ></SpTable>
        </el-tabs>
      </template>
    </div>
    <div class="main-footer footer-btns">
      <el-button class="footer-btn" size="small" type="primary" plain @click="$emit('cancel')">{{ $t('cancel') }}</el-button>
      <el-button class="footer-btn" size="small" type="primary" @click="onlysave">{{
        $t('save')
      }}</el-button>
      <el-button class="footer-btn" size="small" type="primary" @click="submit">{{
        $t('Save and update')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import methods from '../plugins/methods'
import SpTable from './input/sp-table'

export default {
  name: 'sp-main-phone-keys',
  components: { SpTable },
  props: {
    phoneKeysData: [Array, Object],
  },
  model: {
    prop: 'phoneKeysData',
    event: 'changeMainData'
  },
  data() {
    return {
      tabName: Object.values(this.phoneKeysData[0])[0].label.split('$')[0],
      tableColumnData: [
        'Key',
        'Type',
        'SubType',
        'Account',
        'Value',
        'Label',
        'Extension',
      ],
      tabActive: 'Aom1ProgKey',
    }
  },
  computed: {
    pageType() {
      return Array.isArray(this.phoneKeysData)
    },
    selectAllType: {
      get() {
        let type = true
        methods.parseData(this.phoneKeysData, (e) => {
          !e.Push && (type = false)
        })
        return type
      },
      set(val) {
        methods.parseData(this.phoneKeysData, (e) => {
          e.Push = val
        })
      },
    },
  },
  methods: {
    test(phoneKeysData, key) {
      console.log(phoneKeysData[key])
    },
    auxiliaryCheckboxChange(arr) {
      arr.forEach((element) => {
        let i_Account = element.Account
        i_Account.Push = element.Push
        i_Account.label = element.Account.Key
        let i_Extension = element.Extension
        i_Extension.Push = element.Push
        i_Extension.label = element.Extension.Key
        let i_Label = element.Label
        i_Label.Push = element.Push
        i_Label.label = element.Label.Key
        let i_Type = element.Type
        i_Type.Push = element.Push
        i_Type.label = element.Type.Key
        let i_SubType = element.SubType
        i_SubType.Push = element.Push
        i_SubType.label = element.SubType.Key
        let i_Value = element.Value
        i_Value.Push = element.Push
        i_Value.label = element.Value.Key
      })
    },
    auxiliaryClear(arr) {
      for (let a = 0; a < arr.length; a++) {
        arr.forEach((element) => {
          let i_Account = element.Account
          i_Account.Push = element.Push
          i_Account.label = element.Account.Key
          let i_Extension = element.Extension
          i_Extension.Push = element.Push
          i_Extension.label = element.Extension.Key
          let i_Label = element.Label
          i_Label.Push = element.Push
          i_Label.label = element.Label.Key
          let i_Type = element.Type
          i_Type.Push = element.Push
          i_Type.label = element.Type.Key
          let i_SubType = element.SubType
          i_SubType.Push = element.Push
          i_SubType.label = element.SubType.Key
          let i_Value = element.Value
          i_Value.Push = element.Push
          i_Value.label = element.Value.Key
        })
      }
    },
    // 全选
    selectAll() {
      this.selectAllType = !this.selectAllType
      if (this.selectAllType) {
        if (Array.isArray(this.phoneKeysData)) {
          this.auxiliaryCheckboxChange(this.phoneKeysData)
        } else {
          this.auxiliaryCheckboxChange(this.phoneKeysData.Aom1ProgKey)
          this.auxiliaryCheckboxChange(this.phoneKeysData.Aom2ProgKey)
          this.auxiliaryCheckboxChange(this.phoneKeysData.Aom3ProgKey)
        }
      } else {
        if (Array.isArray(this.phoneKeysData)) {
          this.auxiliaryClear(this.phoneKeysData)
        } else {
          this.auxiliaryClear(this.phoneKeysData.Aom1ProgKey)
          this.auxiliaryClear(this.phoneKeysData.Aom2ProgKey)
          this.auxiliaryClear(this.phoneKeysData.Aom3ProgKey)
        }
      }
    },
    // 恢复默认值
    resetSettings() {
      methods.parseData(this.phoneKeysData, (e) => {
        Object.values(e).forEach((ee) => {
          if (typeof ee == 'object') ee.Value = ee.DefaultValue
        })
        e.Push = false
      })
      if (Array.isArray(this.phoneKeysData)) {
        this.auxiliaryClear(this.phoneKeysData)
      } else {
        this.auxiliaryClear(this.phoneKeysData.Aom1ProgKey)
        this.auxiliaryClear(this.phoneKeysData.Aom2ProgKey)
        this.auxiliaryClear(this.phoneKeysData.Aom3ProgKey)
      }
    },
    onlysave() {
      const type = this.getValidate()
      if (type) {
        this.$message.error(type)
      } else {
        this.$emit('onlysave')
      }
    },
    // 提交
    submit() {
      const type = this.getValidate()
      if (type) {
        this.$message.error(type)
      } else {
        this.$emit('submit')
      }
    },
    // 校验结果
    getValidate() {
      let message = false
      methods.parseData(this.phoneKeysData, (e, i) => {
        if (e.Push) {
          Object.entries(e).forEach((ee) => {
            if (ee[0] == 'Type' && this.getCheckValue(ee[1].Value) && !e.Value.Value) {
              message = typeof e.Name == 'number'
                ? this.$t('sitParam.Key', [i + 1])
                : this.$t('sitParam.phoneKeysKeyValues.' + e.Name)
            }
          })
        }
      })
      return message
        ? this.$t('sitParam2.phoneKeysValidateTips', [message])
        : false
    },
    // 查询校验参数
    getCheckValue(val) {
      const arr = [
        '0',
        '60',
        '2',
        '3',
        '4',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '18',
        '23',
        '24',
        '58',
        '101',
        '102',
        '103',
        '1002',
        '1003',
        '1013'
      ] // 不需要校验的
      console.log('getCheckValue', val, arr.indexOf(val))
      return arr.indexOf(val) != -1 ? false : true
    },
    tabClick() {
      this.$refs.spTableRef.pageCount = 1;
    }
  },
}
</script>

<style lang="scss" scoped>
  .main {
    padding: 0;
    .main-header {
      margin-bottom: 20px;
      // padding: 0 20px;
      .btn {
        width: 162px;
      }
    }
  }
</style>
