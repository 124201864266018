import axios from '@/plugins/axios.js'

// 推送话机配置
export const pushDeviceConfig = (params) => axios.post(`/device/deviceConfigUpdate?id=${params}`)

// 推送话机配置(model)
export const pushConfigModel = (params) => axios.post('/modeltemplate/push', params)
export const pushConfigModelSchedule = (params) =>
  axios.post('/modeltemplate/scheduleUpdate', params)

// 推送话机配置(group)
export const pushConfigGroup = (params) => axios.post('/tb-group-template/push', params)
export const pushConfigGroupSchedule = (params) =>
  axios.post('/tb-group-template/scheduleUpdate', params)

// 推送话机配置(site)
export const pushConfigSite = (params) => axios.post('/tb-site-template/push', params)
export const pushConfigSiteSchedule = (params) =>
  axios.post('/tb-site-template/scheduleUpdate', params)

// 推送话机配置(mac)
export const pushConfigMac = (params) => axios.post('/tb-mac/push', {}, { params })

//高亮显示
export const configPushRecord = (params) => axios.get('/tb-config/configPushRecord', { params })

// 保存配置参数
export const saveParam = (params) => axios.post('/tb-config/saveParam', params)

// 获取配置参数
export const getParam = (params) => axios.get('/tb-config/getParam', { params })

// 获取配置参数(Xml)
export const getParamXml = (params) => axios.get('/tb-config/selectExtend', { params })

// 保存配置参数(Xml)
export const saveParamXml = (params) =>
  axios.post('/tb-config/updateExtent', params, { params: { relationId: params.relationId } })

// 过滤菜单
export const dynamicParameterJs = (params) =>
  axios.post("/tb-config/dynamicParameterJs", params);
