<template>
  <el-select
    style="width: 100%"
    ref="siteSelect"
    v-model="label"
    :size="size"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
    :multiple="checkboxShow"
    :collapse-tags="checkboxShow"
    @clear="clear"
    @change="changSelected"
    @remove-tag="removeTag"
  >
    <el-input
      :size="size"
      v-model="valueFilter"
      class="selectInput"
      prefix-icon="el-icon-search"
    ></el-input>
    <el-option v-if="data.length" value class="options">
      <el-tree
        :style="'max-width:' + maxWidth + 'px'"
        ref="tree"
        :data="data"
        :props="props"
        :node-key="props.value"
        @node-click="handleNodeClick"
        @check-change="handleCheckChange"
        :show-checkbox="checkboxShow"
        :default-expanded-keys="newDefaultExpandedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :filter-node-method="filterNode"
        :check-strictly="checkStrictly"
        accordion
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
import { selectSite } from '@/api/site'

export default {
  name: 'select-site',
  model: {
    prop: 'value',
    event: 'changValue',
  },
  props: {
    value: [Number, String, Array],
    placeholder: {
      type: String,
      default() {
        return window.vm.$t('components.selectSite.pleaseSelectSite')
      },
    },
    clearable: Boolean,
    size: String,
    checkboxShow: Boolean,
    defaultShow: Boolean,
    allSiteShow: Boolean,
    allSiteId: {
      type: String,
      default: 'all',
    },
    treeData: Array,
    disabled: Boolean,
    checkStrictly: Boolean,
    emptyPlaceholder: {
      type: String,
      default: '',
    },
    defaultExpandedSecondLevel: Boolean, // 默认展开二级
    defaultExpandedKeys: {
      type: Array,
      default() {
        return []
      },
    }, // 默认展开的节点的 key 的数组
    disabledProp: {
      type: String,
      default: 'disabled',
    },
    // 默认选中所有
    defaultSelectAll: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: [String, Number],
      default: 'auto'
    },
  },
  data() {
    return {
      label: this.checkboxShow ? [] : '',
      valueFilter: '',
      type: true,
      data: [],
      props: {
        value: 'id',
        children: 'childSite',
        label: 'siteName',
      },
      defaultCheckedKeys: [],
    }
  },
  computed: {
    newDefaultExpandedKeys() {
      if (this.defaultExpandedSecondLevel) {
        return this.data.map((e) => e.id)
      } else {
        return this.defaultExpandedKeys
      }
    },
  },
  watch: {
    valueFilter(val) {
      this.$refs.tree.filter(val)
    },
    value(newVal, oldVal) {
      if(this.data.length && JSON.stringify(newVal) != JSON.stringify(oldVal)) this.init()
    }
  },
  created() {
    if (this.treeData) {
      this.data = this.treeData
      this.init(true)
    } else {
      this.getData()
    }
  },
  methods: {
    changSelected() {
      let checkedNodes = this.$refs.tree.getCheckedNodes()
      let checkId = []
      for (let checkedNode of checkedNodes) {
        if (this.label.indexOf(checkedNode.siteName) !== -1) {
          checkId.push(checkedNode.id)
        }
      }
      this.$refs.tree.setCheckedKeys(checkId)
    },
    getData(clearValue) {
      if (clearValue) this.$emit('changValue', null)
      selectSite()
        .then((res) => {
          if (res.data.code == 0) {
            let data = res.data.result.rows
            this.allSiteShow &&
              data.unshift({
                siteName: this.$t('components.selectSite.allSite'),
                id: this.allSiteId,
              })
            this.data = data
            if (this.checkboxShow ? this.value.length : this.value) {
              this.init()
            } else if (this.checkboxShow && this.defaultSelectAll) {
              this.$nextTick(() => {
                this.selectAll()
              })
            }
          }
        })
        .catch(() => {})
    },
    init(selectAll) {
      this.$nextTick(() => {
        if (this.checkboxShow) {
          this.$refs.tree && this.$refs.tree.setCheckedKeys([])
          this.value && (this.label = this.getValueTitles(this.value))
          this.defaultCheckedKeys = this.value
          this.defaultExpandedKey = this.value
          // 默认全选且没有选择过
          if (!this.value && selectAll) this.selectAll()
        } else {
          if (this.value) {
            this.label = this.$refs.tree.getNode(this.value).data[this.props.label]
            this.$refs.tree.setCurrentKey(this.value)
            this.defaultExpandedKey = [this.value]
            this.changValue('init')
          } else {
            if (this.defaultShow && this.data.length) {
              this.label = this.data[0][this.props.label]
              this.changValue('init', this.data[0].id)
            }
          }
        }
      })
    },
    selectAll() {
      if (this.defaultSelectAll && !this.label.length && this.$refs.tree) {
        this.$refs.tree.setCheckedNodes(this.data)
      }
    },
    handleNodeClick(data) {
      if (!this.checkboxShow && !data[this.disabledProp]) {
        this.changValue('click', data.id)
        this.label = data.siteName
        this.$refs.siteSelect.blur()
      }
    },
    handleCheckChange(data) {
      if (this.checkboxShow) {
        const values = this.$refs.tree.getCheckedKeys()
        this.label = this.getValueTitles(values)
        this.changValue('check', values)
        this.defaultExpandedKey = [data.id]
      }
    },
    filterNode(value, data) {
      let keyword = value.toLowerCase(),
        siteName = data.siteName.toLowerCase(),
        siteDesc = data.siteDesc.toLowerCase()
      if (!value) return true
      return siteName.indexOf(keyword) !== -1 || siteDesc.indexOf(keyword) !== -1
    },
    clear() {
      if (this.checkboxShow) {
        this.defaultExpandedKey = []
        this.$refs.tree.setCheckedKeys([])
        this.changValue('clear', [])
        this.label = []
      } else {
        this.$refs.tree.setCurrentKey(null)
        this.changValue('clear', this.emptyPlaceholder)
        this.label = ''
      }
    },
    getValueTitles(val) {
      let arr = []
      val.forEach((e) => {
        arr.push(this.$refs.tree.getNode(e).data[this.props.label])
      })
      return arr
    },
    changValue(type, val) {
      val != undefined && this.$emit('changValue', val)
      this.$emit('change', type, this.$refs.tree.getNode(val ? val : this.value))
    },
    removeTag() {
      let ids = []
      this.flat(this.data, ids, {
        filter: {
          data: this.label,
          label: this.props.label,
          prop: this.props.value
        },
      });
      this.changValue('check', ids)
    },
    // 树结构扁平化
    flat(data, val, option) {
      if(Array.isArray(data)) {
        data.forEach(e => {
          this.flat(e.childSite, val, option)
          if(option.filter) {
            if(option.filter.data.includes(e[option.filter.label])) {
              val.push(e[option.filter.prop])
            }
          }else{
            val.push(e)
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep.el-tree {
  // max-width: 1184px;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
::v-deep.el-select__tags-text {
  overflow: hidden;
  max-width: 4em;
  display: block;
  float: left;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
ul li ::v-deep .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
::v-deep .is-current {
  background-color: #F6F6F6;
  // color: $--color-primary
}
.el-tree {
  ::v-deep.is-current .el-tree-node__label {
    color: $--color-primary;
    font-weight: 700;
  }
  ::v-deep.is-current[aria-disabled=true] >.el-tree-node__content >.el-tree-node__label{
    color: #C6C6C6;
  }
}
.el-tree ::v-deep .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
.selectInput {
  padding: 0 5px;
  box-sizing: border-box;
}
::v-deep.el-tree-node[aria-disabled=true] {
  >.el-tree-node__content >.el-tree-node__label{
    color: #C6C6C6;
    cursor: not-allowe;
  }
}
</style>
