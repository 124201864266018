<template>
  <div>
    <el-input v-model="newValue" :disabled="disabled" @click.native="handleClick"></el-input>
    <el-dialog
      :title="$t(`sitParam.${item.label}`)"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
    >
      <el-transfer
        v-model="transferVal"
        :data="transferData"
        target-order="push"
        :titles="[$t('sitParam2.disableCodecs'), $t('sitParam2.enableCodecs')]"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{ $t('save') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'sp-transfer',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    item: Object,
  },
  data() {
    return {
      dialogVisible: false,
      transferData: this.getTransferData(this.item),
      transferVal: this.value.split(';'),
    }
  },
  computed: {
    newValue() {
      if (this.value) {
        let arr = []
        this.value.split(';').forEach((e) => {
          arr.push(this.$t(`sitParam.${this.item.label}Values.${e}`))
        })
        return arr.join(';')
      } else {
        return ''
      }
    },
  },
  methods: {
    getTransferData(item) {
      const data = []
      item.DefaultValue.split(';').forEach((e) => {
        data.push({
          key: e,
          label: this.$t(`sitParam.${item.label}Values.${e}`),
        })
      })
      return data
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleClick() {
      if (!this.disabled) {
        this.dialogVisible = true
        this.transferData = this.getTransferData(this.item)
        this.transferVal = this.value.split(';')
      }
    },
    handleSubmit() {
      this.$emit('change', this.transferVal.filter(item => item).join(';'))
      this.handleClose()
    },
  },
}
</script>
