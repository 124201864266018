import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    language: 'en',
    loginCookieTipShow: true,
    sidebarSite: [],
    tableColumnCheckData: {},
    tableColumnWidthData: {},
    userTab: null,
    backupTab: null
  },
  mutations: {
    SET_LANGUAGE(state, payload) {
      state.language = payload
      i18n.locale = payload
      location.reload()
    },
    SET_LOGIN_COOKIE_TIP_SHOW(state, payload) {
      state.loginCookieTipShow = payload
    },
    SET_SIDEBAR_SITE(state, payload) {
      state.sidebarSite = payload
    },
    SET_TABLE_COLUMN_CHECK_DATA(state, payload) {
      state.tableColumnCheckData[payload.name] = payload.data
    },
    SET_TABLE_COLUMN_WIDTH_DATA(state, payload) {
      state.tableColumnWidthData[payload.name] = payload.data
    },
    SET_USER_TAB(state, payload) {
      state.userTab = payload
    },
    SET_BACKUP_TAB(state, payload) {
      state.backupTab = payload
    },
  },
}
