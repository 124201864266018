export default [
  /* Device Diagnostics */
  {
    path: '/diagnostics',
    name: 'diagnostics',
    meta: {
      power: 'diagnostics',
    },
    component: () =>
      import(/* webpackChunkName: "Diagnostics" */ '@/views/Diagnostics/Diagnostics.vue'),
  },
  {
    path: '/diagnostics/diagnosisInterface',
    name: 'diagnosisInterface',
    meta: {
      power: 'diagnostics',
    },
    component: () =>
      import(/* webpackChunkName: "Diagnostics" */ '@/views/Diagnostics/DiagnosisInterface.vue'),
  },
]
