<template>
  <div class="select-time">
    <el-date-picker
      v-model="start"
      type="datetime"
      size="small"
      value-format="timestamp"
      :placeholder="$t('startTime')"
    ></el-date-picker>
    <span> - </span>
    <el-date-picker
      v-model="end"
      type="datetime"
      size="small"
      align="right"
      value-format="timestamp"
      :placeholder="$t('endTime')"
    ></el-date-picker>
    <el-button
      class="search"
      type="primary"
      @click="changeTime"
    >{{ $t('search') }}</el-button>
  </div>
</template>

<script>
export default {
  name: 'select-time',
  props: {
    startTime: Number,
    endTime: Number,
  },
  data() {
    return {
      start: null,
      end: null,
    }
  },
  created() {
    this.start = this.startTime
    this.end = this.endTime
  },
  methods: {
    changeTime() {
      this.$emit('change', [this.start, this.end])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-date-editor.el-input {
  width: auto;
}
.search {
  margin-left: 6px;
}
</style>
