import store from '@/store'

let loading = function({ identifier, storeCommit }) {
  this.identifier = identifier ? identifier : 'identification'
  this.storeCommit = storeCommit ? storeCommit : 'session/SET_AXIOS_LOADING'
  this.loadingStack = {}
}

loading.prototype = {
  getState() {
    return !Object.entries(this.loadingStack)
      .map((e) => e[1])
      .includes(false)
  },
  show(config) {
    const iden = this._hashCode(config.url + new Date().getTime())
    const noLoading = config.data
      ? config.data.noLoading == undefined
        ? false
        : config.data.noLoading
      : false
    this.loadingStack[iden] = noLoading
    this._setAxiosLoading()
    return iden
  },
  hide(response) {
    const iden = response.config.headers[this.identifier]
    delete this.loadingStack[iden]
    this._setAxiosLoading()
  },
  _setAxiosLoading() {
    if (this.getState()) {
      store.commit(this.storeCommit, false)
    } else {
      store.commit(this.storeCommit, true)
    }
  },
  _hashCode(value) {
    var hash = 0,
      i,
      chr
    if (value.length === 0) return hash
    for (i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0
    }
    return hash
  },
}

loading.create = function(config) {
  return new loading(config ? config : {})
}

export default loading
