<template>
  <el-form class="repeating" ref="repeatingForm" :model="repeatingForm" label-width="auto">
    <el-form-item :label="$t('startTime')" prop="time">
      <el-date-picker
        v-if="justDisplayTime=='false'"
        class="repeating-date-picker"
        size="small"
        v-model="repeatingForm.time"
        type="datetime"
        value-format="x"
        :clearable="false"
        :picker-options="pickerOptions"
        @change="timeChange"
      ></el-date-picker>
      <el-time-picker
          v-model="repeatingForm.time"
          size="small"
          v-if="justDisplayTime=='true'"
          @change="timeChange"
          :placeholder="$t('select')">
      </el-time-picker>
    </el-form-item>
    <el-form-item :label="$t('task.repeating')" prop="repeateType">
      <div class="repeating-radio">
        <el-radio v-model="repeatingForm.repeateType" :label="1">{{ $t('task.daily') }}</el-radio>
      </div>
      <div class="repeating-radio">
        <el-radio v-model="repeatingForm.repeateType" :label="2">{{ $t('task.perWeek') }}</el-radio>
        <el-select
          size="small"
          class="repeating-radio-select"
          v-model="repeatingForm.repeateTime[1]"
          :disabled="repeatingForm.repeateType !== 2"
        >
          <el-option
            v-for="item in 7"
            :key="item"
            :label="$t('task.WeekDay' + item)"
            :value="item + ''"
          ></el-option>
        </el-select>
      </div>
      <div class="repeating-radio">
        <el-radio v-model="repeatingForm.repeateType" :label="3">{{
          $t('task.perMonth')
        }}</el-radio>
        <el-select
          size="small"
          class="repeating-radio-select"
          v-model="repeatingForm.repeateTime[2]"
          :disabled="repeatingForm.repeateType !== 3"
        >
          <el-option
            v-for="item in perMonthList"
            :key="item"
            :label="item == 'Last' ? $t('task.lastDay') : `${item} ${$t('task.day')}`"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <!-- <div class="repeating-radio">
        <el-radio v-model="repeatingForm.repeateType" :label="4">{{
          $t('task.perMonth')
        }}</el-radio>
        <el-select
          size="small"
          class="repeating-radio-select"
          v-model="repeatingForm.repeateTime[3][0]"
        >
          <el-option
            v-for="item in 5"
            :key="item"
            :label="$t('task.Num' + item)"
            :value="item"
          ></el-option>
        </el-select>
        <el-select
          size="small"
          class="repeating-radio-select"
          v-model="repeatingForm.repeateTime[3][1]"
        >
          <el-option
            v-for="item in 7"
            :key="item"
            :label="$t('task.WeekDay' + item)"
            :value="item"
          ></el-option>
        </el-select>
      </div> -->
    </el-form-item>
  </el-form>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'select-repeating-time',
  props: {
    justDisplayTime: {
      type: String,
      default: 'false'
    },
    beginTime: Number,
    repeateType: Number,
    repeateTime: [Number, String],
  },
  data() {
    const repeateType = this.repeateType
    let repeateTime = ['', '1', '1', ['1', '1']]
    repeateTime[this.repeateType - 1] = ''+this.repeateTime

    var perMonthList = Array.apply(null, { length: 31 })
    perMonthList = perMonthList.map((e, i) => (e = i + 1 + ''))
    perMonthList.push('Last')
    return {
      pickerOptions: {
        disabledDate(time) {
          const todayTimestamp = dayjs().startOf('date').unix(),
            timestamp = dayjs(time).unix()
          return timestamp < todayTimestamp
        }
      },
      repeatingForm: {
        time: this.beginTime,
        repeateType,
        repeateTime,
      },
      oldValue: this.value,
      perMonthList,
    }
  },
  watch: {
    repeatingForm: {
      deep: true,
      handler(newValue) {
        this.$emit('update:beginTime', newValue.time.valueOf())
        this.$emit('update:repeateType', newValue.repeateType)
        const repeateTime = newValue.repeateTime[newValue.repeateType - 1]
        if (Array.isArray(repeateTime)) {
          this.$emit('update:repeateTime', repeateTime.join('#'))
        } else {
          this.$emit('update:repeateTime', repeateTime)
        }
      },
    },
    beginTime() {
      this.resetForm()
    },
    repeateType() {
      this.resetForm()
    },
    repeateTime() {
      this.resetForm()
    },
  },
  methods: {
    resetForm() {
      const repeateType = this.repeateType
      let repeateTime = ['', '1', '1', ['1', '1']]
      repeateTime[this.repeateType - 1] = ''+this.repeateTime
      this.repeatingForm = {
        time: this.beginTime,
        repeateType,
        repeateTime,
      }
    },
    timeChange() {
      var startAt = (new Date(this.repeatingForm.time) * 1000) / 1000
      if (startAt < Date.now()) {
        this.repeatingForm.time = new Date().getTime() + 1000 * 60 * 10
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeating {
  padding: 20px;
  border: 1px solid lightgray;
  .repeating-date-picker {
    width: 200px;
  }
  .repeating-radio {
    .el-radio {
      width: 100px;
    }
    .repeating-radio-select {
      display: inline-block;
      width: 150px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
