import axios from '@/plugins/axios.js'

// 保存备份配置
export const backupConfig = (params) => axios.post('/backup/config', params)

// 立即执行备份
export const backupExecute = (params) => axios.post('/backup/execute', params)

// 备份记录
export const backupSearch = (params) => axios.post('/backup/search', params)

// 恢复备份
export const backupRecoverById = (backupRecordId) => axios.post(`/backup/recover/${backupRecordId}`)

// 查询备份配置
export const backupConfigByType = (backupType) => axios.get(`/backup/config/${backupType}`)

// 删除备份
export const backupRemove = (params) => axios.post('/backup/remove', params)

// 状态查询 反参：0 未开始 1备份 2还原
export const backupStatus = () => axios.get('/backup/status', {
  data: {
    noLoading: true,
  },
})

// 取消备份
export const backupCancel = () => axios.post('/backup/cancel', { noLoading: true })