export default [
  {
    path: '/adminFirmware',
    name: 'adminFirmware',
    meta: {
      power: 'adminFirmware',
    },
    component: () =>
      import(
        /* webpackChunkName: "OtherResourcesDetails" */ '@/views/Resources/Firmware/FirmwareAdmin.vue'
      ),
  },
]
