export default [
  /* Firmware */
  {
    path: '/firmware',
    name: 'firmware',
    meta: {
      power: 'firmware',
    },
    component: () =>
      import(/* webpackChunkName: "Firmware" */ '@/views/Resources/Firmware/Firmware.vue'),
  },
  {
    path: '/firmware/uploadFirmware',
    name: 'uploadFirmware',
    meta: {
      power: 'firmware',
    },
    component: () =>
      import(
        /* webpackChunkName: "CustomerFirmwareDetails" */ '@/views/Resources/Firmware/CustomerFirmwareDetails.vue'
      ),
  },
  {
    path: '/firmware/editFirmware',
    name: 'editFirmware',
    meta: {
      power: 'firmware',
    },
    component: () =>
      import(
        /* webpackChunkName: "CustomerFirmwareDetails" */ '@/views/Resources/Firmware/CustomerFirmwareDetails.vue'
      ),
  },
  /* Other Resources */
  {
    path: '/otherResources',
    name: 'otherResources',
    meta: {
      power: 'otherResources',
    },
    component: () =>
      import(
        /* webpackChunkName: "OtherResources" */ '@/views/Resources/OtherResources/OtherResources.vue'
      ),
  },
  {
    path: '/otherResources/uploadFiles',
    name: 'uploadFiles',
    meta: {
      power: 'otherResources',
    },
    component: () =>
      import(
        /* webpackChunkName: "OtherResourcesDetails" */ '@/views/Resources/OtherResources/OtherResourcesDetails.vue'
      ),
  },
  {
    path: '/otherResources/editFile',
    name: 'editFile',
    meta: {
      power: 'otherResources',
    },
    component: () =>
      import(
        /* webpackChunkName: "OtherResourcesDetails" */ '@/views/Resources/OtherResources/OtherResourcesDetails.vue'
      ),
  },
]
